import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./Component/Register/Register";
import ForgotPassword from "./Component/ForgotPassword/ForgotPassword";
import PageNotFound from "./Component/PageNotFound/PageNotFound";
import HomePage from "./Component/HomePage/HomePage";
import AllEvent from "./Component/AllEvent/AllEvent";
import ContactUs from "./Component/ContactUs/ContactUs";
import CreateEvent from "./Component/CreateEvent/CreateEvent";
import EventDetails from "./Component/EventDetails/EventDetails";
import Profile from "./Component/Profile/Profile";
import OrganiserProfile from "./Component/OrganiserProfile/OrganiserProfile";
import OrgDashboard from "./Component/OrgDashboard/OrgDashboard";
import ResellEvent from "./Component/ResellEvent/ResellEvent";
import TermsConditions from "./Component/TermsConditions/TermsConditions";
import PrivacyPolicy from "./Component/PrivacyPolicy/PrivacyPolicy";
import FAQ from "./Component/FAQ/FAQ";
import EventType from "./Component/CreateEvent/EventType";
import ResellEventDetail from "./Component/ResellEvent/ResellEventDetail";
import OrgEvent from "./Component/OrgDashboard/OrgEvent";
import OrgPayout from "./Component/OrgDashboard/OrgPayout";
import OrgReport from "./Component/OrgDashboard/OrgReport";
import ResellTicket from "./Component/ResellEvent/ResellTicket";
import BuyTicket from "./Component/BuyTicket/BuyTicket";
import ResellBuyTicket from "./Component/ResellBuyTicket/ResellBuyTicket";
import ResellPaymentStatus from "./Component/ResellBuyTicket/ResellPaymentStatus";
import CreateEventConfiguration from "./Component/CreateEvent/CreateEventConfiguration";
import SeatConfiguration from "./Component/CreateEvent/SeatConfiguration";
import CreateVenue from "./Component/CreateVenue/CreateVenue";
import RequestVenue from "./Component/CreateVenue/RequestVenue";
import Support from "./Component/Login/Support";
import AuthGard from "./utils/AuthGard";
import PaymentStatus from "./Component/BuyTicket/PaymentStatus";
import OrganizationList from "./Component/OrgDashboard/OrganizationList";
import Invoice from "./Component/Invoice/Invoice";
import LeagueCreate from "./Component/LeagueCreate/LeagueCreate";
import SeasonalPass from "./Component/SeasonalPass/SeasonalPass";
import InvoiceBuy from "./Component/Invoice/InvoiceBuy";
import LandingPage from "./Component/LandingPage/LandingPage";
import SeasonalEvent from "./Component/SeasonalEvent/SeasonalEvent";
import OrgMyTeam from "./Component/OrgDashboard/OrgMyTeam";
import Login from "./Component/Login/Login1";
import VenueConfiguration from "./Component/CreateVenue/VenueConfiguration";
import OrgLandingPageInfo from "./Component/OrgDashboard/OrgLandingPageInfo";
import BundleCreate from "./Component/BundleCreate/BundleCreate";
import SeasonalPaymentStatus from "./Component/SeasonalEvent/SeasonalPaymentStatus";
import BundleEvents from "./Component/BundleEvent/BundleEvents";
import OrgAbout from "./Component/OrgDashboard/OrgAbout";
import BundlePaymentStatus from "./Component/BundleEvent/BundlePaymentStatus";
import InvoiceProfile from "./Component/Profile/InvoiceProfile";
import ViewInvoiceProfile from "./Component/Profile/ViewInvoiceProfile";
import SeasonInvoice from "./Component/Invoice/SeasonInvoice";
import BundleInvoice from "./Component/Invoice/BundleInvoice";
import CreateTeam from "./Component/Team/CreateTeam";
import SupportChat from "./Component/SupportChat/SupportChat";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signin" element={<Login />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/support" element={<Support />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/all-event" element={<AllEvent />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route
          path="/event-type"
          element={<AuthGard Component={EventType} />}
        />
        <Route
          path="/create-event"
          element={<AuthGard Component={CreateEvent} />}
        />
        <Route
          path="/create-event/edit"
          element={<AuthGard Component={CreateEvent} />}
        />
        <Route path="/event-details/:eventID" element={<EventDetails />} />
        <Route path="/profile" element={<AuthGard Component={Profile} />} />
        <Route
          path="/organiser-profile"
          element={<AuthGard Component={OrganiserProfile} />}
        />
        <Route
          path="/org-dashboard"
          element={<AuthGard Component={OrgDashboard} />}
        />
        <Route path="/resell-event" element={<ResellEvent />} />
        <Route path="/resell-ticket/:eventID" element={<ResellTicket />} />
        <Route
          path="/resell-event-details/:resellID"
          element={<ResellEventDetail />}
        />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/payment-status"
          element={<AuthGard Component={PaymentStatus} />}
        />
        <Route path="/org-events" element={<AuthGard Component={OrgEvent} />} />
        <Route
          path="/org-payout"
          element={<AuthGard Component={OrgPayout} />}
        />
        <Route
          path="/org-report"
          element={<AuthGard Component={OrgReport} />}
        />
        <Route
          path="/organization-list"
          element={<AuthGard Component={OrganizationList} />}
        />
        <Route
          path="/org-landing/info"
          element={<AuthGard Component={OrgLandingPageInfo} />}
        />
        <Route
          path="/buy-ticket"
          element={<AuthGard Component={BuyTicket} />}
        />
        <Route
          path="/resell-buy-ticket"
          element={<AuthGard Component={ResellBuyTicket} />}
        />
        <Route
          path="/resell-payment"
          element={<AuthGard Component={ResellPaymentStatus} />}
        />
        <Route
          path="/create-event/configuration"
          element={<AuthGard Component={CreateEventConfiguration} />}
        />
        <Route
          path="/seat/configuration"
          element={<AuthGard Component={SeatConfiguration} />}
        />
        <Route
          path="/create-venue"
          element={<AuthGard Component={CreateVenue} />}
        />
        <Route
          path="/create-venue/configuration"
          element={<AuthGard Component={VenueConfiguration} />}
        />
        <Route
          path="/request-venue"
          element={<AuthGard Component={RequestVenue} />}
        />
        <Route
          path="/payment-status"
          element={<AuthGard Component={PaymentStatus} />}
        />
        <Route path="/invoice" element={<AuthGard Component={Invoice} />} />
        <Route
          path="/buy-invoice"
          element={<AuthGard Component={InvoiceBuy} />}
        />
        <Route
          path="/view-ticket-invoice"
          element={<AuthGard Component={ViewInvoiceProfile} />}
        />
        <Route
          path="/invoice-profile"
          element={<AuthGard Component={InvoiceProfile} />}
        />
        <Route
          path="/create-league"
          element={<AuthGard Component={LeagueCreate} />}
        />
        <Route
          path="/create-seasonal"
          element={<AuthGard Component={SeasonalPass} />}
        />
        <Route path="/landingpage" element={<LandingPage />} />
        <Route
          path="/seasonalevent/:seasonalticketid"
          element={<SeasonalEvent />}
        />{" "}
        <Route
          path="/seasonalevent/paymentstatus"
          element={<AuthGard Component={SeasonalPaymentStatus} />}
        />
        <Route path="/bundleevent/:bundleticketid" element={<BundleEvents />} />
        <Route
          path="/bundleevent/paymentstatus"
          element={<AuthGard Component={BundlePaymentStatus} />}
        />
        <Route
          path="/org-myteam"
          element={<AuthGard Component={OrgMyTeam} />}
        />
        <Route
          path="/create-bundle"
          element={<AuthGard Component={BundleCreate} />}
        />
        <Route path="/org-about" element={<AuthGard Component={OrgAbout} />} />
        <Route
          path="/create-team"
          element={<AuthGard Component={CreateTeam} />}
        />
        <Route
          path="/view-ticket-season"
          element={<AuthGard Component={SeasonInvoice} />}
        />
        <Route
          path="/view-ticket-bundle"
          element={<AuthGard Component={BundleInvoice} />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <SupportChat />
    </BrowserRouter>
  );
}

export default App;
