import React, { useEffect, useState } from "react";
import "../CreateEvent/CreateEvent.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Currency, timeZonecountries } from "../../utils/data";
import $ from "jquery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Divider, Modal } from "@mui/material";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RxCross2 } from "react-icons/rx";
import { FaInfoCircle } from "react-icons/fa";
import Loader from "../../utils/Loader";
import moment from "moment";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function CreateEvent() {
  const navigate = useNavigate();
  const data = useLocation();

  const eventIDforUpdate = data.state !== null ? data.state.event_id : 0;

  const venueIDforUpdate = data.state !== null ? data.state.venue_id : 0;
  const [text_ownername, setText_ownername] = useState("Display Owner Name");
  const [text_showAvailableTicket, setText_showAvailableTicket] = useState(
    "Show Available Ticket "
  );
  const [text_allow_transfer, setText_allow_transfer] =
    useState("Allow Transfer");
  const [text_allow_resell, setText_allow_resell] = useState("Allow Resell"); //new

  const [text_percentage_ticketPrice, setText_percentage_ticketPrice] =
    useState("Percentage Of Ticket Price");
  const [text_percentage_profit, setText_percentage_profit] = useState(
    "Percentage Of Profit"
  );
  const [text_percentage_resell, setText_percentage_resell] = useState(
    "Percentage of resell price"
  );
  const [text_fixedPrice, setText_fixedPrice] = useState("Fixed Price");
  const [text_ph_percentage_ticketPrice, setText_ph_percentage_ticketPrice] =
    useState("Enter Percentage of Ticket Price");
  const [text_ph_percentage_profit, setText_ph_percentage_profit] = useState(
    "Enter Percentage of Profit"
  );
  const [text_percentage_resellPrice, setText_percentage_resellPrice] =
    useState("Percentage On Resell Price");
  const [text_ph_percentage_resellPrice, setText_ph_percentage_resellPrice] =
    useState("Enter Percentage On Resell Price");
  const [text_resellFixed, setText_resellFixed] = useState(
    "Fixed Amount On Resell"
  );
  const [text_ph_resellFixed, setText_ph_resellFixed] = useState(
    "Enter Fixed Amount On Resell"
  );
  const [text_ph_currency, setText_ph_currency] = useState("Select Currency");
  const [text_network_currency, setText_network_currency] = useState(
    "Selected Network's Currency"
  );
  const [text_ph_nftNetwork, setText_ph_nftNetwork] =
    useState("Select NFT Network");
  const [text_ph_stableCoin, setText_ph_stableCoin] =
    useState("Select Stable Coin");
  const [text_ph_ticketType, setText_ph_ticketType] =
    useState("Select Ticket Type");
  const [text_nft, setText_nft] = useState("NFT");
  const [text_regular, setText_regular] = useState("Regular");
  const [text_nftNetwork, setText_nftNetwork] = useState("NFT Network");
  const [text_ticket_sellcurrency, setText_ticket_sellcurrency] = useState(
    "Ticket Sell Currency"
  );
  const [text_ph_ticketCyrrency, setText_ph_ticketCyrrency] = useState(
    "Select Ticket Currency"
  );
  const [text_totalnumber_ticket, setText_totalnumber_ticket] = useState(
    "Total Number of Tickets"
  );
  const [text_total_ValOfTicket, setText_total_ValOfTicket] = useState(
    "Total Value Of All Ticket"
  );
  const [text_crypto, setText_crypto] = useState("Crypto");
  const [text_Fiat, setText_Fiat] = useState("Fiat");
  const [text_ph_crypto, setText_ph_crypto] = useState("Select Crypto");
  const [text_stableCoin, setText_stableCoin] = useState("Stable Coin");
  const [text_currency, setText_currency] = useState("Currency");
  const [text_upload_image, setText_upload_image] = useState("Upload Image");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_save, setText_save] = useState("Save");
  const [text_ph_eveVenue, setText_ph_eveVenue] =
    useState("Select Event Venue");
  const [text_addNew, setText_addNew] = useState("Add New");
  const [text_requestvenue, setText_requestvenue] =
    useState("Request New Venue");
  const [text_timeZone, setText_timeZone] = useState("Time Zone");
  const [text_upload, setText_upload] = useState("Upload");
  const [text_add, setText_add] = useState("Add");
  const [text_ph_tags, setText_ph_tags] = useState("Enter Tags");
  const [text_err_eveTitle, setText_err_eveTitle] = useState(
    "Please Enter Event Title!"
  );
  const [text_err_ticketType, setText_err_ticketType] = useState(
    "Please Select Ticket Type"
  );
  const [text_err_eveVenue, setText_err_eveVenue] = useState(
    "Please Enter Event Venue!"
  );
  const [text_err_currency, setText_err_currency] = useState(
    "Please Select Currency"
  );
  const [text_err_NftNetwork, setText_err_NftNetwork] = useState(
    "Please Select Nft Network"
  );
  const [text_err_sellingCurrency, setText_err_sellingCurrency] = useState(
    "Please Select Ticket Selling Currency"
  );
  const [text_eveImage, setText_eveImage] = useState("Event Image");
  const [text_eveTitle, setText_eveTitle] = useState("Event Title");
  const [text_eveType, setText_eveType] = useState("Event Type");
  const [text_ph_eveTitle, setText_ph_eveTitle] = useState("Enter Event Title");
  const [text_eveID, setText_eveID] = useState("Event ID");
  const [text_startDate, setText_startDate] = useState("Start Date");
  const [text_startTime, setText_startTime] = useState("Start Time");
  const [text_endDate, setText_endDate] = useState("End Date");
  const [text_endTime, setText_endTime] = useState("End Time");
  const [text_eveDesc, setText_eveDesc] = useState("Event Description");
  const [text_ph_eveDesc, setText__ph_eveDesc] = useState(
    "Enter Event Description"
  );
  const [text_eventvenue, setText_eventvenue] = useState("Event Venue");
  const [text_location, setText_location] = useState("Location");
  const [text_eveCreator_taxID, setText_eveCreator_taxID] = useState(
    "Event Creator Tax ID"
  );
  const [text_ph_eveCreator_taxID, setText_ph_eveCreator_taxID] = useState(
    "Enter Tax ID Of Event Creator"
  );
  const [text_eveCreatorName, setText_eveCreatorName] =
    useState("Event Creator Name");
  const [text_ph_eveCreatorName, setText_ph_eveCreatorName] = useState(
    "Enter Name Of Event Creator"
  );
  const [text_sel_TimeZone, setText_sel_TimeZone] =
    useState("Select time zone");
  const [text_title_memorabilia, setText_title_memorabilia] = useState(
    "Add Memorabilia Images"
  );
  const [text_ticketType, setText_ticketType] = useState("Ticket Type");
  const [text_typeNftToken, setText_typeNftToken] =
    useState("Type of NFT Token");
  const [text_eveDetails, setText_eveDetails] = useState("Event Details");
  const [text_configure, setText_configure] = useState("Configure Price");
  const [text_selectleague, setText_selectleague] = useState("Select League");
  const [text_selectteam, setText_selectteam] = useState("Select Team");
  const [text_hometeam, setText_hometeam] = useState("Home Team");
  const [text_awayteam, setText_awayteam] = useState("Away Team");
  const [text_selhometeam, setText_selhometeam] = useState("Select Home Team");
  const [text_selawayteam, setText_selawayteam] = useState("Select Away Team");
  const [text_vs, setText_vs] = useState("vs");
  const [text_configureseats, setText_configureseats] = useState(
    "Create and Configure Seats"
  );
  const [text_createonlineevent, setText_createonlineevent] = useState(
    "Create Online Event"
  );
  // new
  const [text_err_select_eveVenue, setText_err_select_eveVenue] = useState(
    "Please Select Event Venue!"
  );
  const [text_update, setText_update] = useState("Update");
  const [text_selteamtype, setText_selteamtype] = useState("Select Team Type");
  const [btn_create_event, setBtn_create_event] = useState("Create Event");
  const [text_orgdashboard, setText_orgdashboard] = useState(
    "Organization Dashboard"
  );
  const [text_home, setText_home] = useState("Home");

  const [transferData, setTransferData] = useState("");
  const [resellData, setResellData] = useState("");
  const [ownername, setOwnername] = useState(false);
  const [showAvailable, setShowAvailable] = useState("");

  const [resellOnTicketPrice, setResellOnTicketPrice] = useState(false);
  const [resellOnProfit, setResellOnProfit] = useState(false);
  const [resellOnResellPrice, setResellOnResellPrice] = useState(false);
  const [resellOnResellFixedPrice, setResellOnResellFixedPrice] =
    useState(false);
  const [resellTicketPercentage, setResellTicketPercentage] = useState("");
  const [resellProfitPercentage, setResellProfitPercentage] = useState("");
  const [resellPriceProfit, setResellPriceProfit] = useState("");

  const [resellFixedProfit, setResellFixedProfit] = useState("");
  const [currency, setCurrency] = useState("");
  const [Cryptocurrency, setCryptocurrency] = useState("");
  const [typeOfToken, setTypeOfToken] = useState("ERC-721");
  const [nonCrypto, setNonCrypto] = useState("");
  const [selectedCryptoCurrency, setSelectedCryptoCurrency] = useState("");
  const [slectedTicketCurrency, setSlectedTicketCurrency] = useState("");
  const [ticketCurrency, setTicketCurrency] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventID, setEventID] = useState("");
  const [utcMomentStartTime, setUtcMomentStartTime] = useState("");
  const [utcMomentEndTime, setUtcMomentEndTime] = useState("");
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [startDate, setStartDate] = useState(tomorrow);

  // useEffect(() => {
  //   startDate.setDate(startDate.getDate() + 1);
  // }, []);
  const [endDate, setEndDate] = useState(tomorrow);
  const [eventDes, setEventDes] = useState("");
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  useEffect(() => {
    // Ensure that the end date is after the start date on page load
    setEndTime(new Date(startTime.getTime() + 60 * 60 * 1000)); // Adding 1 hour as an example
  }, [startDate]);
  const filterTime = (time) => {
    const selectedDate = new Date(time);
    return startTime < selectedDate.getTime();
  };
  const [venueList, setVenueList] = useState([]);
  const [venueID, setVenueID] = useState("");
  const [eventVenue, setEventVenue] = useState("");
  const [errEventVenue, setErrEventVenue] = useState("");

  const [errEventTitle, setErrEventTitle] = useState("");
  var timezones = timeZonecountries[0];
  const FinalData = timezones;
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [eventCreatorTaxID, setEventCreatorTaxID] = useState("");
  const [eventCreatorName, setEventCreatorName] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errEventTicketType, setErrEventTicketType] = useState("");
  const [errTypeRegularCurrency, setErrTypeRegularCurrency] = useState("");
  const [errTypeNftNetwork, setErrTypeNftNetwork] = useState("");
  const [errSellCurrencyType, setErrSellCurrencyType] = useState("");
  const [errSellCurrency, setErrSellCurrency] = useState("");
  const [sum, setSum] = useState("");
  const [sumAmount, setSumAmount] = useState(0);

  const TeamList = JSON.parse(sessionStorage.getItem("teamList"));
  console.log(TeamList);

  // Event Image Start
  const [openEveImageUploadPopup, setOpenEveImageUploadPopup] = useState(false);
  const handleOpenEveImagePopup = () => setOpenEveImageUploadPopup(true);
  const handleCloseEveImagePopup = () => setOpenEveImageUploadPopup(false);
  const [Loading, setLoading] = useState(false);
  const [eventImage, setEventImage] = useState("");
  const [eventFilePath, setEventFilePath] = useState("");

  const styleTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };

  const EventimguplpoadValidation = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setEventImage(file);
        // You can perform further actions with the selected file here
      } else {
        setEventImage(null);
        alert("Please upload only JPEG or PNG files.");
      }
    }
  };

  const handleEveImgUpload = () => {
    setLoading(true);
    var API_URL = API_DOMAIN + "file/upload";
    var imgdata = new FormData();
    imgdata.append("att_file", eventImage);
    let headerConfig = {
      headers: {
        "Content-Type": "form-data",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .post(API_URL, imgdata, headerConfig)
      .then(function (response) {
        console.log("Img Upload", response);
        if (response.status === 200) {
          setLoading(false);
          setEventFilePath(response.data.data.Location);
          handleCloseEveImagePopup();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
          setTimeout(() => {
            setLoading(false);
            handleCloseEveImagePopup();
            setEventImage("");
          }, 1000);
        }
      })
      .then(function () {});
  };
  // Event Image End

  // Memorabilia Imgae Start
  const [tagValueMemorabilia, setTagValueMemorabilia] = useState("");
  const [memorabiliaImage, setMemorabiliaImage] = useState("");
  const [memorabiliaLoading, setMemorabiliaLoading] = useState(false);
  const [memorabiliaArry, setMemorabiliaArry] = useState([]);
  const [tagValue, setTagValue] = useState([]);

  const [openMemorabiliaImageUploadPopup, setOpenMemorabiliaImageUploadPopup] =
    useState(false);
  const handleOpenMemorabiliaPopup = () =>
    setOpenMemorabiliaImageUploadPopup(true);
  const handleCloseMemorabiliaPopup = () => {
    setOpenMemorabiliaImageUploadPopup(false);
    setTagValueMemorabilia("");
    setMemorabiliaImage("");
  };

  const MemorabiliaimguplpoadValidation = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setMemorabiliaImage(file);
        // You can perform further actions with the selected file here
      } else {
        setMemorabiliaImage(null);
        alert("Please upload only JPEG or PNG files.");
      }
    }
  };
  const handleMemorabiliaImgUpload = () => {
    setMemorabiliaLoading(true);
    var API_URL = API_DOMAIN + "file/upload";
    var imgdata = new FormData();
    imgdata.append("att_file", memorabiliaImage);
    let headerConfig = {
      headers: {
        "Content-Type": "form-data",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .post(API_URL, imgdata, headerConfig)
      .then(function (response) {
        console.log("Memorabilia Img Upload", response.data.data.Location);
        if (response.status === 200) {
          setMemorabiliaLoading(false);
          saveurlsHandle(
            response.data.data.Location,
            tagValueMemorabilia,
            memorabiliaImage.name
          );
          handleCloseMemorabiliaPopup();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
          setMemorabiliaLoading(false);
        }
      })
      .then(function () {});
  };
  const saveurlsHandle = (url, tagvalue, filename) => {
    console.log("urllllllllll", url);
    var newUrl = {};
    newUrl.filepath = url;
    newUrl.filetag = tagvalue;
    newUrl.filekey = filename;

    setMemorabiliaArry([...memorabiliaArry, newUrl]);
    setSelectedFiles([...selectedFiles, newUrl]);
  };
  const handleRemoveImgFunc = (index, file) => {
    const newSelectedFiles = selectedFiles.filter((item) => item !== file);
    setSelectedFiles(newSelectedFiles);

    const newTagValues = tagValue.filter((tag, i) => i !== index);
    console.log("newTagValues***", newTagValues);

    // var newTagValueArr = tagValue.splice(index, 1);
    // setTagValue(newTagValueArr)
  };
  // get venue list
  const getVenueDetails = () => {
    var API_URL = API_DOMAIN + "venue/venue";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("venue", response);
        setVenueList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getVenueDetails();
  }, []);
  const [selectedVenueList, setSelectedVenueList] = useState([]);
  const [selectedVenueSeatingArrengment, setSelectedVenueSeatingArrengment] =
    useState([]);
  const getselectedvenuedetails = (id) => {
    for (let i = 0; i < venueList.length; i++) {
      if (venueList[i].venueid === id) {
        console.log("matched", venueList[i]);
        const dummy = [venueList[i]];
        setSelectedVenueList(dummy);
        console.log(dummy[0].seatingarrangement);
        var tempArray = [];
        for (let i = 0; i < dummy[0].seatingarrangement.length; i++) {
          let doc = dummy[0].seatingarrangement[i];
          let dc = {
            section_id: doc.section_id,
            ticket_category: doc.section,
            no_tickets: doc.number_of_seat,
            zone: doc.zone,
            section: doc.section,
            subsection: doc.subsection,
            ticket_price: doc.price,
            total_value: doc.sectiontotal,
            rows: doc.rows,
            column: doc.column,
            entrygate: doc.entrygate,
          };
          tempArray.push(dc);
          console.log("dc", dc, doc);
        }
        console.log("temp", tempArray);
        setSelectedVenueSeatingArrengment(tempArray);
      }
    }
  };
  // Create Event

  const CreateEventValidation = () => {
    var isCreateEvent = true;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (!eventTitle) {
      isCreateEvent = false;
      setErrEventTitle(<>{text_err_eveTitle}</>);
    }

    if (!eventVenue) {
      isCreateEvent = false;
      setErrEventVenue(<>{text_err_select_eveVenue}</>);
    }
    if (!currency) {
      isCreateEvent = false;
      setErrEventTicketType(<>{text_err_ticketType}!</>);
    }
    if (currency === "non_crypto" && !nonCrypto) {
      isCreateEvent = false;
      setErrTypeRegularCurrency(<>{text_err_currency}!</>);
    }
    if (currency === "crypto" && !Cryptocurrency) {
      isCreateEvent = false;
      setErrTypeNftNetwork(<>{text_err_NftNetwork}!</>);
    }
    if (!ticketCurrency) {
      isCreateEvent = false;
      setErrSellCurrencyType(<>{text_err_sellingCurrency}!</>);
    }
    if (ticketCurrency !== "" && !slectedTicketCurrency) {
      isCreateEvent = false;
      setErrSellCurrency(<>{text_err_currency}!</>);
    }

    return isCreateEvent;
  };

  const RandomeText = (length) => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  useEffect(() => {
    if (selectedVenueSeatingArrengment.length !== 0) {
      const sum = selectedVenueSeatingArrengment
        .map((datum) => datum.no_tickets)
        .reduce((a, b) => parseInt(a) + parseInt(b));
      console.log(sum);
      setSum(sum);
      const sumAmount = selectedVenueSeatingArrengment
        .map((datum) => parseInt(datum.total_value))
        .reduce((a, b) => parseInt(a) + parseInt(b));
      console.log(sumAmount);
      setSumAmount(sumAmount);
    }
  }, [selectedVenueSeatingArrengment]);
  const EventCreateHandle = () => {
    console.log("first", selectedFiles, showAvailable);
    if (CreateEventValidation()) {
      var getHours = selectedTimeZone.slice(1, 3);
      var getMinitus = selectedTimeZone.slice(4, 6);
      var hourtoMinitus = Math.floor(getHours * 60);
      var FinalTimezoneOffset = -(Number(hourtoMinitus) + Number(getMinitus));

      var API_URL = API_DOMAIN + "event/create?type=insert";

      let headerConfig = {
        headers: {
          authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };
      var data = {
        EventTitle: eventTitle,
        EventImageKey: "k",
        EventImagePath: eventFilePath === "" ? "path" : eventFilePath,
        EventType: eventType,
        EventID: eventID === "" ? RandomeText(5) : eventID,
        StartDate: moment.utc(startDate).format("yyyy-MM-DD"),
        EndDate: moment.utc(endDate).format("yyyy-MM-DD"),
        EventDescription: eventDes,
        StartTime: utcMomentStartTime,
        EndTime: utcMomentEndTime,
        venuedetails: selectedVenueList,
        Memorabilia: selectedFiles,
        ticket_type: currency,
        currency: nonCrypto,
        nft_token_type: typeOfToken,
        nft_token: "s",
        nft_network: Cryptocurrency,
        nft_currency: selectedCryptoCurrency,
        sell_currency_type: ticketCurrency,
        sell_currency: slectedTicketCurrency,
        TicketDetailsList: selectedVenueSeatingArrengment,
        TotalNumberTickets: sum,
        TotalAmount: sumAmount,
        TransferPercentage: transferData,
        ResellPercentageTicketPrice: resellTicketPercentage,
        ResellPercentageTicketProfit: resellProfitPercentage,
        resellpriceprofit: resellPriceProfit,
        resellfixedprice: resellFixedProfit,
        // passDetails: passinputFields,
        // partnername: partnerName,
        // partnerid: partnerName,
        timezoneoffset: FinalTimezoneOffset,
        EventCreaterTaxId: eventCreatorTaxID,
        EventCreaterName: eventCreatorName,
        OwnerName: ownername,
        // PaperlessTicketDetailsList: inputFieldsoffline,
        showAvailableTicket: showAvailable,
        venueid: venueID,
        leagueid: selectedleagueId,
        gameid: selectedGameId,
        teamname: homeTeamName,
        teamtype: teamType,
        teamid: homeTeamId,
        isFutureEvent: futureEvent === true ? "true" : "false",
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("create", response.data.data.event_unique_id);
          if (response.status === 200) {
            navigate("/create-event/configuration", {
              state: { event_id: response.data.data.event_unique_id },
            });
            // setEveUniqID(response.data.data.event_unique_id);
            // setShownextStep(true);
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .then(function () {});
    }
  };

  const [leagueDetailsList, setLeagueDetailsList] = useState([]);
  const getLeagueDetails = () => {
    var API_URL = API_DOMAIN + "league/getall";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("league details", response.data.data);
        setLeagueDetailsList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getLeagueDetails();
  }, []);
  const [selectedLeagueGameList, setSelectedLeagueGameList] = useState([]);
  const [homeTeam, setHomeTeam] = useState("");
  const [awayTeam, setAwayTeam] = useState("");
  const [selectedleagueId, setSelectedleagueId] = useState("");
  const [selectedGameId, setSelectedGameId] = useState("");
  const [teamType, setTeamType] = useState("");
  const [selectedLeagueName, setSelectedLeagueName] = useState("");
  const getSelectedLeagueDetails = (selectedId) => {
    const filteredData = leagueDetailsList.filter(
      (item) => item.leagueid === selectedId
    );
    setSelectedLeagueGameList(filteredData[0].gamelist);
    setSelectedleagueId(filteredData[0].leagueid);
    setSelectedLeagueName(filteredData[0].leaguename);
    console.log("selected league", filteredData);
  };
  const [awayTeamsList, setAwayTeamsList] = useState([]);
  const [homeTeamName, setHomeTeamName] = useState("");
  const [homeTeamId, setHomeTeamId] = useState("");
  const [futureEvent, setFutureEvent] = useState(false);
  const getAwayTeamsList = (ht) => {
    const filteredData = selectedLeagueGameList.filter(
      (item) => item.home === ht
    );
    setHomeTeam(ht);
    // setSelectedLeagueGameList(filteredData);
    console.log("home team game list", filteredData);
    setAwayTeamsList(filteredData);
  };
  const [selectedTeamLeagueList, setSelectedTeamLeagueList] = useState([]);
  const getSelectedLeague = (teamid) => {
    console.log(teamid, leagueDetailsList);
    const filteredData = TeamList.filter((item) => item.teamid === teamid);
    setHomeTeamName(filteredData[0].teamname);
    setHomeTeamId(filteredData[0].teamid);
    var leagueArr = [];
    console.log("filteredData", filteredData[0].teamname);
    leagueDetailsList.forEach((element) => {
      var dummy = element.leagueteams;
      console.log(dummy);
      for (let i = 0; i < dummy.length; i++) {
        if (dummy[i].teamid === teamid) {
          console.log("matched", element);
          leagueArr.push(element);
          console.log(leagueArr);
          setSelectedleagueId(element?.leagueid);
          setSelectedTeamLeagueList(leagueArr);
        }
      }
    });
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_sel_TimeZone(
        xpath.select1("/resources/string[@name='text_sel_TimeZone']", doc)
          .firstChild.data
      );
      setText_showAvailableTicket(
        xpath.select1(
          "/resources/string[@name='text_showAvailableTicket']",
          doc
        ).firstChild.data
      );
      setText_ownername(
        xpath.select1("/resources/string[@name='text_ownername']", doc)
          .firstChild.data
      );
      setText_allow_transfer(
        xpath.select1("/resources/string[@name='text_allow_transfer']", doc)
          .firstChild.data
      );
      setText_allow_resell(
        xpath.select1("/resources/string[@name='text_allow_resell']", doc)
          .firstChild.data
      );
      setText_percentage_ticketPrice(
        xpath.select1("/resources/string[@name='text_allow_resell']", doc)
          .firstChild.data
      );
      setText_percentage_profit(
        xpath.select1("/resources/string[@name='text_percentage_profit']", doc)
          .firstChild.data
      );
      setText_percentage_resell(
        xpath.select1("/resources/string[@name='text_percentage_resell']", doc)
          .firstChild.data
      );
      setText_fixedPrice(
        xpath.select1("/resources/string[@name='text_fixedPrice']", doc)
          .firstChild.data
      );
      setText_ph_percentage_ticketPrice(
        xpath.select1(
          "/resources/string[@name='text_ph_percentage_ticketPrice']",
          doc
        ).firstChild.data
      );
      setText_ph_percentage_profit(
        xpath.select1(
          "/resources/string[@name='text_ph_percentage_profit']",
          doc
        ).firstChild.data
      );
      setText_percentage_resellPrice(
        xpath.select1(
          "/resources/string[@name='text_percentage_resellPrice']",
          doc
        ).firstChild.data
      );
      setText_ph_percentage_resellPrice(
        xpath.select1(
          "/resources/string[@name='text_ph_percentage_resellPrice']",
          doc
        ).firstChild.data
      );
      setText_resellFixed(
        xpath.select1("/resources/string[@name='text_resellFixed']", doc)
          .firstChild.data
      );
      setText_ph_resellFixed(
        xpath.select1("/resources/string[@name='text_ph_resellFixed']", doc)
          .firstChild.data
      );
      setText_ph_currency(
        xpath.select1("/resources/string[@name='text_ph_currency']", doc)
          .firstChild.data
      );
      setText_network_currency(
        xpath.select1("/resources/string[@name='text_network_currency']", doc)
          .firstChild.data
      );
      setText_ph_nftNetwork(
        xpath.select1("/resources/string[@name='text_ph_nftNetwork']", doc)
          .firstChild.data
      );
      setText_ph_stableCoin(
        xpath.select1("/resources/string[@name='text_ph_stableCoin']", doc)
          .firstChild.data
      );
      setText_ph_ticketType(
        xpath.select1("/resources/string[@name='text_ph_ticketType']", doc)
          .firstChild.data
      );
      setText_nft(
        xpath.select1("/resources/string[@name='text_nft']", doc).firstChild
          .data
      );
      setText_regular(
        xpath.select1("/resources/string[@name='text_regular']", doc).firstChild
          .data
      );
      setText_nftNetwork(
        xpath.select1("/resources/string[@name='text_nftNetwork']", doc)
          .firstChild.data
      );
      setText_ticket_sellcurrency(
        xpath.select1(
          "/resources/string[@name='text_ticket_sellcurrency']",
          doc
        ).firstChild.data
      );
      setText_ph_ticketCyrrency(
        xpath.select1("/resources/string[@name='text_ph_ticketCyrrency']", doc)
          .firstChild.data
      );
      setText_crypto(
        xpath.select1("/resources/string[@name='text_crypto']", doc).firstChild
          .data
      );
      setText_Fiat(
        xpath.select1("/resources/string[@name='text_Fiat']", doc).firstChild
          .data
      );
      setText_ph_crypto(
        xpath.select1("/resources/string[@name='text_ph_crypto']", doc)
          .firstChild.data
      );
      setText_stableCoin(
        xpath.select1("/resources/string[@name='text_stableCoin']", doc)
          .firstChild.data
      );
      setText_currency(
        xpath.select1("/resources/string[@name='text_currency']", doc)
          .firstChild.data
      );
      setText_upload_image(
        xpath.select1("/resources/string[@name='text_upload_image']", doc)
          .firstChild.data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_ph_eveVenue(
        xpath.select1("/resources/string[@name='text_ph_eveVenue']", doc)
          .firstChild.data
      );
      setText_addNew(
        xpath.select1("/resources/string[@name='text_addNew']", doc).firstChild
          .data
      );
      setText_requestvenue(
        xpath.select1("/resources/string[@name='text_requestvenue']", doc)
          .firstChild.data
      );
      setText_timeZone(
        xpath.select1("/resources/string[@name='text_timeZone']", doc)
          .firstChild.data
      );
      setText_upload(
        xpath.select1("/resources/string[@name='text_upload']", doc).firstChild
          .data
      );
      setText_add(
        xpath.select1("/resources/string[@name='text_add']", doc).firstChild
          .data
      );
      setText_ph_tags(
        xpath.select1("/resources/string[@name='text_ph_tags']", doc).firstChild
          .data
      );
      setText_err_eveTitle(
        xpath.select1("/resources/string[@name='text_err_eveTitle']", doc)
          .firstChild.data
      );
      setText_err_ticketType(
        xpath.select1("/resources/string[@name='text_err_ticketType']", doc)
          .firstChild.data
      );
      setText_err_eveVenue(
        xpath.select1("/resources/string[@name='text_err_eveVenue']", doc)
          .firstChild.data
      );
      setText_err_currency(
        xpath.select1("/resources/string[@name='text_err_currency']", doc)
          .firstChild.data
      );
      setText_err_NftNetwork(
        xpath.select1("/resources/string[@name='text_err_NftNetwork']", doc)
          .firstChild.data
      );
      setText_err_sellingCurrency(
        xpath.select1(
          "/resources/string[@name='text_err_sellingCurrency']",
          doc
        ).firstChild.data
      );
      setText_eveImage(
        xpath.select1("/resources/string[@name='text_eveImage']", doc)
          .firstChild.data
      );
      setText_eveTitle(
        xpath.select1("/resources/string[@name='text_eveTitle']", doc)
          .firstChild.data
      );
      setText_eveType(
        xpath.select1("/resources/string[@name='text_eveType']", doc).firstChild
          .data
      );
      setText_ph_eveTitle(
        xpath.select1("/resources/string[@name='text_ph_eveTitle']", doc)
          .firstChild.data
      );
      setText_eveID(
        xpath.select1("/resources/string[@name='text_eveID']", doc).firstChild
          .data
      );
      setText_startDate(
        xpath.select1("/resources/string[@name='text_startDate']", doc)
          .firstChild.data
      );
      setText_startTime(
        xpath.select1("/resources/string[@name='text_startTime']", doc)
          .firstChild.data
      );
      setText_endDate(
        xpath.select1("/resources/string[@name='text_endDate']", doc).firstChild
          .data
      );
      setText_endTime(
        xpath.select1("/resources/string[@name='text_endTime']", doc).firstChild
          .data
      );
      setText_eveDesc(
        xpath.select1("/resources/string[@name='text_eveDesc']", doc).firstChild
          .data
      );
      setText__ph_eveDesc(
        xpath.select1("/resources/string[@name='text_ph_eveDesc']", doc)
          .firstChild.data
      );
      setText_eventvenue(
        xpath.select1("/resources/string[@name='text_eventvenue']", doc)
          .firstChild.data
      );
      setText_location(
        xpath.select1("/resources/string[@name='text_location']", doc)
          .firstChild.data
      );
      setText_eveCreator_taxID(
        xpath.select1("/resources/string[@name='text_eveCreator_taxID']", doc)
          .firstChild.data
      );
      setText_title_memorabilia(
        xpath.select1("/resources/string[@name='text_title_memorabilia']", doc)
          .firstChild.data
      );
      setText_ph_eveCreator_taxID(
        xpath.select1(
          "/resources/string[@name='text_ph_eveCreator_taxID']",
          doc
        ).firstChild.data
      );
      setText_eveCreatorName(
        xpath.select1("/resources/string[@name='text_eveCreatorName']", doc)
          .firstChild.data
      );
      setText_ph_eveCreatorName(
        xpath.select1("/resources/string[@name='text_ph_eveCreatorName']", doc)
          .firstChild.data
      );
      setText_ticketType(
        xpath.select1("/resources/string[@name='text_ticketType']", doc)
          .firstChild.data
      );
      setText_eveDetails(
        xpath.select1("/resources/string[@name='text_eveDetails']", doc)
          .firstChild.data
      );
      setText_typeNftToken(
        xpath.select1("/resources/string[@name='text_typeNftToken']", doc)
          .firstChild.data
      );
      setText_configure(
        xpath.select1("/resources/string[@name='text_configure']", doc)
          .firstChild.data
      );
      setText_selectleague(
        xpath.select1("/resources/string[@name='text_selectleague']", doc)
          .firstChild.data
      );
      setText_selectteam(
        xpath.select1("/resources/string[@name='text_selectteam']", doc)
          .firstChild.data
      );
      setText_hometeam(
        xpath.select1("/resources/string[@name='text_hometeam']", doc)
          .firstChild.data
      );
      setText_awayteam(
        xpath.select1("/resources/string[@name='text_awayteam']", doc)
          .firstChild.data
      );
      setText_selhometeam(
        xpath.select1("/resources/string[@name='text_selhometeam']", doc)
          .firstChild.data
      );
      setText_selawayteam(
        xpath.select1("/resources/string[@name='text_selawayteam']", doc)
          .firstChild.data
      );
      setText_vs(
        xpath.select1("/resources/string[@name='text_vs']", doc).firstChild.data
      );
      setText_configureseats(
        xpath.select1("/resources/string[@name='text_configureseats']", doc)
          .firstChild.data
      );
      setText_createonlineevent(
        xpath.select1("/resources/string[@name='text_createonlineevent']", doc)
          .firstChild.data
      );
      setText_err_select_eveVenue(
        xpath.select1(
          "/resources/string[@name='text_err_select_eveVenue']",
          doc
        ).firstChild.data
      );
      setText_update(
        xpath.select1("/resources/string[@name='text_update']", doc).firstChild
          .data
      );
      setText_selteamtype(
        xpath.select1("/resources/string[@name='text_selteamtype']", doc)
          .firstChild.data
      );
      setBtn_create_event(
        xpath.select1("/resources/string[@name='btn_create_event']", doc)
          .firstChild.data
      );
      setText_orgdashboard(
        xpath.select1("/resources/string[@name='setText_orgdashboard']", doc)
          .firstChild.data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  const GetEventDetails = () => {
    var type = "select";
    var event_unique_id = eventIDforUpdate;
    var API_URL =
      API_DOMAIN +
      "event/public?type=" +
      type +
      "&event_unique_id=" +
      event_unique_id;

    axios
      .get(API_URL)
      .then(function (response) {
        console.log("Detaied Event", response.data.data);
        var res = response.data.data;
        setEventTitle(res[0].EventTitle);
        setEventFilePath(res[0].eventFilePath);
        setEventType(res[0].EventType);
        setEventID(res[0].EventID);
        setStartDate(new Date(res[0].StartDate));
        setEndDate(new Date(res[0].EndDate));
        setEventDes(res[0].EventDescription);
        setStartTime(new Date(res[0].StartTime));
        setEndTime(new Date(res[0].EndTime));
        setSelectedTimeZone(res[0].timezoneoffset);
        setEventCreatorTaxID(res[0].EventCreaterTaxId);
        setEventCreatorName(res[0].EventCreaterName);
        setSelectedFiles(res[0].Memorabilia);
        setHomeTeamName(res[0].teamname);
        setTeamType(res[0].teamtype);
        setEventVenue(res[0].venuedetails[0].venuename);
        setShowAvailable(res[0].showAvailableTicket);
        setOwnername(res[0].OwnerName);
        setTransferData(res[0].TransferPercentage);
        setCurrency(res[0].ticket_type);
        setTypeOfToken(res[0].nft_token_type);
        setCryptocurrency(res[0].nft_network);
        setSelectedCryptoCurrency(res[0].nft_currency);
        setNonCrypto(res[0].currency);
        setTicketCurrency(res[0].sell_currency_type);
        setSlectedTicketCurrency(res[0].sell_currency);
        if (
          res[0].ResellPercentageTicketPrice === null &&
          res[0].ResellPercentageTicketProfit === null &&
          res[0].resellfixedprice === null &&
          res[0].resellpriceprofit === null
        ) {
          setResellData(false);
        } else {
          setResellData(true);
        }
        if (res[0].ResellPercentageTicketPrice !== null) {
          setResellOnTicketPrice(true);
        } else if (res[0].ResellPercentageTicketProfit !== null) {
          setResellOnProfit(true);
        } else if (res[0].resellfixedprice !== null) {
          setResellOnResellFixedPrice(true);
        } else if (res[0].resellpriceprofit !== null) {
          setResellOnResellPrice(true);
        }
        setResellTicketPercentage(res[0].ResellPercentageTicketPrice);
        setResellProfitPercentage(res[0].ResellPercentageTicketProfit);
        setResellPriceProfit(res[0].resellpriceprofit);
        setResellFixedProfit(res[0].resellfixedprice);
      })
      .catch(function (error) {
        // errorToast(err);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    GetEventDetails();
  }, []);
  const UpdateEventHandle = () => {
    // if (CreateEventValidation()) {
    // var getHours = selectedTimeZone.slice(1, 3);
    // var getMinitus = selectedTimeZone.slice(4, 6);
    // var hourtoMinitus = Math.floor(getHours * 60);
    // var FinalTimezoneOffset = -(Number(hourtoMinitus) + Number(getMinitus));

    var API_URL = API_DOMAIN + "event/update";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      event_unique_id: eventIDforUpdate,
      EventTitle: eventTitle,
      EventImageKey: "k",
      EventImagePath: eventFilePath === "" ? "path" : eventFilePath,
      EventType: eventType,
      EventID: eventID === "" ? RandomeText(5) : eventID,
      StartDate: moment.utc(startDate).format("yyyy-MM-DD"),
      EndDate: moment.utc(endDate).format("yyyy-MM-DD"),
      EventDescription: eventDes,
      StartTime: startTime,
      EndTime: endTime,

      venueid: venueIDforUpdate,
    };
    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("create", response.data.data.event_unique_id);
        if (response.status === 200) {
          navigate("/profile");
          // setEveUniqID(response.data.data.event_unique_id);
          // setShownextStep(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
    // }
  };

  const StartTimeSelectManually = (date) => {
    const timeString = moment(date).format("HH:mm:ss");
    const today = new Date();
    const year = today.getUTCFullYear();
    const month = String(today.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so we add 1
    const day = String(today.getUTCDate()).padStart(2, "0");

    // Combine today's date with the given time string (assuming it's in UTC)
    //YYYY-MM-DD HH:mm
    const utcDateTimeString = `${year}-${month}-${day} ${timeString}`; // 'Z' indicates UTC
    console.log(utcDateTimeString);

    let utcMomentStartTime_temp = moment.utc(utcDateTimeString).toISOString();

    //const localTime = moment.utc(utcDateTimeString, ); // Assumes today's date

    // Convert to UTC
    // const utcTime = localTime.utc().format('dd-mm-yyy HH:mm:ss'); // Format as UTC time

    console.log("utcTime", utcMomentStartTime_temp);
    setStartTime(date);
    setEndTime(date);
    setUtcMomentStartTime(utcMomentStartTime_temp);
    // console.log(moment.utc(date))
  };
  const EndTimeSelectManually = (date) => {
    const timeString = moment(date).format("HH:mm:ss");
    const today = new Date();
    const year = today.getUTCFullYear();
    const month = String(today.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so we add 1
    const day = String(today.getUTCDate()).padStart(2, "0");

    // Combine today's date with the given time string (assuming it's in UTC)
    //YYYY-MM-DD HH:mm
    const utcDateTimeString = `${year}-${month}-${day} ${timeString}`; // 'Z' indicates UTC
    console.log(utcDateTimeString);

    let utcMomentStartTime_temp = moment.utc(utcDateTimeString).toISOString();

    //const localTime = moment.utc(utcDateTimeString, ); // Assumes today's date

    // Convert to UTC
    // const utcTime = localTime.utc().format('dd-mm-yyy HH:mm:ss'); // Format as UTC time

    console.log("utcTime", utcMomentStartTime_temp);
    setEndTime(date);
    setUtcMomentEndTime(utcMomentStartTime_temp);
    // console.log(moment.utc(date))
  };
  return (
    <>
      <Header />
      <div
        className="Create-event-wrapper eventdetail-nightmod"
        id="otherPages"
      >
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/org-dashboard">{text_orgdashboard}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/event-type">{text_eveType}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {btn_create_event}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center">
                  <h3>{text_createonlineevent}</h3>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9 col-md-12">
                <div className="wizard-steps-block">
                  <div id="add-event-tab" className="step-app">
                    <ul className="step-steps">
                      <li className="active">
                        <Link to="#tab_step1">
                          <span className="number"></span>
                          <span className="step-name">{text_eveDetails}</span>
                        </Link>
                      </li>
                      <li></li>
                      <li>
                        <Link to="#tab_step2">
                          <span className="number"></span>
                          <span className="step-name">{text_configure}</span>
                        </Link>
                      </li>
                    </ul>
                    <div className="step-content">
                      <div
                        className="step-tab-panel step-tab-info active"
                        id="tab_step1"
                      >
                        <div className="tab-from-content">
                          <div className="main-card">
                            <div className="bp-title">
                              <h4>
                                <FaInfoCircle className=" step_icon me-3" />
                                {text_eveDetails}
                              </h4>
                            </div>
                            <div className="p-4 bp-form main-form">
                              <div className="row">
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_eveImage}
                                    </label>
                                    <button
                                      className="btn btn-default btn-hover steps_btn mt-2"
                                      onClick={handleOpenEveImagePopup}
                                    >
                                      {text_upload}
                                    </button>
                                    {eventFilePath !== "" ? (
                                      <>
                                        <img
                                          src={eventFilePath}
                                          alt=""
                                          className="eventImg"
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_eveTitle}
                                      <span className="starsty">*</span>
                                    </label>
                                    <input
                                      className="form-control h_50 inputleaguenight"
                                      type="text"
                                      value={eventTitle}
                                      placeholder={text_ph_eveTitle}
                                      onChange={(e) => [
                                        setEventTitle(e.target.value),
                                        setErrEventTitle(""),
                                      ]}
                                    />
                                    <span className="starsty">
                                      {errEventTitle}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_eveType}
                                    </label>
                                    <input
                                      className="form-control h_50 inputleaguenight"
                                      type="text"
                                      value={eventType}
                                      placeholder="Enter Event Type"
                                      onChange={(e) =>
                                        setEventType(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_eveID}
                                    </label>
                                    <input
                                      className="form-control h_50 inputleaguenight"
                                      type="text"
                                      placeholder="Enter Event ID"
                                      value={
                                        eventID === ""
                                          ? RandomeText(5)
                                          : eventID
                                      }
                                      onChange={(e) => [
                                        setEventID(e.target.value),
                                      ]}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_startDate}
                                    </label>
                                    <DatePicker
                                      className="form-control dateInputSty h_50 inputleaguenight"
                                      minDate={tomorrow}
                                      selected={startDate}
                                      dateFormat="dd MMM,yyyy"
                                      onChange={(date) => [
                                        setStartDate(date),
                                        setEndDate(date),
                                      ]}
                                    />
                                    <br /> <br />
                                    <span className=" ">
                                      <input
                                        type="checkbox"
                                        className="mx-2"
                                        onChange={(e) =>
                                          setFutureEvent(e.target.checked)
                                        }
                                      />
                                      To be decided
                                    </span>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_endDate}
                                    </label>

                                    <DatePicker
                                      className="form-control dateInputSty h_50 inputleaguenight"
                                      selected={endDate}
                                      minDate={startDate}
                                      dateFormat="dd MMM,yyyy"
                                      onChange={(date) => setEndDate(date)}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_eveDesc}
                                    </label>
                                    <input
                                      className="form-control h_50 inputleaguenight"
                                      type="text"
                                      placeholder={text_ph_eveDesc}
                                      onChange={(e) =>
                                        setEventDes(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_startTime}
                                    </label>

                                    <DatePicker
                                      className="form-control dateInputSty h_50 inputleaguenight"
                                      selected={startTime}
                                      onChange={(date) => [
                                        StartTimeSelectManually(date),
                                      ]}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={10}
                                      dateFormat="h:mm aa"
                                      // filterTime={filterStartTime}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_endTime}
                                    </label>

                                    <DatePicker
                                      className="form-control dateInputSty h_50 inputleaguenight"
                                      selected={endTime}
                                      onChange={(date) => [
                                        EndTimeSelectManually(date),
                                      ]}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={10}
                                      dateFormat="h:mm aa"
                                      filterTime={filterTime}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_sel_TimeZone}
                                    </label>
                                    <select
                                      name=""
                                      id=""
                                      className="form-control h_50 inputleaguenight"
                                      onChange={(e) =>
                                        setSelectedTimeZone(e.target.value)
                                      }
                                    >
                                      <option value="" selected disabled>
                                        {text_timeZone}
                                      </option>
                                      {FinalData?.map((val, i) => {
                                        return (
                                          <>
                                            <option value={val?.utc}>
                                              {val?.label}{" "}
                                            </option>
                                          </>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                                {/* <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_eventvenue}/{text_location}
                                      <span className="starsty">*</span>
                                    </label>
                                    <select
                                      name=""
                                      id=""
                                      className="form-control h_50"
                                      onChange={(e) => [
                                        console.log(
                                          "selected venue id",
                                          e.target.value
                                        ),
                                        setVenueID(e.target.value),
                                        setEventVenue(e.target.value),
                                        setErrEventVenue(""),
                                        getselectedvenuedetails(e.target.value),
                                      ]}
                                    >
                                      <option value="" selected disabled>
                                        {text_ph_eveVenue}
                                      </option>

                                      {venueList.map((vl) => {
                                        return (
                                          <>
                                            <option value={vl?.venueid}>
                                              {vl?.venuename}
                                            </option>
                                          </>
                                        );
                                      })}
                                      <option value="newvenue">
                                        {text_addNew}
                                      </option>
                                    </select>

                                    {eventVenue === "newvenue" ? (
                                      <>
                                        <button
                                          className="main-btn mt-3"
                                          onClick={() =>
                                            navigate("/request-venue")
                                          }
                                        >
                                          <>{text_requestvenue}</>
                                        </button>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    <span className="starsty">
                                      {errEventVenue}
                                    </span>
                                  </div>
                                </div> */}
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_eveCreator_taxID}
                                    </label>
                                    <input
                                      className="form-control h_50 inputleaguenight"
                                      type="text"
                                      placeholder={text_ph_eveCreator_taxID}
                                      onChange={(e) =>
                                        setEventCreatorTaxID(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_eveCreatorName}
                                    </label>
                                    <input
                                      className="form-control h_50 inputleaguenight"
                                      type="text"
                                      placeholder={text_ph_eveCreatorName}
                                      onChange={(e) =>
                                        setEventCreatorName(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="create-event-stadium">
                                  <center>{/*stadium Img here  */}</center>
                                </div>
                              </div>
                              <div className="row ">
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form ">
                                    <label className="form-label fs-16">
                                      {text_title_memorabilia}
                                    </label>

                                    {selectedFiles.length === 0 ? (
                                      <>
                                        <button
                                          className="btn btn-default btn-hover steps_btn mt-2"
                                          onClick={() =>
                                            handleOpenMemorabiliaPopup(true)
                                          }
                                        >
                                          {text_upload}
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        <button
                                          className="btn btn-default btn-hover steps_btn mt-2"
                                          onClick={() =>
                                            handleOpenMemorabiliaPopup(true)
                                          }
                                        >
                                          {text_add}
                                        </button>
                                      </>
                                    )}
                                  </div>
                                  <div className="memorabilia-img-div">
                                    {selectedFiles.map((file, index) => (
                                      <div className="memorabilia-mul-imgs">
                                        <img
                                          key={index}
                                          src={file.filepath}
                                          alt=""
                                          className="memorabilia-img"
                                        />
                                        <div
                                          className="memorabilia-cross-icon"
                                          onClick={() =>
                                            handleRemoveImgFunc(index, file)
                                          }
                                        >
                                          <RxCross2 />
                                        </div>
                                        <div className="mt-2">
                                          <h5 className="memorabilia-filename ">
                                            {file.filekey}
                                          </h5>
                                          {/* )} */}

                                          <input
                                            type="text"
                                            className="form-control"
                                            // placeholder={text_ph_tags}
                                            // onChange={(e) => handleTagChange(e, index)}
                                            value={
                                              selectedFiles[index].filetag ===
                                              ""
                                                ? "No Tags"
                                                : selectedFiles[index].filetag
                                            }
                                            disabled
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_selectteam}{" "}
                                    </label>
                                    {window.location.pathname ===
                                    "/create-event/edit" ? (
                                      <>
                                        <input
                                          type="text"
                                          disabled
                                          value={homeTeamName}
                                          className="form-control h_50 inputleaguenight"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <select
                                          name=""
                                          id=""
                                          className="form-control h_50 inputleaguenight"
                                          onChange={(e) => [
                                            getSelectedLeague(e.target.value),
                                          ]}
                                        >
                                          <option value="" disabled selected>
                                            {text_selectteam}
                                          </option>
                                          {TeamList.map((g) => {
                                            return (
                                              <>
                                                <option value={g.teamid}>
                                                  {g.teamname}
                                                </option>
                                                {console.log(g.teamname)}
                                              </>
                                            );
                                          })}
                                        </select>
                                      </>
                                    )}{" "}
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_selteamtype}{" "}
                                    </label>
                                    {window.location.pathname ===
                                    "/create-event/edit" ? (
                                      <>
                                        <input
                                          type="text"
                                          disabled
                                          value={teamType}
                                          className="form-control h_50 inputleaguenight"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <select
                                          name=""
                                          id=""
                                          className="form-control h_50 inputleaguenight"
                                          onChange={(e) =>
                                            setTeamType(e.target.value)
                                          }
                                        >
                                          <option value="" disabled selected>
                                            {text_selteamtype}
                                          </option>
                                          <option value="home">Home</option>
                                          <option value="away">Away</option>
                                          <option value="other">Other</option>
                                        </select>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_selectleague}{" "}
                                    </label>
                                    <select
                                      name=""
                                      id=""
                                      className="form-control h_50 inputleaguenight"
                                    >
                                      <option value="" disabled selected>
                                        {text_selectleague}
                                      </option>
                                      {selectedTeamLeagueList.map((v) => {
                                        return (
                                          <>
                                            <option value={v.leagueid}>
                                              {v.leaguename}
                                            </option>
                                          </>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_eventvenue}/{text_location}
                                      <span className="starsty">*</span>
                                    </label>
                                    {window.location.pathname ===
                                    "/create-event/edit" ? (
                                      <>
                                        <input
                                          type="text"
                                          disabled
                                          value={eventVenue}
                                          className="form-control h_50 inputleaguenight"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <select
                                          name=""
                                          id=""
                                          className="form-control h_50 inputleaguenight"
                                          onChange={(e) => [
                                            console.log(
                                              "selected venue id",
                                              e.target.value
                                            ),
                                            setVenueID(e.target.value),
                                            setEventVenue(e.target.value),
                                            setErrEventVenue(""),
                                            getselectedvenuedetails(
                                              e.target.value
                                            ),
                                          ]}
                                        >
                                          <option value="" selected disabled>
                                            {text_ph_eveVenue}
                                          </option>

                                          {venueList.map((vl) => {
                                            return (
                                              <>
                                                <option value={vl?.venueid}>
                                                  {vl?.venuename}
                                                </option>
                                              </>
                                            );
                                          })}
                                          <option value="newvenue">
                                            {text_addNew}
                                          </option>
                                        </select>
                                      </>
                                    )}

                                    {eventVenue === "newvenue" ? (
                                      <>
                                        <button
                                          className="main-btn mt-3"
                                          onClick={() =>
                                            navigate("/request-venue")
                                          }
                                        >
                                          <>{text_requestvenue}</>
                                        </button>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    <span className="starsty">
                                      {errEventVenue}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-4 col-md-12  mt-3">
                                  {window.location.pathname ===
                                  "/create-event/edit" ? (
                                    <>
                                      <input
                                        type="checkbox"
                                        disabled
                                        checked={
                                          showAvailable === true ? true : false
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="checkbox"
                                        onChange={() => [
                                          setShowAvailable("true"),
                                          console.log(!showAvailable),
                                        ]}
                                      />
                                    </>
                                  )}
                                  &nbsp; &nbsp;
                                  <span>{text_showAvailableTicket}</span>
                                </div>
                                <div className="col-lg-4 col-md-12 mt-3">
                                  {window.location.pathname ===
                                  "/create-event/edit" ? (
                                    <>
                                      <input
                                        type="checkbox"
                                        disabled
                                        checked={
                                          ownername === true ? true : false
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="checkbox"
                                        onChange={() =>
                                          setOwnername(!ownername)
                                        }
                                      />
                                    </>
                                  )}
                                  &nbsp; &nbsp;
                                  <span>{text_ownername}</span>
                                  <br />
                                </div>
                                <div className="col-lg-4  col-md-12  mt-3">
                                  {window.location.pathname ===
                                  "/create-event/edit" ? (
                                    <>
                                      <input
                                        type="checkbox"
                                        disabled
                                        checked={
                                          transferData === null ? false : true
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="checkbox"
                                        onChange={() =>
                                          setTransferData(!transferData)
                                        }
                                      />
                                    </>
                                  )}
                                  &nbsp; &nbsp;
                                  <span>{text_allow_transfer}</span>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-lg-5  col-md-12  mt-3">
                                  {window.location.pathname ===
                                  "/create-event/edit" ? (
                                    <>
                                      <input
                                        type="checkbox"
                                        disabled
                                        checked={
                                          resellData === true ? true : false
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type="checkbox"
                                        onChange={() => [
                                          setResellData(!resellData),
                                          setResellOnTicketPrice(false),
                                          setResellOnProfit(false),
                                          setResellOnResellPrice(false),
                                          setResellOnResellFixedPrice(false),
                                        ]}
                                      />
                                    </>
                                  )}
                                  &nbsp; &nbsp;
                                  <span>{text_allow_resell}</span>
                                  {resellData === true ? (
                                    <>
                                      <div className="d-flex ">
                                        <input
                                          type="radio"
                                          // disabled={
                                          //   resellOnProfit ||
                                          //   resellOnResellFixedPrice ||
                                          //   resellOnResellPrice
                                          //     ? true
                                          //     : false
                                          // }
                                          checked={
                                            resellOnTicketPrice ? true : false
                                          }
                                          // defaultChecked={false}
                                          onChange={() => [
                                            setResellOnTicketPrice(
                                              !resellOnTicketPrice
                                            ),
                                            setResellOnProfit(false),
                                            setResellOnResellFixedPrice(false),
                                            setResellOnResellPrice(false),
                                          ]}
                                          disabled={
                                            window.location.pathname ===
                                            "/create-event/edit"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span className="mx-2">
                                          {text_percentage_ticketPrice}
                                        </span>

                                        <input
                                          type="radio"
                                          // disabled={
                                          //   resellOnTicketPrice ||
                                          //   resellOnResellFixedPrice ||
                                          //   resellOnResellPrice
                                          //     ? true
                                          //     : false
                                          // }
                                          checked={
                                            resellOnProfit ? true : false
                                          }
                                          // defaultChecked={false}
                                          onChange={() => [
                                            setResellOnProfit(!resellOnProfit),
                                            setResellOnResellFixedPrice(false),
                                            setResellOnResellPrice(false),
                                            setResellOnTicketPrice(false),
                                          ]}
                                          disabled={
                                            window.location.pathname ===
                                            "/create-event/edit"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span style={{ marginLeft: "0.5rem" }}>
                                          {text_percentage_profit}
                                        </span>
                                      </div>
                                      <div className="d-flex ">
                                        <input
                                          type="radio"
                                          // disabled={
                                          //   resellOnTicketPrice ||
                                          //   resellOnResellFixedPrice ||
                                          //   resellOnProfit
                                          //     ? true
                                          //     : false
                                          // }
                                          checked={
                                            resellOnResellPrice ? true : false
                                          }
                                          // defaultChecked={false}
                                          onChange={() => [
                                            setResellOnResellPrice(
                                              !resellOnResellPrice
                                            ),
                                            setResellOnProfit(false),
                                            setResellOnResellFixedPrice(false),

                                            setResellOnTicketPrice(false),
                                          ]}
                                          disabled={
                                            window.location.pathname ===
                                            "/create-event/edit"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span
                                          style={{
                                            marginRight: "1rem",
                                            marginLeft: "0.5rem",
                                          }}
                                        >
                                          {text_percentage_resell}
                                        </span>

                                        <input
                                          type="radio"
                                          // disabled={
                                          //   resellOnTicketPrice ||
                                          //   resellOnResellPrice ||
                                          //   resellOnProfit
                                          //     ? true
                                          //     : false
                                          // }
                                          checked={
                                            resellOnResellFixedPrice
                                              ? true
                                              : false
                                          }
                                          // defaultChecked={false}
                                          onChange={() => [
                                            setResellOnResellFixedPrice(
                                              !resellOnResellFixedPrice
                                            ),
                                            setResellOnProfit(false),

                                            setResellOnResellPrice(false),
                                            setResellOnTicketPrice(false),
                                          ]}
                                          disabled={
                                            window.location.pathname ===
                                            "/create-event/edit"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span style={{ marginLeft: "0.5rem" }}>
                                          {text_fixedPrice}
                                        </span>
                                      </div>
                                      {resellOnTicketPrice === true ? (
                                        <>
                                          <div className="mt-2">
                                            <h5>
                                              {text_percentage_ticketPrice}
                                            </h5>
                                            <input
                                              type="text"
                                              className="form-control h_50 inputleaguenight"
                                              placeholder={
                                                text_ph_percentage_ticketPrice
                                              }
                                              onChange={(e) =>
                                                setResellTicketPercentage(
                                                  e.target.value
                                                )
                                              }
                                              value={resellTicketPercentage}
                                              disabled={
                                                window.location.pathname ===
                                                "/create-event/edit"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {resellOnProfit === true ? (
                                        <>
                                          <div className="mt-2">
                                            <h5>{text_percentage_profit}</h5>
                                            <input
                                              type="text"
                                              className="form-control h_50 inputleaguenight"
                                              placeholder={
                                                text_ph_percentage_profit
                                              }
                                              onChange={(e) =>
                                                setResellProfitPercentage(
                                                  e.target.value
                                                )
                                              }
                                              value={resellProfitPercentage}
                                              disabled={
                                                window.location.pathname ===
                                                "/create-event/edit"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {resellOnResellPrice === true ? (
                                        <>
                                          <div className="mt-2">
                                            <h5>
                                              {text_percentage_resellPrice}
                                            </h5>
                                            <input
                                              type="text"
                                              className="form-control h_50 inputleaguenight"
                                              placeholder={
                                                text_ph_percentage_resellPrice
                                              }
                                              onChange={(e) =>
                                                setResellPriceProfit(
                                                  e.target.value
                                                )
                                              }
                                              value={resellPriceProfit}
                                              disabled={
                                                window.location.pathname ===
                                                "/create-event/edit"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {resellOnResellFixedPrice === true ? (
                                        <>
                                          <div className="mt-2">
                                            <h5>{text_resellFixed}</h5>
                                            <input
                                              type="text"
                                              className="form-control h_50 inputleaguenight"
                                              placeholder={text_ph_resellFixed}
                                              onChange={(e) =>
                                                setResellFixedProfit(
                                                  e.target.value
                                                )
                                              }
                                              value={resellFixedProfit}
                                              disabled={
                                                window.location.pathname ===
                                                "/create-event/edit"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                              <div className="row ce-border mt-3">
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form ">
                                    <label className="form-label fs-16">
                                      <b> {text_ticketType}</b>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row  mt-3">
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_ticketType}
                                      <span className="starsty">*</span>
                                    </label>
                                    <select
                                      className="form-control h_50 inputleaguenight"
                                      name="currency"
                                      value={currency}
                                      onChange={(e) => [
                                        setCurrency(e.target.value),
                                        setNonCrypto(""),
                                        setErrEventTicketType(""),
                                      ]}
                                      disabled={
                                        window.location.pathname ===
                                        "/create-event/edit"
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value="" selected disabled>
                                        {text_ph_ticketType}
                                      </option>
                                      <option value="crypto">{text_nft}</option>
                                      <option value="non_crypto">
                                        {text_regular}
                                      </option>
                                    </select>
                                    <span className="starsty">
                                      {errEventTicketType}
                                    </span>
                                  </div>
                                </div>

                                {currency === "crypto" ? (
                                  <>
                                    <div className="col-lg-4 col-md-12">
                                      <div className="form-group border_bottom-create profile-night-form">
                                        <label className="form-label fs-16">
                                          {text_typeNftToken}
                                        </label>
                                      </div>
                                      <select
                                        className="form-control h_50 inputleaguenight"
                                        value={typeOfToken}
                                        onChange={(e) =>
                                          setTypeOfToken(e.target.value)
                                        }
                                        disabled={
                                          window.location.pathname ===
                                          "/create-event/edit"
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="ERC-721" selected>
                                          ERC-721
                                        </option>
                                        <option value="ERC-1155">
                                          ERC-1155
                                        </option>
                                        <option value="ERC-6551">
                                          ERC-6551
                                        </option>
                                      </select>
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                      <div className="form-group border_bottom-create profile-night-form">
                                        <label className="form-label fs-16">
                                          {text_nftNetwork}{" "}
                                          <span className="Star">*</span>
                                        </label>
                                      </div>
                                      <select
                                        className="form-control h_50 inputleaguenight"
                                        value={Cryptocurrency}
                                        onChange={(e) => [
                                          setCryptocurrency(e.target.value),
                                          setNonCrypto(e.target.value),
                                          setSelectedCryptoCurrency(
                                            e.target.value
                                          ),
                                          setErrTypeNftNetwork(""),
                                        ]}
                                        disabled={
                                          window.location.pathname ===
                                          "/create-event/edit"
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="" selected disabled>
                                          {text_ph_nftNetwork}
                                        </option>
                                        <option value="matic">Polygon</option>
                                        <option value="eth">Ethereum</option>
                                      </select>
                                      <span className="starsty">
                                        {errTypeNftNetwork}
                                      </span>
                                    </div>

                                    <div className="col-lg-4 col-md-12">
                                      <div className="form-group border_bottom-create profile-night-form">
                                        <label className="form-label fs-16">
                                          {text_network_currency}
                                        </label>
                                        <input
                                          type="text"
                                          className="mt-1 form-control h_50 mb-2 inputleaguenight"
                                          value={selectedCryptoCurrency}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {currency === "non_crypto" ? (
                                  <>
                                    <div className="col-lg-4 col-md-12">
                                      <div className="form-group border_bottom-create profile-night-form pb_30">
                                        <label className="form-label fs-16">
                                          {text_currency}
                                          <span className="starsty">*</span>
                                        </label>

                                        <select
                                          className="mt-1 form-control h_50 inputleaguenight"
                                          value={nonCrypto}
                                          onChange={(e) => [
                                            setNonCrypto(e.target.value),
                                            setErrTypeRegularCurrency(""),
                                          ]}
                                          disabled={
                                            window.location.pathname ===
                                            "/create-event/edit"
                                              ? true
                                              : false
                                          }
                                        >
                                          <option value="" selected disabled>
                                            {text_ph_currency}
                                          </option>
                                          {Currency.map((v) => {
                                            return (
                                              <>
                                                <option value={v?.abbreviation}>
                                                  {v?.currency} ({v?.symbol})
                                                </option>
                                              </>
                                            );
                                          })}
                                        </select>
                                        <span className="starsty">
                                          {errTypeRegularCurrency}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>

                              <div className="row ce-border">
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form ">
                                    <label className="form-label fs-16">
                                      <b> {text_ticket_sellcurrency}</b>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="row  mt-3">
                                <div className="col-lg-4 col-md-12">
                                  <div className="form-group border_bottom-create profile-night-form pb_30">
                                    <label className="form-label fs-16">
                                      {text_ticket_sellcurrency}
                                      <span className="starsty">*</span>
                                    </label>
                                    <select
                                      className="mt-1 form-control h_50 inputleaguenight"
                                      name="currency"
                                      value={ticketCurrency}
                                      defaultValue={currency}
                                      onChange={(e) => [
                                        setTicketCurrency(e.target.value),
                                        setSlectedTicketCurrency(""),
                                        setErrSellCurrencyType(""),
                                      ]}
                                      disabled={
                                        window.location.pathname ===
                                        "/create-event/edit"
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value="" selected disabled>
                                        {text_ph_ticketCyrrency}
                                      </option>
                                      <option value="fiat">{text_Fiat} </option>
                                      <option value="crypto">
                                        {text_crypto}{" "}
                                      </option>
                                      <option value="stable">
                                        {text_stableCoin}
                                      </option>
                                    </select>
                                    <span className="starsty">
                                      {errSellCurrencyType}
                                    </span>
                                  </div>
                                </div>

                                {ticketCurrency === "fiat" ? (
                                  <>
                                    <div className="col-lg-4 col-md-12">
                                      <div className="form-group border_bottom-create profile-night-form pb_30">
                                        <label className="form-label fs-16">
                                          {text_currency}
                                          <span className="starsty">*</span>
                                        </label>
                                        <select
                                          className="mt-1 form-control h_50 inputleaguenight"
                                          name="currency"
                                          onChange={(e) => [
                                            setSlectedTicketCurrency(
                                              e.target.value
                                            ),
                                            setErrSellCurrency(""),
                                          ]}
                                          value={slectedTicketCurrency}
                                          disabled={
                                            window.location.pathname ===
                                            "/create-event/edit"
                                              ? true
                                              : false
                                          }
                                        >
                                          <option value="" selected disabled>
                                            {text_ph_currency}
                                          </option>
                                          {Currency.map((v) => {
                                            return (
                                              <>
                                                <option value={v?.symbol}>
                                                  {v?.currency} ({v?.symbol})
                                                </option>
                                              </>
                                            );
                                          })}
                                        </select>
                                        <span className="starsty">
                                          {errSellCurrency}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {ticketCurrency === "crypto" ? (
                                  <>
                                    <div className="col-lg-4 col-md-12">
                                      <div className="form-group border_bottom-create profile-night-form pb_30">
                                        <label className="form-label fs-16">
                                          {text_crypto}
                                          <span className="starsty">*</span>
                                        </label>
                                        <select
                                          className="mt-1 form-control h_50 inputleaguenight"
                                          name="currency"
                                          onChange={(e) => [
                                            setSlectedTicketCurrency(
                                              e.target.value
                                            ),
                                            setErrSellCurrency(""),
                                          ]}
                                          value={slectedTicketCurrency}
                                          disabled={
                                            window.location.pathname ===
                                            "/create-event/edit"
                                              ? true
                                              : false
                                          }
                                        >
                                          <option value="" selected disabled>
                                            {text_ph_crypto}
                                          </option>
                                          <option value="eth">ETH</option>
                                          <option value="btc">Bitcoin</option>
                                          <option value="matic">Matic</option>
                                        </select>
                                        <span className="starsty">
                                          {errSellCurrency}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {ticketCurrency === "stable" ? (
                                  <>
                                    <div className="col-lg-4 col-md-12">
                                      <div className="form-group border_bottom-create profile-night-form">
                                        <label className="form-label fs-16">
                                          {text_stableCoin}
                                          <span className="starsty">*</span>
                                        </label>
                                      </div>
                                      <select
                                        className="mt-1 form-control h_50 inputleaguenight"
                                        name="currency"
                                        onChange={(e) => [
                                          setSlectedTicketCurrency(
                                            e.target.value
                                          ),
                                          setErrSellCurrency(""),
                                        ]}
                                        value={slectedTicketCurrency}
                                        disabled={
                                          window.location.pathname ===
                                          "/create-event/edit"
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="" selected disabled>
                                          {text_ph_stableCoin}
                                        </option>
                                        <option value="usdt">USDT </option>
                                      </select>
                                      <span className="starsty">
                                        {errSellCurrency}
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap gap-2 justify-content-center mt-4">
                      {/* className="step-footer step-tab-pager mt-4 " */}
                      <button
                        data-direction="prev"
                        className="btn  cancelBtn me-2 "
                        onClick={() => navigate(-1)}
                      >
                        {text_cancel}
                      </button>
                      {window.location.pathname === "/create-event/edit" ? (
                        <>
                          <button
                            data-direction="finish"
                            className="btn btn-default btn-hover steps_btn Create-Conf-Btn"
                            onClick={UpdateEventHandle}
                          >
                            {text_update}{" "}
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            data-direction="finish"
                            className="btn btn-default btn-hover steps_btn Create-Conf-Btn"
                            onClick={EventCreateHandle}
                          >
                            {text_configureseats}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Event Image Poupup */}
      <Modal
        open={openEveImageUploadPopup}
        onClose={handleCloseEveImagePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue"
                onClick={() => [handleCloseEveImagePopup()]}
              />
            </div>
            <h3 className="popup-main-title">{text_upload_image}</h3>
            <Divider />
            {Loading === true ? (
              <>
                <center className=" mt-3 mb-3">
                  <Loader />
                </center>
              </>
            ) : (
              <>
                <div className="container">
                  <div className="text-center form-group mt-3">
                    <input
                      type="file"
                      className="form-control h_50 uploadinput landinginputnight"
                      // onChange={(e) => [
                      //   setEventImage(e.target.files),
                      //   console.log(e.target.files),
                      // ]}
                      onChange={EventimguplpoadValidation}
                    />
                  </div>
                  {/* <input type="file" onClick={(e)=>setEventImage(e.target.files)} /> */}
                </div>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <button
                    onClick={() => handleCloseEveImagePopup()}
                    className="cancelBtn"
                  >
                    {" "}
                    {text_cancel}
                  </button>

                  <button onClick={handleEveImgUpload} className="resell-Btn">
                    {" "}
                    {text_save}
                  </button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
      {/* Memorabilia Popup */}
      <Modal
        open={openMemorabiliaImageUploadPopup}
        onClose={() => [handleCloseMemorabiliaPopup()]}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue"
                onClick={() => [handleCloseMemorabiliaPopup()]}
              />
            </div>
            <h3 className="popup-main-title">{text_upload_image}</h3>
            <Divider />
            {memorabiliaLoading === true ? (
              <>
                <center className=" mt-3 mb-3">
                  <Loader />
                </center>
              </>
            ) : (
              <>
                <div className="container">
                  <div className="text-center">
                    <input
                      type="file"
                      className="form-control h_50 uploadinput mb-2 mt-3 landinginputnight"
                      // onChange={(e) => [
                      //   setMemorabiliaImage(e.target.files),
                      //   console.log(e.target.files),
                      // ]}
                      onChange={MemorabiliaimguplpoadValidation}
                    />
                    <input
                      type="text"
                      className="form-control h_50 landinginputnight"
                      placeholder={text_ph_tags}
                      // onChange={(e) => handleTagChange(e, index)}
                      // value={selectedFiles[index].tagValue}
                      onChange={(e) => setTagValueMemorabilia(e.target.value)}
                    />
                  </div>
                  {/* <input type="file" onClick={(e)=>setEventImage(e.target.files)} /> */}
                </div>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <button
                    onClick={() => handleCloseMemorabiliaPopup()}
                    className="cancelBtn"
                  >
                    {" "}
                    {text_cancel}
                  </button>

                  <button
                    onClick={handleMemorabiliaImgUpload}
                    className="resell-Btn"
                  >
                    {" "}
                    {text_save}
                  </button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default CreateEvent;
