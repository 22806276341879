import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import "./BuyTicket.css";
import axios from "axios";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import { ToastContainer, toast } from "react-toastify";
import confirmImg from "../../assets/img/confirmed.png";
import { FaTicketAlt } from "react-icons/fa";
import EveImg from "../../assets/img/event-imgs/img-7.jpg";
import moment from "moment";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function PaymentStatus() {
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  var totalticket = JSON.parse(sessionStorage.getItem("SeatsDetails"));
  const [paymentID, setPaymentID] = useState("");
  const navigate = useNavigate();
  const [evedetails, setEvedetails] = useState([]);
  const [text_home, setText_home] = useState("Home");
  const [text_bookingconfirmed, setText_bookingconfirmed] =
    useState("Booking Confirmed");
  const [text_PaymentProccessing, setText_PaymentProccessing] = useState(
    "Payment Proccessing"
  );
  const [text_viewtickets, setText_viewtickets] = useState("View Tickets");
  const [text_total, setText_total] = useState("Total");
  const [text_ticketconfirmed, setText_ticketconfirmed] =
    useState("Ticket Confirmed");
  const [text_ticketconfirmed_desc, setText_ticketconfirmed_desc] = useState(
    "We are pleased to inform you that your reservation request has been received and confirmed"
  );
  const [text_tickets, setText_tickets] = useState("Tickets");
  const data = useLocation();
  const pid = data.state !== null ? data.state.paymentid : false;
  console.log("object", pid);
  // useEffect(() => {
  //   buyTicketHandle(pid);
  // }, [pid]);
  const buyTicketHandle = (payid) => {
    var API_URL = API_DOMAIN + "seat/buy";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      paymentid: payid,
      ownerdetails: JSON.parse(sessionStorage.getItem("ownerDetails")),
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("check out", response);
        if (response.data.message === "success") {
          setPaymentSuccess(true);
          sessionStorage.setItem("Paymentid", payid);
        }
      })
      .catch(function (error) {
        // errorToast(err);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    const paymentid = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );
    setPaymentID(paymentid);
    buyTicketHandle(paymentid);
  }, [1]);
  var ticketDetails = JSON.parse(sessionStorage.getItem("SeatsDetails"));

  const eventID = ticketDetails[0].seatD?.event_unique_id;

  useEffect((data) => {
    var type = "select";
    var event_unique_id = eventID;
    var API_URL =
      API_DOMAIN +
      "event/public?type=" +
      type +
      "&event_unique_id=" +
      event_unique_id;

    axios
      .get(API_URL)
      .then(function (response) {
        console.log("Detaied Event", response.data.data);
        var res = response.data.data;
        setEvedetails(res);
        sessionStorage.setItem("EveDetail", JSON.stringify(res));
      })
      .catch(function (error) {
        // errorToast(err);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  }, []);
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_bookingconfirmed(
        xpath.select1("/resources/string[@name='text_bookingconfirmed']", doc)
          .firstChild.data
      );
      setText_PaymentProccessing(
        xpath.select1("/resources/string[@name='text_PaymentProccessing']", doc)
          .firstChild.data
      );
      setText_viewtickets(
        xpath.select1("/resources/string[@name='text_viewtickets']", doc)
          .firstChild.data
      );
      setText_total(
        xpath.select1("/resources/string[@name='text_total']", doc).firstChild
          .data
      );
      setText_ticketconfirmed(
        xpath.select1("/resources/string[@name='text_ticketconfirmed']", doc)
          .firstChild.data
      );
      setText_tickets(
        xpath.select1("/resources/string[@name='text_tickets']", doc).firstChild
          .data
      );
      setText_ticketconfirmed_desc(
        xpath.select1(
          "/resources/string[@name='text_ticketconfirmed_desc']",
          doc
        ).firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />

      <div className="wrapper eventdetail-wrapper" id="otherPages">
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_ticketconfirmed}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {paymentSuccess === false ? (
          <>
            <div className="event-dt-block p-80">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-7 col-md-10">
                    <div className="booking-confirmed-content">
                      <div className="main-card">
                        <div className="booking-confirmed-top text-center p_30">
                          <h4>{text_PaymentProccessing}</h4>
                          <br />
                          <div className="booking-confirmed-img mt-4">
                            <div className="loader mt-4">
                              <div className="circleLoading"></div>
                              <div className="circleLoading"></div>
                              <div className="circleLoading"></div>
                              <div className="circleLoading"></div>
                            </div>
                          </div>
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="event-dt-block p-80">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-7 col-md-10">
                    <div className="booking-confirmed-content">
                      <div className="main-card">
                        <div className="booking-confirmed-top text-center p_30">
                          <div className="booking-confirmed-img mt-4">
                            <img src={confirmImg} alt="" />
                          </div>
                          <h4>{text_ticketconfirmed}</h4>
                          <p className="ps-lg-4 pe-lg-4">
                            {text_ticketconfirmed_desc}.
                          </p>
                          {/* <div className="add-calender-booking">
                            <h5>Add</h5>
                            <Link href="#" className="cb-icon">
                              <i className="fa-brands fa-windows"></i>
                            </Link>
                            <Link href="#" className="cb-icon">
                              <i className="fa-brands fa-apple"></i>
                            </Link>
                            <Link href="#" className="cb-icon">
                              <i className="fa-brands fa-google"></i>
                            </Link>
                            <Link href="#" className="cb-icon">
                              <i className="fa-brands fa-yahoo"></i>
                            </Link>
                          </div> */}
                        </div>
                        {evedetails.map((ed) => {
                          console.log(ed);
                          return (
                            <>
                              <div className="booking-confirmed-bottom">
                                <div className="booking-confirmed-bottom-bg p_30">
                                  <div className="event-order-dt">
                                    <div className="event-thumbnail-img">
                                      <img
                                        src={
                                          ed.EventImagePath === "path"
                                            ? EveImg
                                            : ed.EventImagePath
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="event-order-dt-content">
                                      <h5>{ed.EventTitle}</h5>
                                      <span>
                                        {moment.utc(ed?.StartDate).format(
                                          "DD MMM,yyyy"
                                        ) ===
                                        moment.utc(ed?.EndDate).format(
                                          "DD MMM,yyyy"
                                        ) ? (
                                          <>
                                            {moment.utc(ed?.StartDate).format(
                                              "ddd, MMM D, yyyy"
                                            )}{" "}
                                            {moment
                                              .utc(ed?.StartTime)
                                              .format("hh:mm A")}
                                          </>
                                        ) : (
                                          <>
                                            {moment.utc(ed?.StartDate).format(
                                              "ddd, MMM D, yyyy"
                                            )}{" "}
                                            {moment
                                              .utc(ed?.StartTime)
                                              .format("hh:mm A")}
                                            -{" "}
                                            {moment.utc(ed?.EndDate).format(
                                              "ddd, MMM D, yyyy"
                                            )}{" "}
                                            {moment
                                              .utc(ed?.EndTime)
                                              .format("hh:mm A")}
                                          </>
                                        )}
                                      </span>
                                      {/* <div className="buyer-name">John Doe</div> */}
                                      <div className="buyer-name">
                                        {sessionStorage.getItem(
                                          "userfirstname"
                                        )}{" "}
                                        {sessionStorage.getItem("userlastname")}
                                      </div>
                                      <div className="booking-total-grand">
                                        {text_tickets} :{" "}
                                        <span className="booking-count-tickets mx-2">
                                          {totalticket.length}
                                        </span>
                                      </div>
                                      <div className="booking-total-grand">
                                        {text_total} :{" "}
                                        <span>
                                          {totalticket[0]?.seatD?.currency}{" "}
                                          {/* {console.log(totalticket[0]?.seatD?.currency)} */}
                                          {sessionStorage.getItem(
                                            "seatTotalPrice"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <Link
                                    to="/buy-invoice"
                                    className="main-btn btn-hover h_50 w-100 mt-5"
                                  >
                                    <FaTicketAlt className="rotate-icon me-3" />
                                    {text_viewtickets}
                                  </Link>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default PaymentStatus;
