import React, { useState, useEffect } from "react";
import "../ForgotPassword/ForgotPassword.css";
import mainlogo from "../../assets/img/tiq8-Logo-white.png";
import Logo from "../../assets/img/logo.svg";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import { ToastContainer, toast } from "react-toastify";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";
import moment from "moment";

const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function ForgotPassword() {
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotemailerr, setforgotemailerr] = useState("");
  const [forgoterr, setForgoterr] = useState("");
  const [forgotsuccess, setForgotsuccess] = useState("");
  const [openforgot, setOpenforgot] = useState(false);
  const [forgotBtn, setForgotBtn] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [text_forgot, setText_forgot] = useState("Forgot Password");

  const [text_ph_email, setText_ph_email] = useState("Enter Email");

  const [text_email_heading, setText_email_heading] = useState(
    "Enter your Registered Email"
  );
  const [text_resetpass, setText_resetpass] = useState("Reset Password");
  const [text_backtosignin, setText_backtosignin] = useState("Back to sign in");
  const [text_err_emailorphone, setText_err_emailorphone] = useState(
    "*Please Enter Email or Phone Number!"
  );
  const [text_okay, setText_okay] = useState("Okay");
  const [text_rightsReserved, setText_rightsReserved] = useState(
    "All rights reserved"
  );
  const [login_title, setLogin_title] = useState(
    "The Easiest Way to Create Events and Sell More Tickets Online"
  );
  const handleCloseforgot = () => {
    setOpenforgot(false);
    navigate("/signin");
  };
  const sendforgotemailhandler = () => {
    var isValidfor = true;
    if (forgotEmail === "") {
      isValidfor = false;
      setforgotemailerr(<>{text_err_emailorphone}</>);
    }
    setForgotBtn(false);
    return isValidfor;
  };

  const sendForgotEmailHandle = () => {
    if (sendforgotemailhandler()) {
      setForgotBtn(true);
      var API_URL = API_DOMAIN + "user/sendforgotpasswordlink";
      let headerConfig = {
        headers: {
          accept: "application/json",
        },
      };
      var data = {
        username: forgotEmail,
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("response***", response);
          if (response.data.status === true) {
            setForgotBtn(false);
            setOpenforgot(true);
            setForgotsuccess(response?.data?.message);
            // toast.success(response?.data?.message);
            setforgotemailerr("");
          }
        })
        .catch(function (error) {
          console.log("catch block err***", error.response.data);
          setForgoterr(error.response.data.message);
          setForgotBtn(false);
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            setForgoterr(error.response.data.message);
          }
        })
        .then(function () {});
    }
  };

  const keyHandler = (e) => {
    if (e.key === "Enter") {
      if (forgotBtn) {
        return;
      }
      sendForgotEmailHandle();
    }
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_forgot(
        xpath.select1("/resources/string[@name='text_forgot']", doc).firstChild
          .data
      );
      setText_ph_email(
        xpath.select1("/resources/string[@name='text_ph_email']", doc)
          .firstChild.data
      );
      setText_email_heading(
        xpath.select1("/resources/string[@name='text_email_heading']", doc)
          .firstChild.data
      );
      setText_resetpass(
        xpath.select1("/resources/string[@name='text_resetpass']", doc)
          .firstChild.data
      );
      setText_backtosignin(
        xpath.select1("/resources/string[@name='text_backtosignin']", doc)
          .firstChild.data
      );
      setText_err_emailorphone(
        xpath.select1("/resources/string[@name='text_err_emailorphone']", doc)
          .firstChild.data
      );
      setText_okay(
        xpath.select1("/resources/string[@name='text_okay']", doc).firstChild
          .data
      );
      setText_rightsReserved(
        xpath.select1("/resources/string[@name='text_rightsReserved']", doc)
          .firstChild.data
      );
      setLogin_title(
        xpath.select1("/resources/string[@name='login_title']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <div id="otherPages">
        <div className="app-form" onKeyPress={keyHandler}>
          <div className="app-form-sidebar">
            <Link to="/">
              <div className="sidebar-sign-logo">
                <img src={mainlogo} alt="" className="side-logo-outerPage" />
              </div>
            </Link>
            <div className="sign_sidebar_text">
              <h1>{login_title}</h1>
            </div>
          </div>
          <div className="app-form-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <div className="app-top-items">
                    <Link to="/">
                      <div className="sign-logo" id="logo">
                        <img src={mainlogo} alt="" />
                        <img className="logo-inverse" src={mainlogo} alt="" />
                      </div>
                    </Link>
                    <div className="app-top-right-link">
                      <Link className="sidebar-register-link" to="/signin">
                        <IoArrowBackCircleOutline className="fp_backsign" />{" "}
                        {text_backtosignin}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-7">
                  <div>
                    <h2 className="registration-title">{text_forgot}</h2>
                    <div className="form-group mt-5">
                      <label className="form-label">
                        {text_email_heading}
                        <span className="starsty">*</span>
                      </label>
                      <input
                        className="form-control h_50"
                        type="email"
                        placeholder={text_ph_email}
                        onChange={(e) => [
                          setForgotEmail(e.target.value),
                          setforgotemailerr(""),
                          setForgoterr(""),
                        ]}
                      />
                    </div>
                    <span className="starsty">{forgotemailerr}</span>
                    <p className="regErr-p">{forgoterr}</p>
                    <button
                      className="main-btn btn-hover w-100 mt-4"
                      onClick={sendForgotEmailHandle}
                      disabled={forgotBtn ? true : false}
                    >
                      {text_resetpass}
                    </button>
                    <div className="new-sign-link">
                      <Link className="signup-link" to="/signin">
                        {text_backtosignin}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright-footer">
              © {moment(new Date()).format("YYYY")}, TiQ8. {text_rightsReserved}
              .
            </div>
          </div>
        </div>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={openforgot}
        onClose={handleCloseforgot}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <FcFeedback className="Email-icon" style={{ scale: "2.3" }} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3 className="forgotsuccess  mb-4">{forgotsuccess}</h3>
          </DialogContentText>
          <Divider />
        </DialogContent>
        <DialogActions>
          <button
            className="main-btn verify-ok-btn  mx-4"
            onClick={handleCloseforgot}
          >
            {text_okay}
          </button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
}

export default ForgotPassword;
