import React from "react";
import "../OrganiserProfile/OrganiserProfile.css";

function OrganiserProfile() {
  return (
    <>
    <div id="otherPages">
      <h1>OrganiserProfile</h1>
      </div>
    </>
  );
}

export default OrganiserProfile;
