import React, { useEffect, useState } from "react";
import "../SeasonalPass/SeasonalPass.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import { Box, Divider, Modal } from "@mui/material";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Loader from "../../utils/Loader";
import { Currency } from "../../utils/data";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function SeasonalPass() {
  const _ = require("lodash");

  const navigate = useNavigate();
  const [passCurrency, setPassCurrency] = useState("");
  const TeamList = JSON.parse(sessionStorage.getItem("teamList"));
  console.log(TeamList);
  // dummy
  const [dummyLname, setDummyLname] = useState("");

  const [showSeasonalTeam, setShowSeasonalTeam] = useState(false);
  const [showSeasonalEvents, setShowSeasonalEvents] = useState(false);
  const [showEventsCategory, setShowEventsCategory] = useState(false);
  const [showSeatsOfCategory, setShowSeatsOfCategory] = useState(false);
  const [openImageUploadPopup, setOpenImageUploadPopup] = useState(false);
  const [isParkingPass, setIsParkingPass] = useState(false);
  const [parkingPass, setParkingPass] = useState("");
  const [isFoodandDrink, setIsFoodandDrink] = useState(false);
  const handleOpenImagePopup = () => setOpenImageUploadPopup(true);
  const handleCloseImagePopup = () => setOpenImageUploadPopup(false);
  const [Loading, setLoading] = useState(false);
  const [seasonImage, setSeasonImage] = useState("");
  const [seasonImageUrl, setSeasonImageUrl] = useState("");
  const styleTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };
  const [text_home, setText_home] = useState("Home");
  const [text_selectleague, setText_selectleague] = useState("Select League");
  const [text_createpass, setText_createpass] = useState("Create Pass");
  const [text_selectseat, setText_selectseat] = useState("Select Seat");
  const [text_selectcategory, setText_selectcategory] =
    useState("Select Category");
  const [text_selectevent, setText_selectevent] = useState("Select Event");
  const [text_seatprice, setText_seatprice] = useState("Seat Price");
  const [text_enterseatprice, setText_enterseatprice] =
    useState("Enter Seat Price");
  const [text_createseasonalpass, setText_seasonalpass] = useState(
    "Create Seasonal Pass"
  );
  const [text_seasonalpass1, setText_seasonalpass1] = useState("Seasonal Pass");
  const [text_enterleaguename, setText_enterleaguename] =
    useState("Enter League Name");
  const [text_upload, setText_upload] = useState("Upload");
  const [text_upload_image, setText_upload_image] = useState("Upload Image");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_save, setText_save] = useState("Save");
  const [text_priceseasonpass, setText_priceseasonpass] = useState(
    "Price of Each Season Pass"
  );
  const [text_currency, setText_currency] = useState("Currency");
  const [text_selcurrency, setText_selcurrency] = useState("Select Currency");
  const [text_enterpassname, setText_enterpassbane] = useState(
    "Enter Season Pass Name"
  );
  const [text_seasonpassname, setText_seasonpassname] =
    useState("Season Pass Name");
  const [text_seasonpassimage, setText_seasonpassimage] =
    useState("Season Pass Image");
  const [text_createseasonpass, setText_createseasonpass] =
    useState("Create Season Pass");
  const [text_orgdashboard, setText_orgdashboard] = useState(
    "Organization Dashboard"
  );
  const [text_teamname, setText_teamname] = useState("Team Name");
  const [text_parkingdrop, setText_parkingdrop] = useState(
    "Select Parking Pass Level"
  );
  const [text_vip, setText_vip] = useState("VIP");
  const [text_gold, setText_gold] = useState("Gold");
  const [text_silver, setText_silver] = useState("Silver");
  const [text_parkingpass, setText_parkingpass] = useState("Parking Pass");
  const [text_foodanddrinks, setText_foodanddrinks] =
    useState("Food and Drinks");
  // get league details
  const [leagueDetailsList, setLeagueDetailsList] = useState([]);
  const [leagueTeamList, setLeagueTeamList] = useState([]);

  const getTeamData = (team_id) => {
    const teamfilterData = leagueDetailsList.filter(
      (item) => item.leagueid === team_id
    );
    console.log("Team list", teamfilterData);
    setLeagueTeamList(teamfilterData[0].leagueteams);
  };

  const getLeagueDetails = () => {
    var API_URL = API_DOMAIN + "league/getall";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("league details", response.data.data);
        setLeagueDetailsList(response.data.data);
        setDummyLname(response.data.data[0].leaguename);
        setSelectedLeagueId(response.data.data[0].leagueid);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getLeagueDetails();
  }, []);
  // get selected league
  const [selectedLeagueId, setSelectedLeagueId] = useState("");
  const [selectedLeagueEveDetails, setSelectedLeagueEveDetails] = useState([]);

  const getselectedLeagueData = (id) => {
    var API_URL = API_DOMAIN + `event/get/byleagueid?leagueid=${id}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("selected league", response.data.data[0]);
        setSelectedLeagueEveDetails(response.data.data[0]);
        var res = response.data.data[0];
        setTimeout(() => {
          getselectedEveCategory(
            res[0]?.event_unique_id,
            response.data.data[0]
          );
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getselectedLeagueData(leagueDetailsList[0]?.leagueid);
  }, [leagueDetailsList]);

  // get event category of selected event
  const [selectedEveId, setSelectedEveId] = useState("");
  const [selectedEveCategoryDetails, setSelectedEveCategoryDetails] = useState(
    []
  );
  const [selectedEventVenueId, setSelectedEventVenueId] = useState("");
  const getselectedEveCategory = (eid, data) => {
    const filteredData = data.filter((item) => item.event_unique_id === eid);
    console.log("selected event", filteredData);
    // setSelectedEventVenueId(filteredData[0].venueid);
    // setSelectedEveCategoryDetails(filteredData[0].TicketDetailsList);
    // setSelectedEveId(eid);
    handlegetfilterData(filteredData[0].venueid, eid, data);
  };
  const [eventListsVenue, setEventListsVenue] = useState([]);
  const [showEventList, setShowEventList] = useState(false);

  const handlegetfilterData = (vid, eid, data) => {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].venueid === vid) {
        arr.push(data[i]);
      }
    }
    console.log("000", arr);
    // setEventListsVenue(arr);
  };
  const getSeatDetails = (secid) => {
    var API_URL =
      API_DOMAIN +
      `seat/select/multipleseat?seat_category_id=${secid}&event_unique_id=${selectedEveId}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("seatdetails List", response);
        let data = response.data.data;
        var dataMain = data;
        //   setseatList(dataMain);
        const newArr = dataMain.map((v) => ({ ...v, ischecked: false }));
        ArrayTOObject(newArr);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const [seatDetails, setseatDetails] = useState({});
  const [seatPrice, setSeatPrice] = useState("");
  const [seasonName, setSeasonName] = useState("");
  const ArrayTOObject = (arr) => {
    var seatss = arr,
      result = seatss.reduce(function (r, a) {
        r[a.rowstart] = r[a.rowstart] || [];
        r[a.rowstart].push(a);
        return r;
      }, Object.create(null));
    //sorting below
    var orderedData = Object.keys(result)
      .sort()
      .reduce((obj, key) => {
        obj[key] = result[key];
        return obj;
      }, {});
    //objs.sort((a,b) => a.column - b.column);
    for (const key in orderedData) {
      orderedData[key].sort((a, b) => a.column - b.column);
      //console.log(`${key}: ${orderedData[key]}`);
    }

    setseatDetails(orderedData);
  };
  let checkRowAll = (arr) => arr.every((v) => v.ischecked === true);
  const handleSelectFullRow = (seat, e) => {
    var temp = _.clone(seatDetails);
    var tempp = temp[seat.rowstart];
    //var temp = seatDetails[seat.rowstart];
    for (var i in tempp) {
      tempp[i].ischecked = e.target.checked;
    }
    temp[seat.rowstart] = tempp;
    setseatDetails(temp);
    var arr = seatDetails[seat.rowstart];

    for (let i = 0; i < arr.length; i++) {
      const isSelected = selectedIds.includes(arr[i].seat_unique_id);
      setSelectedIds((prevIds) =>
        isSelected
          ? prevIds.filter((prevId) => prevId !== arr[i].seat_unique_id)
          : [...prevIds, arr[i].seat_unique_id]
      );
    }
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const SeatConfigure = (e, seat) => {
    console.log(e, seat);
    const isSelected = selectedIds.includes(seat.seat_unique_id);
    setSelectedIds((prevIds) =>
      isSelected
        ? prevIds.filter((prevId) => prevId !== seat.seat_unique_id)
        : [...prevIds, seat.seat_unique_id]
    );

    var temp = _.clone(seatDetails);
    var tempp = temp[seat.rowstart];
    for (let i in tempp) {
      if (tempp[i].seat_unique_id == seat.seat_unique_id) {
        tempp[i].ischecked = e.target.checked;
        break; //Stop this loop, we found it!
      }
    }
    temp[seat.rowstart] = tempp;
    setseatDetails(temp);
    // if (showNext === false) {
    //   setShowNext(true);
    // }
  };
  console.log("selectedIds", selectedIds);

  const createSeasonalHandle = (secid) => {
    var API_URL = API_DOMAIN + `seasonal/create/byvenueid`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let data = {
      currency: passCurrency,
      price: seatPrice,
      // leagueid: selectedLeagueId,
      ticketList: selectedIds,
      venueid: selectedEventVenueId,
      ticketname: seasonName,
      url: seasonImageUrl,
      isParking: isParkingPass,
      ParkingLevel: parkingPass,
      isFoodAndBeverages: isFoodandDrink,
      teamid: selectedTeamid,
    };

    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("create seasonal", response);
        if (response.data.status === true) {
          toast.success(response.data.message);
          setTimeout(() => {
            navigate(`/landingpage?cid=${sessionStorage.getItem("userid")}`);
          }, 1000);
          console.log(parkingPass);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  const ImguplpoadValidation = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setSeasonImage(file);
        // You can perform further actions with the selected file here
      } else {
        setSeasonImage(null);
        alert("Please upload only JPEG or PNG files.");
      }
    }
  };
  const handleImgUpload = () => {
    setLoading(true);
    var API_URL = API_DOMAIN + "file/upload";
    var imgdata = new FormData();
    imgdata.append("att_file", seasonImage);
    let headerConfig = {
      headers: {
        "Content-Type": "form-data",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .post(API_URL, imgdata, headerConfig)
      .then(function (response) {
        console.log("Img Upload", response);
        if (response.status === 200) {
          setLoading(false);
          setSeasonImageUrl(response.data.data.Location);
          handleCloseImagePopup();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
          setTimeout(() => {
            setLoading(false);
            handleCloseImagePopup();
            setSeasonImage("");
          });
        }
      })
      .then(function () {});
  };
  const [selectedTeamid, setSelectedTeamid] = useState("");
  const handleSelectedTeam = (teamid) => {
    const filteredData = TeamList.filter((item) => item.teamid === teamid);
    console.log(filteredData);
    getEventsCard(filteredData[0].teamid);
  };
  const getEventsCard = (selectedteamid) => {
    var API_URL = API_DOMAIN + "event/public/getall";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("all events ", response.data.data.event);
        var res = response.data.data;
        const sortdata = res.event;
        sortdata.sort(
          (a, b) => new Date(a?.StartDateSort) - new Date(b?.StartDateSort)
        );
        const filteredEvents = sortdata.filter(
          (event) => event.teamid === selectedteamid
        );
        console.log("sortdata", sortdata, filteredEvents);
        setEventListsVenue(filteredEvents);
        setSelectedEventVenueId(filteredEvents[0].venueid);
        setSelectedEveCategoryDetails(filteredEvents[0].TicketDetailsList);
        setSelectedEveId(filteredEvents[0].event_unique_id);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_enterleaguename(
        xpath.select1("/resources/string[@name='text_enterleaguename']", doc)
          .firstChild.data
      );
      setText_seasonalpass1(
        xpath.select1("/resources/string[@name='text_seasonalpass1']", doc)
          .firstChild.data
      );
      setText_seasonalpass(
        xpath.select1("/resources/string[@name='text_createseasonalpass']", doc)
          .firstChild.data
      );
      setText_enterseatprice(
        xpath.select1("/resources/string[@name='text_enterseatprice']", doc)
          .firstChild.data
      );
      setText_seatprice(
        xpath.select1("/resources/string[@name='text_seatprice']", doc)
          .firstChild.data
      );
      setText_selectleague(
        xpath.select1("/resources/string[@name='text_selectleague']", doc)
          .firstChild.data
      );
      setText_createpass(
        xpath.select1("/resources/string[@name='text_createpass']", doc)
          .firstChild.data
      );
      setText_selectseat(
        xpath.select1("/resources/string[@name='text_selectseat']", doc)
          .firstChild.data
      );
      setText_selectcategory(
        xpath.select1("/resources/string[@name='text_selectcategory']", doc)
          .firstChild.data
      );
      setText_selectevent(
        xpath.select1("/resources/string[@name='text_selectevent']", doc)
          .firstChild.data
      );
      setText_upload(
        xpath.select1("/resources/string[@name='text_upload']", doc).firstChild
          .data
      );
      setText_upload_image(
        xpath.select1("/resources/string[@name='text_upload_image']", doc)
          .firstChild.data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_priceseasonpass(
        xpath.select1("/resources/string[@name='text_priceseasonpass']", doc)
          .firstChild.data
      );
      setText_currency(
        xpath.select1("/resources/string[@name='text_currency']", doc)
          .firstChild.data
      );
      setText_selcurrency(
        xpath.select1("/resources/string[@name='text_selcurrency']", doc)
          .firstChild.data
      );
      setText_enterpassbane(
        xpath.select1("/resources/string[@name='text_enterpassname']", doc)
          .firstChild.data
      );
      setText_seasonpassname(
        xpath.select1("/resources/string[@name='text_seasonpassname']", doc)
          .firstChild.data
      );
      setText_seasonpassimage(
        xpath.select1("/resources/string[@name='text_seasonpassimage']", doc)
          .firstChild.data
      );
      setText_createseasonpass(
        xpath.select1("/resources/string[@name='text_createseasonpass']", doc)
          .firstChild.data
      );
      setText_orgdashboard(
        xpath.select1("/resources/string[@name='text_orgdashboard']", doc)
          .firstChild.data
      );
      setText_teamname(
        xpath.select1("/resources/string[@name='text_teamname']", doc)
          .firstChild.data
      );
      setText_parkingdrop(
        xpath.select1("/resources/string[@name='text_parkingdrop']", doc)
          .firstChild.data
      );
      setText_vip(
        xpath.select1("/resources/string[@name='text_vip']", doc).firstChild
          .data
      );
      setText_gold(
        xpath.select1("/resources/string[@name='text_gold']", doc).firstChild
          .data
      );
      setText_silver(
        xpath.select1("/resources/string[@name='text_silver']", doc).firstChild
          .data
      );
      setText_parkingpass(
        xpath.select1("/resources/string[@name='text_parkingpass']", doc)
          .firstChild.data
      );
      setText_foodanddrinks(
        xpath.select1("/resources/string[@name='text_foodanddrinks']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div
        className="Create-event-wrapper eventdetail-nightmod"
        id="otherPages"
      >
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/org-dashboard">{text_orgdashboard}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_seasonalpass1}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center">
                  {/* <h3>{text_createseasonalpass}</h3> */}
                  <h3>{text_createseasonpass}</h3>
                </div>
              </div>
              <div className="main-card">
                <div className="p-4 bp-form main-form">
                  <div className="row p-4">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_seasonpassimage}
                        </label>
                        <button
                          className="btn btn-default btn-hover steps_btn mt-2"
                          onClick={handleOpenImagePopup}
                        >
                          {text_upload}
                        </button>
                        {seasonImageUrl !== "" ? (
                          <>
                            <img
                              src={seasonImageUrl}
                              alt=""
                              className="eventImg"
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    {/* {showSeasonalTeam === true ? (
                      <> */}
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {" "}
                          {text_teamname}
                        </label>
                        {/* <select
                              className="form-control h_50 w-75"
                              type="text"
                              placeholder="Select Team"
                              onChange={(e) => [setShowSeasonalEvents(true)]}
                            >
                              <option value="" selected disabled>
                                Select Team
                              </option>
                              {leagueTeamList.map((ld) => {
                                return (
                                  <>
                                    <option value={ld.leagueid}>
                                      {ld.leagueteame}
                                      {console.log(ld.leagueteame)}
                                    </option>
                                  </>
                                );
                              })}

                              
                            </select> */}
                        <select
                          className="form-control h_50 w-75 inputseasonalnight"
                          onChange={(e) => [
                            getEventsCard(e.target.value),
                            setSelectedTeamid(e.target.value),
                          ]}
                        >
                          <option value="" disabled selected>
                            Select Team
                          </option>
                          {TeamList.map((g) => {
                            return (
                              <>
                                <option value={g.teamid}>{g.teamname}</option>
                              </>
                            );
                          })}
                        </select>
                        {/* <input
                          type="text"
                          disabled
                          value="Cibona"
                          className="form-control h_50 w-75"
                        /> */}
                      </div>
                    </div>

                    {/* </>
                    ) : (
                      <></>
                    )} */}
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_seasonpassname}
                        </label>
                        <input
                          type="text"
                          className="form-control w-75  h_50 inputseasonalnight"
                          placeholder={text_enterpassname}
                          onChange={(e) => setSeasonName(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* {showSeasonalEvents === true ? (
                      <> */}
                    {/* <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          Select Game Event
                        </label>
                        <select
                          className="form-control h_50 w-75"
                          type="text"
                          placeholder="Select Game Event"
                          onChange={(e) => [
                            getselectedEveCategory(e.target.value),
                            setShowEventsCategory(true),
                            setShowEventList(true),
                          ]}
                        >
                          <option value="" selected disabled>
                            {text_selectevent}
                          </option>
                          {selectedLeagueEveDetails.map((e) => {
                            return (
                              <>
                                <option value={e.event_unique_id}>
                                  {e.EventTitle}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div> */}
                    <div className="col-lg-4 col-md-12 mb-2">
                      <div className="form-group border_bottom-create profile-night-form ">
                        <div className="">
                          <div className="d-flex">
                            <input
                              type="checkbox"
                              className="me-2"
                              onChange={() =>
                                setIsFoodandDrink(!isFoodandDrink)
                              }
                            />
                            <label className="form-label fs-16 mt-2">
                              {text_foodanddrinks}
                            </label>
                          </div>
                          <div className="d-flex">
                            <input
                              type="checkbox"
                              className="me-2"
                              onChange={() => setIsParkingPass(!isParkingPass)}
                            />
                            <label className="form-label fs-16 mt-2">
                              {text_parkingpass}
                            </label>
                          </div>
                        </div>
                      </div>
                      {isParkingPass === true ? (
                        <>
                          <select
                            className="form-control h_50 w-75 inputseasonalnight"
                            type="text"
                            onChange={(e) => setParkingPass(e.target.value)}
                          >
                            <option value="" disabled>
                              {text_parkingdrop}
                            </option>
                            <option value="VIP">{text_vip}</option>
                            <option value="Gold">{text_gold}</option>
                            <option value="Silver">{text_silver}</option>
                          </select>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* </>
                    ) : (
                      <></>
                    )} */}

                    <hr />
                    <h3>
                      Event Games Included In Season Pass(
                      {eventListsVenue.length} Events Included)
                    </h3>
                    <div className="row">
                      {eventListsVenue.map((ed) => {
                        return (
                          <>
                            <div className="col-lg-4 col-md-12  mt-3 sesonEveCheckBox">
                              <input type="checkbox" className="me-3" checked />
                              <span>{ed?.EventTitle}</span>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <hr />
                    {/* </>
                    ) : (
                      <></>
                    )} */}
                    {/* {showEventsCategory === true ? (
                      <> */}
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_selectcategory}
                        </label>
                        <select
                          className="form-control h_50 w-75 inputseasonalnight"
                          type="text"
                          placeholder={text_enterleaguename}
                          onChange={(e) => [
                            getSeatDetails(e.target.value),
                            setShowSeatsOfCategory(true),
                          ]}
                        >
                          <option value="" selected disabled>
                            {text_selectcategory}
                          </option>
                          {selectedEveCategoryDetails.map((cat) => {
                            return (
                              <>
                                <option value={cat.section_id}>
                                  {cat.section}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_selcurrency}{" "}
                        </label>
                        <select
                          className="form-control h_50 inputseasonalnight"
                          type="text"
                          placeholder={text_enterleaguename}
                          name="selectcategory"
                          onChange={(e) => [setPassCurrency(e.target.value)]}
                        >
                          <option value="" selected disabled>
                            {text_currency}{" "}
                          </option>
                          {Currency.map((currency) => {
                            return (
                              <>
                                <option value={currency.abbreviation}>
                                  {currency?.currency} ({currency?.symbol})
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_priceseasonpass}
                        </label>
                        <input
                          type="text"
                          className="form-control league-input  h_50 inputseasonalnight"
                          placeholder="Enter Price of Each Pass"
                          onChange={(e) => setSeatPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* </>
                    ) : (
                      <></>
                    )} */}
                    {showSeatsOfCategory === true ? (
                      <>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group border_bottom-create profile-night-form pb_30">
                            <label className="form-label fs-16">
                              {text_selectseat}
                            </label>
                            <div className="p-3">
                              <div className=" seatscroll ">
                                {Object.keys(seatDetails)?.map(function (
                                  rowstart
                                ) {
                                  return (
                                    <>
                                      <div className="p-3">
                                        <div className=" d-flex gap-4 justify-content-between">
                                          <div className="d-flex  gap-4 ">
                                            <h5>
                                              <input
                                                type="checkbox"
                                                className="mx-2 selectcheckSTY"
                                                checked={checkRowAll(
                                                  seatDetails[rowstart]
                                                )}
                                                onChange={(e) => [
                                                  handleSelectFullRow(
                                                    {
                                                      rowstart: rowstart,
                                                    },
                                                    e
                                                  ),
                                                  // setShowNext(true),
                                                ]}
                                              />
                                              {rowstart} .
                                            </h5>
                                            {seatDetails[rowstart].map(
                                              (seat, index) => {
                                                return (
                                                  <>
                                                    {/* {console.log(seat)} */}
                                                    <div
                                                      class="seats gap-3"
                                                      type="A"
                                                    >
                                                      <div
                                                        className={
                                                          seat.isdisable ===
                                                          true
                                                            ? "seatDisable seat"
                                                            : "seat"
                                                        }
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          id={seat?._id}
                                                          checked={
                                                            seat.ischecked
                                                          }
                                                          disabled={
                                                            seat.isbundle ===
                                                              true ||
                                                            seat.isseasonal ===
                                                              true ||
                                                            seat.sold ===
                                                              true ||
                                                            seat.isdisable ===
                                                              true
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        <label for={seat?._id}>
                                                          {seat?.seat_name}{" "}
                                                          <br />
                                                          {seat.currency}{" "}
                                                          {seat.seat_price ==
                                                          null
                                                            ? "null"
                                                            : seat.seat_price}
                                                        </label>
                                                      </div>
                                                      <input
                                                        type="checkbox"
                                                        className="selectcheckSTY mt-2"
                                                        onChange={(e) => [
                                                          SeatConfigure(
                                                            e,
                                                            seat
                                                          ),
                                                          // setShowNext(
                                                          //   true
                                                          // ),
                                                        ]}
                                                        checked={seat.ischecked}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              }
                                            )}
                                          </div>
                                          <div></div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          {/* <center>
                        <button className="saveBtn">Save Seat</button>
                      </center> */}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />
                    {/* <div className="col-lg-6 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">Select Event</label>
                        <select
                          className="form-control h_50 w-75"
                          type="text"
                          placeholder="Enter League Name"
                        >
                          <option value="" selected disabled>
                            Select Event
                          </option>
                          <option value="event1">Event-1</option>
                          <option value="event2">Event-2</option>
                          <option value="event3">Event-3</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          Select Category
                        </label>
                        <select
                          className="form-control h_50 w-75"
                          type="text"
                          placeholder="Enter League Name"
                        >
                          <option value="" selected disabled>
                            Select Category
                          </option>
                          <option value="event1">Event-1</option>
                          <option value="event2">Event-2</option>
                          <option value="event3">Event-3</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">Select Seat</label>
                        <div className="p-3">
                          <div className=" d-flex gap-4 justify-content-between">
                            <div className="d-flex  gap-4 ">
                              <h5>
                                <input
                                  type="checkbox"
                                  className="mx-2 selectcheckSTY"
                                />
                                1 .
                              </h5>
                              <div class="seats gap-3" type="A">
                                <div className="seat">
                                  <input type="checkbox" />
                                  <label>
                                    Seat-11 <br />$ 100
                                  </label>
                                </div>
                                <center>
                                  <input
                                    type="checkbox"
                                    className="selectcheckSTY mt-2"
                                  />
                                </center>
                              </div>
                              <div class="seats gap-3" type="A">
                                <div className="seat">
                                  <input type="checkbox" />
                                  <label>
                                    Seat-12 <br />$ 100
                                  </label>
                                </div>
                                <center>
                                  <input
                                    type="checkbox"
                                    className="selectcheckSTY mt-2"
                                  />
                                </center>
                              </div>
                              <div class="seats gap-3" type="A">
                                <div className="seat">
                                  <input type="checkbox" />
                                  <label>
                                    Seat-13 <br />$ 100
                                  </label>
                                </div>
                                <center>
                                  <input
                                    type="checkbox"
                                    className="selectcheckSTY mt-2"
                                  />
                                </center>
                              </div>
                              <div class="seats gap-3" type="A">
                                <div className="seat">
                                  <input type="checkbox" />
                                  <label>
                                    Seat-14 <br />$ 100
                                  </label>
                                </div>
                                <center>
                                  <input
                                    type="checkbox"
                                    className="selectcheckSTY mt-2"
                                  />
                                </center>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        </div>
                        <div className="p-3">
                          <div className=" d-flex gap-4 justify-content-between">
                            <div className="d-flex  gap-4 ">
                              <h5>
                                <input
                                  type="checkbox"
                                  className="mx-2 selectcheckSTY"
                                />
                                2 .
                              </h5>
                              <div class="seats gap-3" type="A">
                                <div className="seat">
                                  <input type="checkbox" />
                                  <label>
                                    Seat-21 <br />$ 100
                                  </label>
                                </div>
                                <center>
                                  <input
                                    type="checkbox"
                                    className="selectcheckSTY mt-2"
                                  />
                                </center>
                              </div>
                              <div class="seats gap-3" type="A">
                                <div className="seat">
                                  <input type="checkbox" />
                                  <label>
                                    Seat-22 <br />$ 100
                                  </label>
                                </div>
                                <center>
                                  <input
                                    type="checkbox"
                                    className="selectcheckSTY mt-2"
                                  />
                                </center>
                              </div>
                              <div class="seats gap-3" type="A">
                                <div className="seat">
                                  <input type="checkbox" />
                                  <label>
                                    Seat-23 <br />$ 100
                                  </label>
                                </div>
                                <center>
                                  <input
                                    type="checkbox"
                                    className="selectcheckSTY mt-2"
                                  />
                                </center>
                              </div>
                              <div class="seats gap-3" type="A">
                                <div className="seat">
                                  <input type="checkbox" />
                                  <label>
                                    Seat-24 <br />$ 100
                                  </label>
                                </div>
                                <center>
                                  <input
                                    type="checkbox"
                                    className="selectcheckSTY mt-2"
                                  />
                                </center>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <button className="saveBtn">Save Seat</button>
                      </center>
                    </div> */}
                    <div className="col-lg-12 col-md-12 mt-5">
                      <center>
                        <button
                          className="saveBtn"
                          onClick={createSeasonalHandle}
                        >
                          {text_createpass}
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openImageUploadPopup}
        onClose={handleCloseImagePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue"
                onClick={() => [handleCloseImagePopup()]}
              />
            </div>
            <h3 className="popup-main-title">{text_upload_image}</h3>
            <Divider />
            {Loading === true ? (
              <>
                <center className=" mt-3 mb-3">
                  <Loader />
                </center>
              </>
            ) : (
              <>
                <div className="container">
                  <div className="text-center form-group mt-3">
                    <input
                      type="file"
                      className="form-control h_50 uploadinput landinginputnight"
                      // onChange={(e) => [
                      //   setSeasonImage(e.target.files),
                      //   console.log(e.target.files),
                      // ]}
                      onChange={ImguplpoadValidation}
                    />
                  </div>
                  {/* <input type="file" onClick={(e)=>setSeasonImage(e.target.files)} /> */}
                </div>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <button
                    onClick={() => handleCloseImagePopup()}
                    className="cancelBtn"
                  >
                    {" "}
                    {text_cancel}
                  </button>

                  <button onClick={handleImgUpload} className="resell-Btn">
                    {" "}
                    {text_save}
                  </button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default SeasonalPass;
