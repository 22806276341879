import React, { useState, useEffect } from "react";
import "../OrgDashboard/OrgDashboardSidebar.css";
import { Link } from "react-router-dom";
import {
  FaCalendarAlt,
  FaPlusSquare,
  FaLock,
  FaCreditCard,
  FaChartPie,
  FaBahai,
  FaLink,
} from "react-icons/fa";
import { MdGroup, MdOutlineContactPhone } from "react-icons/md";
import { FaGauge } from "react-icons/fa6";
import { RiAdvertisementFill } from "react-icons/ri";
import { IoInformationCircle } from "react-icons/io5";
import { ToastContainer } from "react-toastify";
import { GlobalConstants } from "../../utils/GlobalConst";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function OrgDashboardSidebar() {
  const [text_about, setText_about] = useState("About");
  const [text_convsetup, setText_convsetup] = useState("Conversion Setup");
  const [text_subscription, setText_subscription] = useState("Subscription");
  const [text_reports, setText_reports] = useState("Reports");
  const [text_payouts, setText_payouts] = useState("Payouts");
  const [text_contactlist, setText_contactlist] = useState("Contact List");
  const [text_promotion, setText_promotion] = useState("Promotion");
  const [text_events, setText_events] = useState("Events");
  const [text_landingpage, setText_landingpage] = useState("Landing Page");
  const [text_dashboard, setText_dashboard] = useState("Dashboard");
  const [text_myteam, setText_myteam] = useState("My Team");
  //active page
  let dashboard,
    Events,
    PayOuts,
    MyTeam,
    report,
    About,
    landing = "headerText";
  const pageName = window.location.pathname.split("/");
  if (pageName[1] === "org-dashboard") {
    dashboard = "active-sidebar";
    Events = "headerText";
    PayOuts = "headerText";
    report = "headerText";
    MyTeam = "headerText";
    landing = "headerText";
    About = "headerText";
  } else if (pageName[1] === "org-events") {
    dashboard = "headerText";
    Events = "active-sidebar";
    PayOuts = "headerText";
    report = "headerText";
    MyTeam = "headerText";
    landing = "headerText";
    About = "headerText";
  } else if (pageName[1] === "org-payout") {
    dashboard = "headerText";
    Events = "headerText";
    PayOuts = "active-sidebar";
    report = "headerText";
    MyTeam = "headerText";
    landing = "headerText";
    About = "headerText";
  } else if (pageName[1] === "org-report") {
    dashboard = "headerText";
    Events = "headerText";
    PayOuts = "headerText";
    report = "active-sidebar";
    MyTeam = "headerText";
    landing = "headerText";
    About = "headerText";
  } else if (pageName[1] === "org-myteam") {
    dashboard = "headerText";
    Events = "headerText";
    PayOuts = "headerText";
    report = "headerText";
    MyTeam = "active-sidebar";
    landing = "headerText";
    About = "headerText";
  } else if (pageName[1] === "org-landing") {
    dashboard = "headerText";
    Events = "headerText";
    PayOuts = "headerText";
    report = "headerText";
    MyTeam = "headerText";
    landing = "active-sidebar";
    About = "headerText";
  } else if (pageName[1] === "org-about") {
    dashboard = "headerText";
    Events = "headerText";
    PayOuts = "headerText";
    report = "headerText";
    MyTeam = "headerText";
    landing = "headerText";
    About = "active-sidebar";
  }
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_about(
        xpath.select1("/resources/string[@name='text_about']", doc).firstChild
          .data
      );
      setText_convsetup(
        xpath.select1("/resources/string[@name='text_convsetup']", doc)
          .firstChild.data
      );
      setText_subscription(
        xpath.select1("/resources/string[@name='text_subscription']", doc)
          .firstChild.data
      );
      setText_reports(
        xpath.select1("/resources/string[@name='text_reports']", doc).firstChild
          .data
      );
      setText_payouts(
        xpath.select1("/resources/string[@name='text_payouts']", doc).firstChild
          .data
      );
      setText_contactlist(
        xpath.select1("/resources/string[@name='text_contactlist']", doc)
          .firstChild.data
      );
      setText_promotion(
        xpath.select1("/resources/string[@name='text_promotion']", doc)
          .firstChild.data
      );
      setText_events(
        xpath.select1("/resources/string[@name='text_events']", doc).firstChild
          .data
      );
      setText_landingpage(
        xpath.select1("/resources/string[@name='text_landingpage']", doc)
          .firstChild.data
      );
      setText_dashboard(
        xpath.select1("/resources/string[@name='text_dashboard']", doc)
          .firstChild.data
      );
      setText_myteam(
        xpath.select1("/resources/string[@name='text_myteam']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <nav className="vertical_nav " id="vertical_nav">
        <div className="left_section menu_left" id="js-menu">
          <div className="left_section">
            <ul>
              <li className="menu--item">
                <Link
                  id={dashboard}
                  to="/org-dashboard"
                  className="menu--link"
                  title="Dashboard"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <i>
                    <FaGauge className="menu--icon" />
                  </i>
                  <span className="menu--label">{text_dashboard}</span>
                </Link>
              </li>
              <li className="menu--item">
                <Link
                  id={landing}
                  to="/org-landing/info"
                  className="menu--link"
                  title="landing"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <i>
                    <FaLink className="menu--icon" />
                  </i>
                  <span className="menu--label">{text_landingpage}</span>
                </Link>
              </li>
              <li className="menu--item">
                <Link
                  id={Events}
                  to="/org-events"
                  className="menu--link"
                  title="Events"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <i>
                    <FaCalendarAlt className="menu--icon" />
                  </i>
                  <span className="menu--label">{text_events}</span>
                </Link>
              </li>
              <li className="menu--item">
                <Link
                  className="menu--link"
                  title="Promotion"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <i>
                    <RiAdvertisementFill className="menu--icon" />
                  </i>
                  <span className="menu--label">{text_promotion}</span>
                </Link>
              </li>
              <li className="menu--item">
                <Link
                  className="menu--link"
                  title="Contact List"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <i>
                    <MdOutlineContactPhone className="menu--icon" />
                  </i>
                  <span className="menu--label">{text_contactlist}</span>
                </Link>
              </li>
              <li className="menu--item">
                <Link
                  id={PayOuts}
                  to="/org-payout"
                  className="menu--link"
                  title="Payouts"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <i>
                    {" "}
                    <FaCreditCard className="menu--icon" />
                  </i>
                  <span className="menu--label">{text_payouts}</span>
                </Link>
              </li>
              <li className="menu--item">
                <Link
                  to="/org-report"
                  className="menu--link"
                  title="Reports"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  id={report}
                >
                  <i>
                    {" "}
                    <FaChartPie className="menu--icon" />
                  </i>
                  <span className="menu--label">{text_reports}</span>
                </Link>
              </li>
              <li className="menu--item">
                <Link
                  className="menu--link"
                  title="Subscription"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <i>
                    <FaBahai className="menu--icon" />
                  </i>
                  <span className="menu--label">{text_subscription}</span>
                </Link>
              </li>
              <li className="menu--item">
                <Link
                  className="menu--link"
                  title="Conversion Setup"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <i>
                    <FaPlusSquare className="menu--icon" />
                  </i>
                  <span className="menu--label">{text_convsetup}</span>
                </Link>
              </li>
              <li className="menu--item">
                <Link
                  to="/org-about"
                  className="menu--link"
                  title="About"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  id={About}
                >
                  <i>
                    <IoInformationCircle className="menu--icon" />
                  </i>
                  <span className="menu--label">{text_about}</span>
                </Link>
              </li>
              <li className="menu--item">
                <Link
                  to="/org-myteam"
                  className="menu--link"
                  title="My Team"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  id={MyTeam}
                >
                  <i>
                    <MdGroup className="menu--icon" />
                  </i>
                  <span className="menu--label" id="my-team-lock">
                    {text_myteam}
                  </span> 
                  {/* <FaLock className="team-lock" /> */}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <ToastContainer />
    </>
  );
}

export default OrgDashboardSidebar;
