import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "../Team/CreateTeam.css";
import { API_DOMAIN,GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function CreateTeam() {
  const navigate = useNavigate();
  const [teamName, setTeamName] = useState("");
  const [teamActive, setTeamActive] = useState("");
  const [venueList, setVenueList] = useState([]);
  const [selectedVenueName, setSelectedVenueName] = useState("");
  const [selectedVenueId, setSelectedVenueId] = useState("");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_home, setText_home] = useState("Home");
  const [text_save, setText_save] = useState("Save");
  const [text_active, setText_active] = useState("Active");
  const [text_addadmin, setText_addadmin] = useState("Add Admin");
  const [text_adminval, setText_adminval] = useState("Enter Admin Email");
  const [text_adminemail, setText_adminemail] = useState("Admin Email");
  const [text_admin, setText_admin] = useState("Admin");
  const [text_venuename, setText_venuename] = useState("Select Venue Name");
  const [text_teamhomevenue, setText_teamhomevenue] =
    useState("Team Home Venue");
  const [text_enterteamname, setText_enterteamname] =
    useState("Enter Team Name");
const[text_teamname,setText_teamname] = useState("Team name");
const[text_createteam,setText_createteam] = useState("Create Team");
const [text_orgdashboard, setText_orgdashboard] = useState(
  "Organization Dashboard"
);
  const [adminInputFields, setAdminInputFields] = useState([
    {
      _id: uuidv4(),
      adminEmail: "",
    },
  ]);
  const handleChangeInput = (_id, event) => {
    const newInputFields = adminInputFields.map((i) => {
      if (_id === i._id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setAdminInputFields(newInputFields);
  };
  const handleAddFields = () => {
    setAdminInputFields([
      ...adminInputFields,
      {
        _id: uuidv4(),
        adminEmail: "",
      },
    ]);
  };
  const handleRemoveAdmin = (_id) => {
    const values = [...adminInputFields];
    values.splice(
      values.findIndex((value) => value._id === _id),
      1
    );
    setAdminInputFields(values);
    // }
  };
  const handleCreateTeam = () => {
    const adminllist = [];
    for (let i = 0; i < adminInputFields.length; i++) {
      adminllist.push(adminInputFields[i].adminEmail);
    }
    console.log(adminllist);
    var API_URL = API_DOMAIN + "team/create";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      team_id_: uuidv4(),
      teamname: teamName,
      isteamactive: teamActive,
      adminlist: adminllist,
      venueid: selectedVenueId,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("create Team", response);
        if (response.data.status === true) {
          toast.success(response.data.message);
          setTimeout(() => {
            navigate("/org-myteam");
          }, 1000);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  const getVenueDetails = () => {
    var API_URL = API_DOMAIN + "venue/venue";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("venue", response);
        setVenueList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getVenueDetails();
  }, []);
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_active(
        xpath.select1("/resources/string[@name='text_active']", doc).firstChild
          .data
      );
      setText_addadmin(
        xpath.select1("/resources/string[@name='text_addadmin']", doc).firstChild
          .data
      );
      setText_adminval(
        xpath.select1("/resources/string[@name='text_adminval']", doc).firstChild
          .data
      );
      setText_adminemail(
        xpath.select1("/resources/string[@name='text_adminemail']", doc).firstChild
          .data
      );
      setText_admin(
        xpath.select1("/resources/string[@name='text_admin']", doc).firstChild
          .data
      );
      setText_venuename(
        xpath.select1("/resources/string[@name='text_venuename']", doc).firstChild
          .data
      );
      setText_teamhomevenue(
        xpath.select1("/resources/string[@name='text_teamhomevenue']", doc).firstChild
          .data
      );
      setText_enterteamname(
        xpath.select1("/resources/string[@name='text_enterteamname']", doc).firstChild
          .data
      );
      setText_teamname(
        xpath.select1("/resources/string[@name='text_teamname']", doc).firstChild
          .data
      );
      setText_createteam(
        xpath.select1("/resources/string[@name='text_createteam']", doc).firstChild
          .data
      );
      setText_orgdashboard(
        xpath.select1("/resources/string[@name='text_orgdashboard']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div
        className="Create-event-wrapper eventdetail-nightmod"
        id="otherPages"
      >
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/org-dashboard">{text_orgdashboard}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_createteam}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container ">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center">
                  <h3>{text_createteam}</h3>
                </div>
              </div>
              <div className="main-card">
                <div className="p-4 bp-form main-form">
                  <div className="row p-4">
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_teamname}
                          <span className="starsty">*</span>
                        </label>
                        <input
                          className="form-control h_50 inputleaguenight"
                          type="text"
                          placeholder={text_enterteamname}
                          onChange={(e) => setTeamName(e.target.value)}
                        />
                      </div>
                      <div className="form-group border_bottom-create profile-night-form pb_30 d-flex">
                        <input
                          type="checkbox"
                          className="mb-2 me-2"
                          name="isteamactive"
                          id=""
                          onChange={(e) => setTeamActive(e.target.checked)}
                        />
                        <label className="form-label mt-2">{text_active}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_teamhomevenue}
                          <span className="starsty">*</span>
                        </label>
                        <select
                          className="form-control h_50 inputleaguenight"
                          type="text"
                          placeholder={text_enterteamname}
                          onChange={(e) => setSelectedVenueId(e.target.value)}
                        >
                          <option selected disabled>
                            {text_venuename}
                          </option>
                          {venueList.map((vl) => {
                            return (
                              <>
                                <option value={vl?.venueid}>
                                  {vl?.venuename}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row p-4">
                    <h2>{text_admin} </h2>
                    {adminInputFields.map((inputField) => {
                      return (
                        <>
                          <div className="col-lg-4 col-md-12">
                            <div className="form-group border_bottom-create profile-night-form pb_30">
                              <label className="form-label fs-16">
                                {text_adminemail}
                                <span className="starsty">*</span>
                              </label>
                              <div className="d-flex gap-4">
                                <input
                                  className="form-control h_50 w-75 inputleaguenight"
                                  type="text"
                                  placeholder={text_adminval}
                                  value={inputField.adminEmail}
                                  name="adminEmail"
                                  id=""
                                  onChange={(e) =>
                                    handleChangeInput(inputField._id, e)
                                  }
                                />
                                <button
                                  onClick={() =>
                                    handleRemoveAdmin(inputField._id)
                                  }
                                  className="removeBtnTeam"
                                >
                                  {" "}
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    <div className="justify-content-start d-flex gap-1 mb-2">
                      <button onClick={handleAddFields} className="ADDBtn">
                        +
                      </button>{" "}
                      <p>{text_addadmin}</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-4 mt-5">
                  <button className="cancelBtn" onClick={() => navigate(-1)}>
                    {text_cancel}
                  </button>
                  <button className="saveBtn" onClick={handleCreateTeam}>
                    {text_save}
                  </button>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default CreateTeam;
