import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Link } from "react-router-dom";
import {
  AppBar,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { API_DOMAIN, GlobalConstants} from "../../utils/GlobalConst";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function OrganizationList() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [deactiveorganizationList, setDeactiveOrganizationList] = useState([]);
  const [activeorganizationList, setActiveOrganizationList] = useState([]);
  const [activeOrgId, setActiveOrgId] = useState("");
  const [deactiveOrgId, setdeactiveOrgId] = useState("");
  const [orgName, setOrgName] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openActiveOrg, setOpenActiveOrg] = useState(false);
  const [openDeactiveOrg, setOpenDeactiveOrg] = useState(false);

  // language Const Start
  const [text_close, setText_close] = useState("Close");
const[text_fullname,setText_fullname] = useState("Full Name");
const[text_orgname,setText_orgname] = useState("Organization Name");
const [text_email, setText_email] = useState("Email");
const [text_phoneNumber, setText_phoneNumber] = useState("Phone Number");
const[text_changestatus,setText_changestatus] = useState("Change Status");
const[text_deactivate,setText_deactivate] = useState("Deactivate");
const[text_home,setText_home] = useState("Home");
const[text_orglist,setText_orglist] = useState("Organization List");
const[text_youactivate,setText_youactivate] = useState("You want to Activate");
const[text_activate,setText_activate] = useState("Activate");
const[text_youdeactivate,setText_youdeactivate] = useState("You want to Deactivate");
const[text_active,setText_active] = useState("Active");
const[text_deactive,setText_deactive] = useState("Deactive");


  const customStyles = {
    rows: {
      style: {},
    },
    cells: {
      style: {
        border: "0.5px solid #EEEEEE",
        background: "#f5f7f9",
      },
    },
    headCells: {
      style: {
        fontSize: "16px",
        textTransform: "uppercase",
        paddingLeft: "10px",
        border: "1px solid #EEEEEE",
        background: "#f5f7f9",
        color: "#1d3f73",
      },
    },
  };

  const activeUsercolumns = [
    {
      name: <>{text_fullname}</>,
      selector: (row) => (
        <>
          {row?.orgDetails[0].firstname} {row?.orgDetails[0].lastname}
        </>
      ),
    },
    {
      name: <>{text_orgname}</>,
      selector: (row) => row?.orgDetails[0].orgname,
    },
    {
      name: <>{text_email} </>,
      selector: (row) => row?.email,
    },
    {
      name: <>{text_phoneNumber}</>,
      selector: (row) => row?.mobile,
    },
    {
      name: <>{text_changestatus}</>,
      selector: (row) => (
        <button
          onClick={() => [
            setOpenDeactiveOrg(true),
            setdeactiveOrgId(row?._id),
            setOrgName(row?.orgDetails[0].orgname),
          ]}
          className="DeactiveBtn"
        >
          {text_deactivate}
        </button>
      ),
    },
    // {
    //   name: <>{text_purchaseDate}</>,
    //   selector: (row) => moment(row.buydatetime).format("DD MMM,yyyy hh:mm a"),
    // },
  ];

  const deactiveUsercolumns = [
    {
      name: <>{text_fullname}</>,
      selector: (row) => (
        <>
          {row?.orgDetails[0].firstname} {row?.orgDetails[0].lastname}
        </>
      ),
    },
    {
      name: <>{text_orgname}</>,
      selector: (row) => row?.orgDetails[0].orgname,
    },
    {
      name: <>{text_email} </>,
      selector: (row) => row?.email,
    },
    {
      name: <>{text_phoneNumber}</>,
      selector: (row) => row?.mobile,
    },
    {
      name: <>{text_changestatus}</>,
      selector: (row) => (
        <button
          onClick={() => [
            setOpenActiveOrg(true),
            setActiveOrgId(row?._id),
            setOrgName(row?.orgDetails[0].orgname),
          ]}
          className="ActiveBtn"
        >
          Activate
        </button>
      ),
    },
    // {
    //   name: <>{text_purchaseDate}</>,
    //   selector: (row) => moment(row.buydatetime).format("DD MMM,yyyy hh:mm a"),
    // },
  ];
  const deactivefilteredUser = deactiveorganizationList.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const activefilteredUser = activeorganizationList.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleCloseActive = () => {
    setOpenActiveOrg(false);
    setOrgName("");
  };
  const handleCloseDeactive = () => {
    setOpenDeactiveOrg(false);
    setOrgName("");
  };

  useEffect(() => {
    var API_URL = API_DOMAIN + "user/getactiveorglist";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response.data.data;
        console.log("active", res);
        setActiveOrganizationList(res);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      })
      .then(function () {});
  }, []);
  useEffect(() => {
    var API_URL = API_DOMAIN + "user/getdeactiveorglist";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response.data.data;
        console.log(res);
        setDeactiveOrganizationList(res);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      })
      .then(function () {});
  }, []);
  const handleActiveOrg = () => {
    var API_URL =
      API_DOMAIN +
      `user/activeorg?emailverify=false&mobileverify=false&orgid=${activeOrgId}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let body = { emailverify: true, mobileverify: false };
    axios
      .post(API_URL, body, headerConfig)
      .then(function (response) {
        var res = response;
        console.log("active", res);
        if (res.data.status === true) {
          toast.success(res.data.message);
          handleCloseActive();
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      })
      .then(function () {});
  };
  const handleDeactiveOrg = () => {
    var API_URL =
      API_DOMAIN +
      `user/deactiveorg?emailverify=false&mobileverify=false&orgid=${deactiveOrgId}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let body = { emailverify: true, mobileverify: false };
    axios
      .post(API_URL, body, headerConfig)
      .then(function (response) {
        var res = response;
        console.log("deactive", res);
        if (res.data.status === true) {
          toast.success(res.data.message);
          handleCloseDeactive();
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      })
      .then(function () {});
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_close(
        xpath.select1("/resources/string[@name='text_close']", doc)
          .firstChild.data
      );
      setText_fullname(
        xpath.select1("/resources/string[@name='text_fullname']", doc)
          .firstChild.data
      );
      setText_orgname(
        xpath.select1("/resources/string[@name='text_orgname']", doc)
          .firstChild.data
      );
      setText_email(
        xpath.select1("/resources/string[@name='text_email']", doc)
          .firstChild.data
      );
      setText_phoneNumber(
        xpath.select1("/resources/string[@name='text_phoneNumber']", doc)
          .firstChild.data
      );
      setText_changestatus(
        xpath.select1("/resources/string[@name='text_changestatus']", doc)
          .firstChild.data
      );
      setText_deactivate(
        xpath.select1("/resources/string[@name='text_deactivate']", doc)
          .firstChild.data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc)
          .firstChild.data
      );
      setText_orglist(
        xpath.select1("/resources/string[@name='text_orglist']", doc)
          .firstChild.data
      );
      setText_youactivate(
        xpath.select1("/resources/string[@name='text_youactivate']", doc)
          .firstChild.data
      );
      setText_activate(
        xpath.select1("/resources/string[@name='text_activate']", doc)
          .firstChild.data
      );
      setText_youdeactivate(
        xpath.select1("/resources/string[@name='text_youdeactivate']", doc)
          .firstChild.data
      );
      setText_active(
        xpath.select1("/resources/string[@name='text_active']", doc)
          .firstChild.data
      );
      setText_deactive(
        xpath.select1("/resources/string[@name='text_deactive']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="wrapper" id="otherPages">
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_orglist}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-50">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center mt-3">
                  <h3>{text_orglist}</h3>
                </div>
              </div>
            </div>
            <div>
              <AppBar
                position="static"
                className="tab_bar tabBG profile-appbar"
                id="mobBoxtab"
              >
                <Tabs value={selectedTab} onChange={handleChange}>
                  <Tab label={text_active} />
                  <Tab label={text_deactive} />
                </Tabs>
              </AppBar>
            </div>
            {selectedTab === 0 && (
              <>
                <div className="mt-3">
                  <DataTable
                    columns={activeUsercolumns}
                    data={activefilteredUser}
                    pagination
                    selectableRowsHighlight
                    highlightOnHover
                    customStyles={customStyles}
                  />
                </div>
              </>
            )}
            {selectedTab === 1 && (
              <div className="mt-3">
                <DataTable
                  columns={deactiveUsercolumns}
                  data={deactivefilteredUser}
                  pagination
                  selectableRowsHighlight
                  highlightOnHover
                  customStyles={customStyles}
                />
              </div>
            )}
          </div>
        </div>
        <Dialog
          fullScreen={fullScreen}
          open={openActiveOrg}
          onClose={handleCloseActive}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="text-center"
          ></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <h3 className="text-center">{text_youactivate} {orgName}</h3>
              <br />
              <center></center>
            </DialogContentText>
            <Divider />
          </DialogContent>
          <>
            <div className="d-flex justify-content-center mb-3 gap-2">
              <button className="cancelBtn " onClick={handleCloseActive}>
                {text_close}
              </button>
              <button className="ActiveBtn " onClick={handleActiveOrg}>
                {text_activate}
              </button>
            </div>
          </>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={openDeactiveOrg}
          onClose={handleCloseDeactive}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="text-center"
          ></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <h3 className="text-center">{text_youdeactivate} {orgName}</h3>
              <br />
              <center></center>
            </DialogContentText>
            <Divider />
          </DialogContent>
          <>
            <div className="d-flex justify-content-center mb-3 gap-2">
              <button className="cancelBtn " onClick={handleCloseDeactive}>
                {text_close}
              </button>
              <button className="DeactiveBtn " onClick={handleDeactiveOrg}>
                {text_deactivate}
              </button>
            </div>
          </>
        </Dialog>
      </div>
      <ToastContainer />
    </>
  );
}

export default OrganizationList;
