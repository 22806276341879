import React, { useEffect, useState } from "react";
import "../Footer/Footer.css";
import "../Header/Header.css";
import { Link, useNavigate } from "react-router-dom";
import { ImFacebook2 } from "react-icons/im";
import {
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import { toast } from "react-toastify";
import { GlobalConstants } from "../../utils/GlobalConst";
import moment from "moment";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CgCloseO } from "react-icons/cg";

const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function Footer() {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const isOrg = sessionStorage.getItem("isOrg");
  const isOrgActive = sessionStorage.getItem("isOrgActive");
  const [openactivationPending, setOpenactivationPending] = useState(false);

  const [text_info_please_login, setText_info_please_login] =
    useState("Please Login First");
  const [text_loginAsOrg_info, setText_loginAsOrg_info] = useState(
    "Please Login As An Organization To Create Event!"
  );
  const [text_activationPending, setText_activationPending] =
    useState("Activation Pending");
  const [text_okay, setText_okay] = useState("Okay");
  const [btn_create_event, setBtn_create_event] = useState("Create Event");
  const [text_faq, setText_faq] = useState("FAQs");
  const [text_contactus, setText_contactus] = useState("Contact Us");
  const [text_company, setText_company] = useState("Company");
  const [text_usefullinks, setText_usefullinks] = useState("Useful Links");
  const [text_privacypolicy, setText_privacypolicy] =
    useState("Privacy Policy");
  const [text_termsandcondition, setText_termsandcondition] =
    useState("Terms & Conditions");
  const [text_followus, setText_followus] = useState("Follow Us");
  const [text_rightsReserved, setText_rightsReserved] = useState(
    "All rights reserved"
  );
  useEffect(() => {
    if (sessionStorage.getItem("isOrg") === "true") {
      setShowCreateEvent(true);
    }
  }, [isOrg]);

  const createEventHandler = () => {
    if (sessionStorage.getItem("token") === null) {
      toast.info(<>{text_info_please_login}</>);
    } else if (
      sessionStorage.getItem("isOrg") === "false" ||
      sessionStorage.getItem("isOrg") === "undefined"
    ) {
      console.log("in else if");
      toast.info(<>{text_loginAsOrg_info}</>);
    } else if (
      sessionStorage.getItem("isOrg") === "true" &&
      sessionStorage.getItem("isOrgActive") === "false"
    ) {
      console.log("in else else if");
      setOpenactivationPending(true);
      // toast.info(<>{text_activationPending}</>);
    } else {
      navigate("/event-type");
    }
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setBtn_create_event(
        xpath.select1("/resources/string[@name='btn_create_event']", doc)
          .firstChild.data
      );
      setText_faq(
        xpath.select1("/resources/string[@name='text_faq']", doc).firstChild
          .data
      );
      setText_contactus(
        xpath.select1("/resources/string[@name='text_contactus']", doc)
          .firstChild.data
      );
      setText_company(
        xpath.select1("/resources/string[@name='text_company']", doc).firstChild
          .data
      );
      setText_usefullinks(
        xpath.select1("/resources/string[@name='text_usefullinks']", doc)
          .firstChild.data
      );
      setText_privacypolicy(
        xpath.select1("/resources/string[@name='text_privacypolicy']", doc)
          .firstChild.data
      );
      setText_termsandcondition(
        xpath.select1("/resources/string[@name='text_termsandcondition']", doc)
          .firstChild.data
      );
      setText_followus(
        xpath.select1("/resources/string[@name='text_followus']", doc)
          .firstChild.data
      );
      setText_activationPending(
        xpath.select1("/resources/string[@name='text_activationPending']", doc)
          .firstChild.data
      );
      setText_okay(
        xpath.select1("/resources/string[@name='text_okay']", doc).firstChild
          .data
      );
      setText_info_please_login(
        xpath.select1("/resources/string[@name='text_info_please_login']", doc)
          .firstChild.data
      );
      setText_loginAsOrg_info(
        xpath.select1("/resources/string[@name='text_loginAsOrg_info']", doc)
          .firstChild.data
      );
      setText_rightsReserved(
        xpath.select1("/resources/string[@name='text_rightsReserved']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <footer className="footer mt-auto header-font" id="otherPages">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="footer-content">
                  <h4>{text_company}</h4>
                  <ul className="footer-link-list">
                    {/* <li>
                      <Link to="#" className="footer-link">
                        About Us
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="#" className="footer-link">
                        Help Center
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/faq" className="footer-link">
                        {text_faq}
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/contact-us" className="footer-link">
                        {text_contactus}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-content">
                  <h4>{text_usefullinks}</h4>
                  <ul className="footer-link-list">
                    {showCreateEvent === true ? (
                      <>
                        <li>
                          <button
                            onClick={createEventHandler}
                            className="footer-link footer-button"
                          >
                            {btn_create_event}
                          </button>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <li>
                      <Link to="#" className="footer-link">
                        Sell Tickets Online
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/privacy-policy" className="footer-link">
                        {text_privacypolicy}
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-conditions" className="footer-link">
                        {text_termsandcondition}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                {/* <div className="footer-content">
                  <h4>Resources</h4>
                  <ul className="footer-link-list">
                    <li>
                      <Link to="#" className="footer-link">
                        Pricing
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="footer-link">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="footer-link">
                        Refer a Friend
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-content">
                  <h4>{text_followus}</h4>
                  <ul className="social-links">
                    <li>
                      <Link to="#" className="social-link">
                        <ImFacebook2 className="socialmedia" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="social-link">
                        <FaInstagram className="socialmedia" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="social-link">
                        <FaTwitter className="socialmedia" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="social-link">
                        <FaLinkedinIn className="socialmedia" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="social-link">
                        <FaYoutube className="socialmedia" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="footer-copyright-text">
                  <p className="mb-0">
                    © {moment(new Date()).format("YYYY")}, <strong>TiQ8</strong>
                    . {text_rightsReserved}.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={openactivationPending}
          id="MainDiv"
          className="signout-modal"
          onClose={() => setOpenactivationPending(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" className="text-end">
            <CgCloseO
              className="closeIconSty"
              onClick={() => [setOpenactivationPending(false)]}
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <h3 className="text-center">{text_activationPending}</h3>
            </DialogContentText>
            <Divider />
          </DialogContent>
          <div className="d-flex justify-content-center gap-4 mb-3">
            <button
              className="popup_btn_No"
              onClick={() => setOpenactivationPending(false)}
            >
              {text_okay}
            </button>
          </div>
        </Dialog>
      </div>
    </>
  );
}

export default Footer;
