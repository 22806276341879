import React from 'react'
import "../utils/Loader.css"
function Loader() {
  return (
    <>
      <div className="load-icon">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>
  );
}

export default Loader