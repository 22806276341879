import React, { useEffect, useState } from "react";
import "../EventDetails/EventDetails.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HiUserCircle } from "react-icons/hi2";
import { MdDateRange, MdFileCopy } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { AiOutlineShareAlt } from "react-icons/ai";
import { FaBookmark, FaCalendarAlt, FaInstagram } from "react-icons/fa";
import { RiVidiconLine } from "react-icons/ri";
import { FaLongArrowAltRight } from "react-icons/fa";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { IoTime } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FiBookmark } from "react-icons/fi";
import axios from "axios";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import moment from "moment";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BiLogIn } from "react-icons/bi";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function EventDetails() {
  const Countdown = ({ targetDate }) => {
    const calculateTimeLeft = () => {
      const difference = new Date(targetDate) - new Date();
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }

      return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearTimeout(timer);
    });

    const { days, hours, minutes, seconds } = timeLeft;

    return (
      <div>
        {days !== undefined && days >= 0 ? (
          <h6
            style={{
              color: "white",
              textTransform: "lowercase",
              fontWeight: "700",
            }}
          >
            {days}
            {text_d} : {hours}
            {text_h} : {minutes}
            {text_m} : {seconds}s
          </h6>
        ) : (
          <h6 style={{ color: "white", fontSize: "18px", marginBottom: "0px" }}>
            {text_countdownfinished}!
          </h6>
        )}
      </div>
    );
  };

  const navigate = useNavigate();
  const { eventID } = useParams();
  const [Data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [allEventData, setAllEventData] = useState([]);
  const [indexBookmark, setIndexBookmark] = useState("");
  // const [hasMoreEvents, setHasMoreEvents] = useState("");
  // const [nextToken, setNextToken] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openloginInfo, setOpenloginInfo] = useState(false);
  const handleCloseloginInfo = () => {
    setOpenloginInfo(false);
    navigate("/signin", {
      state: { pageLand: window.location.pathname },
    });
  };
  const [text_ticketType, setText_ticketType] = useState("Ticket Type");
  const [text_price_ticket, setText_price_ticket] =
    useState("Price Per Ticket");
  const [text_availableTicket, setText_availableTicket] =
    useState("Available Tickets");
  const [text_info_please_login, setText_info_please_login] = useState(
    "Please Login To Proceed Further"
  );
  const [text_home, setText_home] = useState("Home");
  const [text_exploreevent, setText_exploreevent] = useState("Explore Events");
  const [text_evedetailview, setText_evedetailview] =
    useState("Event Detail View");
  const [text_startson, setText_startson] = useState("Starts on");
  const [text_save, setText_save] = useState("Save");
  const [text_share, setText_share] = useState("Share");
  const [text_insta, setText_insta] = useState("Instagram");
  const [text_facebook, setText_facebook] = useState("Facebook");
  const [text_twitter, setText_twitter] = useState("Twitter");
  const [text_linkedin, setText_linkedin] = useState("LinkedIn");
  const [text_email, setText_email] = useState("Email");
  const [text_aboutthisevent, setText_aboutthisevent] =
    useState("About This Event");
  const [text_eveDetails, setText_eveDetails] = useState("Event Details");
  const [text_time, setText_time] = useState("Time");
  const [text_location, setText_location] = useState("Location");
  const [text_booknow, setText_booknow] = useState("Book Now");
  const [text_moreevents, setText_moreevents] = useState("More Events");
  const [text_browseall, setText_browseall] = useState("Browse All");
  const [text_h, setText_h] = useState("h");
  const [text_d, setText_d] = useState("d");
  const [text_m, setText_m] = useState("m");
  const [text_date, setText_date] = useState("Date");
  const [text_countdownfinished, setText_countdownfinished] =
    useState("Countdown finished");
  const [text_copyurl, setText_copyurl] = useState("Copy Event URL");
  const [text_okay, setText_okay] = useState("Okay");
  useEffect(() => {
    var type = "select";
    var event_unique_id = eventID;
    var API_URL =
      API_DOMAIN +
      "event/public?type=" +
      type +
      "&event_unique_id=" +
      event_unique_id;

    axios
      .get(API_URL)
      .then(function (response) {
        console.log("Detaied Event", response.data.data);
        var res = response.data.data;
        setData(res);
        setTableData(res[0].TicketDetailsList);
      })
      .catch(function (error) {
        // errorToast(err);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  }, [eventID]);
  const handleCopyUrl = (sm) => {
    navigator.clipboard.writeText(window.location.href);
    // alert("link copied")
    // toast.info("Link copied to clipboard!");
    // handleTooltipOpen()
    if (sm === "instagram") {
      handleTooltipOpenInsta();
      setTimeout(() => {
        window.open("https://www.instagram.com/", "_blank", "noreferrer");
        setOpenTooltip(false);
      }, 2000);
    } else if (sm === "linkedin") {
      handleTooltipOpenWhatsApp();
      setTimeout(() => {
        window.open("https://www.linkedin.com/", "_blank", "noreferrer");
        setOpenTooltip(false);
      }, 2000);
    } else if (sm === "facebook") {
      handleTooltipOpenFacebook();
      setTimeout(() => {
        window.open("https://www.facebook.com/", "_blank", "noreferrer");
        setOpenTooltip(false);
      }, 2000);
    } else if (sm === "twitter") {
      handleTooltipOpenTwitter();
      setTimeout(() => {
        window.open("https://twitter.com/", "_blank", "noreferrer");
        setOpenTooltip(false);
      }, 2000);
    } else if (sm === "email") {
      handleTooltipOpenEmail();
      setTimeout(() => {
        window.open("https://gmail.com/", "_blank", "noreferrer");
        setOpenTooltip(false);
      }, 2000);
    }
    //  else if (sm === "copy") {
    //   handleTooltipOpenCopy();
    // }
  };
  const [openInsta, setOpenInsta] = React.useState(false);
  const [openFacebook, setOpenFacebook] = React.useState(false);
  const [openTwitter, setOpenTwitter] = React.useState(false);
  const [openLinkedin, setOpenLinkedin] = React.useState(false);
  const [opentooltip, setOpenTooltip] = useState(false);
  const [openeventcopiedtooltip, setOpeneventcopiedtooltip] = useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);

  const handleTooltipClose = () => {
    setOpenInsta(false);
    setOpenFacebook(false);
    setOpenLinkedin(false);
    setOpenTwitter(false);
    setOpenEmail(false);
    setOpenTooltip(false);
    // setOpenCopy(false);
  };

  const handleTooltipOpenWhatsApp = () => {
    setOpenLinkedin(true);
    setTimeout(() => {
      setOpenLinkedin(false);
    }, [1000]);
    setOpenTooltip(true);
  };

  const handleTooltipOpenInsta = () => {
    setOpenInsta(true);
    setTimeout(() => {
      setOpenInsta(false);
    }, [1000]);
    setOpenTooltip(true);
  };
  const handleTooltipOpenFacebook = () => {
    setOpenFacebook(true);
    setTimeout(() => {
      setOpenFacebook(false);
    }, [1000]);
    setOpenTooltip(true);
  };
  const handleTooltipOpenTwitter = () => {
    setOpenTwitter(true);
    setTimeout(() => {
      setOpenTwitter(false);
    }, [1000]);
    setOpenTooltip(true);
  };

  const handleTooltipOpenEmail = () => {
    setOpenEmail(true);
    setTimeout(() => {
      setOpenEmail(false);
    }, [1000]);
    setOpenTooltip(true);
  };
  // more event
  const getEventsCard = () => {
    var type = "selectall";
    var API_URL = API_DOMAIN + "event/public?type=" + type;

    axios
      .get(API_URL)
      .then((response) => {
        console.log("all events ", response.data.data.event);
        var res = response.data.data;
        setAllEventData(res.event);
        // setNextToken(res.nexttoken);
        // setHasMoreEvents(res.hasMoreEvents);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    getEventsCard();
    if (sessionStorage.getItem("token") !== null) {
      console.log("in if", sessionStorage.getItem("token"));
      getBookmarkedEvents();
    }
  }, []);
  const loginCheckHandle = (id) => {
    var token = sessionStorage.getItem("token");
    if (token === null || token === undefined || token === "") {
      // console.log("!");
      // toast.info(<>{text_info_please_login}</>);
      setOpenloginInfo(true);
    } else {
      console.log("adasdasd", id);
      navigate("/buy-ticket", {
        state: { event_id: id?.event_unique_id },
      });
    }
  };
  // useEffect(() => {
  //   // === Timer === //
  //   (function () {
  //     const second = 1000,
  //       minute = second * 60,
  //       hour = minute * 60,
  //       day = hour * 24;

  //     //I'm adding this section so I don't have to keep updating this pen every year :-)
  //     //remove this if you don't need it
  //     let today = new Date(),
  //       dd = String(today.getDate()).padStart(2, "0"),
  //       mm = String(today.getMonth() + 1).padStart(2, "0"),
  //       yyyy = today.getFullYear(),
  //       nextYear = yyyy + 1,
  //       dayMonth = "06/01/",
  //       event = dayMonth + yyyy;

  //     today = mm + "/" + dd + "/" + yyyy;
  //     if (today > event) {
  //       event = dayMonth + nextYear;
  //     }
  //     //end

  //     const countDown = new Date(event).getTime(),
  //       x = setInterval(function () {
  //         const now = new Date().getTime(),
  //           distance = countDown - now;

  //         document.getElementById("day").innerText = Math.floor(distance / day);
  //         document.getElementById("hour").innerText = Math.floor(
  //           (distance % day) / hour
  //         );
  //         document.getElementById("minute").innerText = Math.floor(
  //           (distance % hour) / minute
  //         );
  //         document.getElementById("second").innerText = Math.floor(
  //           (distance % minute) / second
  //         );

  //         //do something later when date is reached
  //         if (distance < 0) {
  //           document.getElementById("headline").innerText = "Booking Ends!";
  //           document.getElementById("countdown").style.display = "none";
  //           document.getElementById("content").style.display = "block";
  //           clearInterval(x);
  //         }
  //         //seconds
  //       }, 0);
  //   })();
  // });
  const [bookmaredEventIdList, setBookmaredEventIdList] = useState([]);

  const getBookmarkedEvents = () => {
    var API_URL = API_DOMAIN + `event/get/bookmarks`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        // var res = response?.data?.data?.event;
        console.log("Bookmarked Events", response.data.data);
        setBookmaredEventIdList(response.data.data);
        var res = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const isBookmarked = (eventId) => {
    return bookmaredEventIdList.some(
      (event) => event.event_unique_id === eventId
    );
  };

  const AddBookmarkHandle = (eveID, eveData) => {
    var API_URL = API_DOMAIN + `event/bookmark`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      event_unique_id: eveID,
      seat_unique_id: "seat_unique_id",
      seat_category_id: "seat_category_id",
      isbookmark: true,
      event_details: [eveData],
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.data.message === "Bookmark created") {
          getBookmarkedEvents();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const RemoveBookmarkHandle = (eveID) => {
    var API_URL = API_DOMAIN + `event/uncheck/bookmark`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      event_unique_id: eveID,
      seat_unique_id: "seat_unique_id",
      seat_category_id: "seat_category_id",
      isbookmark: false,
    };
    axios
      .patch(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Remove", response);
        if (response.data.message === "Bookmark updated") {
          getBookmarkedEvents();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_date(
        xpath.select1("/resources/string[@name='text_date']", doc).firstChild
          .data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_exploreevent(
        xpath.select1("/resources/string[@name='text_exploreevent']", doc)
          .firstChild.data
      );
      setText_evedetailview(
        xpath.select1("/resources/string[@name='text_evedetailview']", doc)
          .firstChild.data
      );
      setText_startson(
        xpath.select1("/resources/string[@name='text_startson']", doc)
          .firstChild.data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_share(
        xpath.select1("/resources/string[@name='text_share']", doc).firstChild
          .data
      );
      setText_insta(
        xpath.select1("/resources/string[@name='text_insta']", doc).firstChild
          .data
      );
      setText_facebook(
        xpath.select1("/resources/string[@name='text_facebook']", doc)
          .firstChild.data
      );
      setText_twitter(
        xpath.select1("/resources/string[@name='text_twitter']", doc).firstChild
          .data
      );
      setText_linkedin(
        xpath.select1("/resources/string[@name='text_linkedin']", doc)
          .firstChild.data
      );
      setText_email(
        xpath.select1("/resources/string[@name='text_email']", doc).firstChild
          .data
      );
      setText_aboutthisevent(
        xpath.select1("/resources/string[@name='text_aboutthisevent']", doc)
          .firstChild.data
      );
      setText_eveDetails(
        xpath.select1("/resources/string[@name='text_eveDetails']", doc)
          .firstChild.data
      );
      setText_time(
        xpath.select1("/resources/string[@name='text_time']", doc).firstChild
          .data
      );
      setText_location(
        xpath.select1("/resources/string[@name='text_location']", doc)
          .firstChild.data
      );
      setText_booknow(
        xpath.select1("/resources/string[@name='text_booknow']", doc).firstChild
          .data
      );
      setText_moreevents(
        xpath.select1("/resources/string[@name='text_moreevents']", doc)
          .firstChild.data
      );
      setText_browseall(
        xpath.select1("/resources/string[@name='text_browseall']", doc)
          .firstChild.data
      );
      setText_h(
        xpath.select1("/resources/string[@name='text_h']", doc).firstChild.data
      );
      setText_countdownfinished(
        xpath.select1("/resources/string[@name='text_countdownfinished']", doc)
          .firstChild.data
      );
      setText_copyurl(
        xpath.select1("/resources/string[@name='text_copyurl']", doc).firstChild
          .data
      );
      setText_okay(
        xpath.select1("/resources/string[@name='text_okay']", doc).firstChild
          .data
      );
      setText_m(
        xpath.select1("/resources/string[@name='text_h']", doc).firstChild.data
      );
      setText_d(
        xpath.select1("/resources/string[@name='text_h']", doc).firstChild.data
      );
      setText_info_please_login(
        xpath.select1("/resources/string[@name='text_info_please_login']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />

      <div className="eventdetail-wrapper eventdetail-nightmod" id="otherPages">
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/all-event">{text_exploreevent}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_evedetailview}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {Data.map((v) => {
          return (
            <>
              <div className="event-dt-block p-80">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="event-top-dts">
                        <div className="event-top-date">
                          <span className="event-month">
                            {moment.utc(v?.StartDate).format("MMM")}
                          </span>
                          <span className="event-date">
                            {moment.utc(v?.StartDate).format("DD")}
                          </span>
                        </div>
                        <div className="event-top-dt">
                          <h3 className="event-main-title">{v.EventTitle}</h3>
                          <div className="event-top-info-status">
                            <span className="event-type-name">
                              {v.EventType}
                            </span>
                            <span className="event-type-name details-hr">
                              {text_startson}{" "}
                              <span className="ev-event-date">
                                {/* Wed, Jun 01, 2022 5:30 AM */}
                                {moment.utc(v.StartDate).format(
                                  "ddd, MMM DD, yyyy"
                                )}{" "}
                                {moment.utc(v.StartTime).format("hh:mm a")}
                              </span>
                            </span>
                            <span className="event-type-name details-hr">
                              {new Date(moment.utc(v.EndTime)).getHours() -
                                new Date(
                                  moment.utc(v.StartTime)
                                ).getHours()}{" "}
                              {text_h}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-7 col-md-12">
                      <div className="main-event-dt">
                        <div className="event-img">
                          <img
                            src={
                              v?.EventImagePath === "path"
                                ? img1
                                : v?.EventImagePath
                            }
                            alt=""
                            className="eventdetail-imghight"
                          />
                        </div>
                        <div className="share-save-btns dropdown">
                          <button
                            className="sv-btn me-2"
                            onClick={() => [setIndexBookmark(!indexBookmark)]}
                          >
                            {isBookmarked(v.event_unique_id) ? (
                              <>
                                <FaBookmark
                                  className="bookmarkicon-fill"
                                  onClick={() =>
                                    RemoveBookmarkHandle(v?.event_unique_id)
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <FiBookmark
                                  className="bookmarkicon"
                                  onClick={() =>
                                    AddBookmarkHandle(v?.event_unique_id, v)
                                  }
                                />
                              </>
                            )}
                            {text_save}
                          </button>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            arrow
                            open={opentooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Copied!"
                            onClose={() => setOpenTooltip(false)}
                          >
                            <button
                              className="sv-btn me-2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <AiOutlineShareAlt /> {text_share}
                            </button>
                          </Tooltip>

                          <ul className="dropdown-menu">
                            <li
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={openInsta}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              disableInteractive
                              arrow
                            >
                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={() => handleCopyUrl("instagram")}
                              >
                                <FaInstagram /> {text_insta}
                              </Link>
                            </li>
                            <li
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={openFacebook}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              disableInteractive
                              arrow
                            >
                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={() => handleCopyUrl("facebook")}
                              >
                                <FaFacebook /> {text_facebook}
                              </Link>
                            </li>
                            <li
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={openTwitter}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              disableInteractive
                              arrow
                            >
                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={() => handleCopyUrl("twitter")}
                              >
                                <FaTwitter /> {text_twitter}
                              </Link>
                            </li>
                            <li
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={openLinkedin}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              disableInteractive
                              arrow
                            >
                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={() => handleCopyUrl("linkedin")}
                              >
                                <FaLinkedinIn /> {text_linkedin}
                              </Link>
                            </li>
                            <li
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={openEmail}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              disableInteractive
                              arrow
                            >
                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={() => handleCopyUrl("email")}
                              >
                                <MdOutlineMail /> {text_email}
                              </Link>
                            </li>
                          </ul>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            arrow
                            open={openeventcopiedtooltip}
                            onClose={() => setOpeneventcopiedtooltip(false)}
                            title="Copied!"
                          >
                            <button
                              className="sv-btn p-2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{ width: "max-content" }}
                              onClick={() => [
                                setOpeneventcopiedtooltip(true),
                                navigator.clipboard.writeText(
                                  window.location.href
                                ),
                              ]}
                            >
                              <MdFileCopy /> {text_copyurl}
                            </button>
                          </Tooltip>
                        </div>
                        <div className="main-event-content">
                          <h4>{text_aboutthisevent}</h4>
                          <p>{v.EventDescription}</p>
                          {/* <p>
                      In malesuada luctus libero sed gravida. Suspendisse nunc
                      est, maximus vel viverra nec, suscipit non massa. Maecenas
                      efficitur vestibulum pellentesque. Ut finibus ullamcorper
                      congue. Sed ut libero sit amet lorem venenatis facilisis.
                      Mauris egestas tortor vel massa auctor, eget gravida
                      mauris cursus. Etiam elementum semper fermentum.
                      Suspendisse potenti. Morbi lobortis leo urna, non laoreet
                      enim ultricies id. Integer id felis nec sapien consectetur
                      porttitor. Proin tempor mauris in odio iaculis semper.
                      Cras ultricies nulla et dui viverra, eu convallis orci
                      fermentum.
                    </p> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-md-12">
                      <div className="main-card event-right-dt">
                        <div className="bp-title">
                          <h4>{text_eveDetails}</h4>
                        </div>
                        <div className="time-left">
                          <div className="countdown">
                            <div className="countdown-item countdownSty">
                              <span id="day" style={{ color: "white" }}>
                                {" "}
                                <Countdown targetDate={v.StartDate} />
                              </span>
                            </div>
                            {/* <div className="countdown-item">
                              <span id="hour">8</span>Hours
                            </div>
                            <div className="countdown-item">
                              <span id="minute">22</span>Minutes
                            </div>
                            <div className="countdown-item">
                              <span id="second">9</span>Seconds
                            </div> */}
                          </div>
                        </div>
                        {/* <div className="event-dt-right-group mt-5">
                          <div className="event-dt-right-icon">
                            <HiUserCircle />
                          </div>
                          <div className="event-dt-right-content">
                            <h4>Organised by</h4>
                            <h5>Story Tellers</h5>
                            <Link to="/profile" className="event-linkstye">
                              View Profile
                            </Link>
                          </div>
                        </div> */}

                        <div className="event-dt-right-group mt-5">
                          {" "}
                          {/*Remove mt-5 while eableing Organised By */}
                          <div className="event-dt-right-icon">
                            <MdDateRange />
                          </div>
                          <div className="event-dt-right-content">
                            <h4>{text_date}</h4>
                            <h5>
                              {moment.utc(v.StartDate).format("ddd, MMM DD, yyyy")}{" "}
                              - {moment.utc(v.EndDate).format("ddd, MMM DD, yyyy")}
                            </h5>
                            {/* <div className="add-to-calendar">
                              <Link
                                to="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                className="event-linkstye "
                              >
                                <FaCalendarAlt /> Add to Calendar
                              </Link>
                              <ul className="dropdown-menu">
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    <i className="fa-brands fa-windows me-3"></i>
                                    Outlook
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    <i className="fa-brands fa-apple me-3"></i>
                                    Apple
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    <i className="fa-brands fa-google me-3"></i>
                                    Google
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    <i className="fa-brands fa-yahoo me-3"></i>
                                    Yahoo
                                  </Link>
                                </li>
                              </ul>
                            </div> */}
                          </div>
                        </div>
                        <div className="event-dt-right-group">
                          <div className="event-dt-right-icon">
                            <IoTime />
                          </div>
                          <div className="event-dt-right-content">
                            <h4>{text_time}</h4>
                            <h5 className="mb-0">
                              {moment.utc(v.StartTime).format("hh:mm A")} -{" "}
                              {moment.utc(v.EndTime).format("hh:mm A")}
                            </h5>
                          </div>
                        </div>
                        <div className="event-dt-right-group">
                          <div className="event-dt-right-icon">
                            <IoLocationSharp />
                          </div>
                          <div className="event-dt-right-content">
                            <h4>{text_location}</h4>
                            <h5 className="mb-0">
                              {v?.venuedetails[0].venuename} ({" "}
                              {v?.venuedetails[0].address},{" "}
                              {v?.venuedetails[0].city},{" "}
                              {v?.venuedetails[0].state},{" "}
                              {v?.venuedetails[0].country},{" "}
                              {v?.venuedetails[0].zipcode})
                            </h5>
                          </div>
                        </div>
                        {/* <div className="event-dt-right-group">
                          <div className="event-dt-right-icon">
                            <FaMoneyCheckDollar />
                          </div>
                          <div className="event-dt-right-content">
                            <h4>AUD</h4>
                            <h5 className="mb-0">$50.00</h5>
                          </div>
                        </div> */}
                        {/* for start venue event details */}
                        {/* <div className="select-tickets-block">
                    <h6>Select Tickets</h6>
                    <div className="select-ticket-action">
                      <div className="ticket-price">AUD $75.00</div>
                      <div className="quantity">
                        <div className="counter">
                          <span
                            className="down"
                            // onClick="decreaseCount(event, this)"
                          >
                            -
                          </span>
                          <input type="text" value="0" />
                          <span className="up" 
						//   onClick="increaseCount(event, this)"
						  >
                            +
                          </span>
                        </div>
                      </div>
                    </div>
                    <p>
                      2 x pair hand painted leather earrings 1 x glass of
                      bubbles / or coffee Individual grazing box / fruit cup
                    </p>
                    <div className="xtotel-tickets-count">
                      <div className="x-title">1x Ticket(s)</div>
                      <h4>
                        AUD <span>$0.00</span>
                      </h4>
                    </div>
                  </div> */}
                        {/* for end venue event details */}

                        <div className="booking-btn">
                          <button
                            onClick={() => loginCheckHandle(v)}
                            className="main-btn btn-hover w-100"
                          >
                            {text_booknow}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <center>
                      <div className="col-xl-8 col-lg-8 col-md-12 mt-3">
                        <div className=" p-4">
                          <div className="row ">
                            <div className="tablescroll">
                              <table className="TableSty">
                                <tr className="text-center">
                                  <th className="TableHeadSty">
                                    {text_ticketType}
                                  </th>
                                  <th className="TableHeadSty">
                                    {text_price_ticket}
                                  </th>
                                  {v?.showAvailableTicket === true ? (
                                    <>
                                      <th className="TableHeadSty">
                                        {text_availableTicket}
                                      </th>
                                    </>
                                  ) : (
                                    <></>
                                  )}{" "}
                                </tr>
                                {tableData.map((data, index) => {
                                  return (
                                    <>
                                      <tr className="text-center">
                                       
                                        <td className="TableBodySty">
                                          {data.ticket_category}
                                        </td>
                                        <td className="TableBodySty">
                                          {v.sell_currency +
                                            " " +
                                            Number(data.ticket_price)
                                              .toFixed(2)
                                              .replace(
                                                /\d(?=(\d{3})+\.)/g,
                                                "$&,"
                                              )}
                                        </td>
                                        {v?.showAvailableTicket === true ? (
                                          <>
                                            <td className="TableBodySty">
                                              {data.no_tickets -
                                                data.ticketsold}
                                            </td>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </tr>
                                    </>
                                  );
                                })}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </center> */}
                    <div className="col-xl-12 col-lg-12 col-md-12 ">
                      <div className="more-events">
                        <div className="main-title position-relative">
                          <h3>{text_moreevents}</h3>
                          <Link to="/all-event" className="view-all-link">
                            {text_browseall} <FaLongArrowAltRight />
                          </Link>
                        </div>
                        <div className="d-flex gap-3 owl-carousel moreEvents-slider owl-theme"></div>
                        <OwlCarousel className="owl-theme" loop margin={10} nav>
                          {allEventData.map((e, index) => {
                            return (
                              <>
                                <div
                                  className="item"
                                  id={index}
                                  onClick={() =>
                                    navigate(
                                      `/event-details/${e?.event_unique_id}`
                                    )
                                  }
                                >
                                  <div className="item">
                                    <div className="main-card mt-4">
                                      <div className="event-thumbnail">
                                        <Link to="#" className="thumbnail-img">
                                          <img
                                            src={
                                              e.EventImagePath === "path"
                                                ? img1
                                                : e.EventImagePath
                                            }
                                            alt=""
                                          />
                                        </Link>
                                        <span
                                          className="bookmark-icon bookmark-content"
                                          title=""
                                          onClick={() => [
                                            setIndexBookmark(index),
                                          ]}
                                        >
                                          {isBookmarked(e.event_unique_id) ? (
                                            <>
                                              <FaBookmark
                                                className="bookmarkicon-fill"
                                                onClick={() =>
                                                  RemoveBookmarkHandle(
                                                    e?.event_unique_id
                                                  )
                                                }
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <FiBookmark
                                                className="bookmarkicon"
                                                onClick={() =>
                                                  AddBookmarkHandle(
                                                    e?.event_unique_id,
                                                    e
                                                  )
                                                }
                                              />
                                            </>
                                          )}
                                        </span>
                                      </div>
                                      <div className="event-content">
                                        <Link to="#" className="event-title">
                                          {e.EventTitle}
                                        </Link>
                                        <div className="duration-price-remaining">
                                          <span className="duration-price">
                                            {e?.venuedetails[0].venuename} ({" "}
                                            {e?.venuedetails[0].address},{" "}
                                            {e?.venuedetails[0].city},{" "}
                                            {e?.venuedetails[0].state},{" "}
                                            {e?.venuedetails[0].country},{" "}
                                            {e?.venuedetails[0].zipcode})
                                          </span>
                                          <span className="remaining"></span>
                                        </div>
                                      </div>
                                      <div className="event-footer">
                                        <div className="event-timing">
                                          <div className="publish-date">
                                            <span>
                                              <MdDateRange />{" "}
                                              {moment.utc(e?.StartDate).format(
                                                "DD MMM,yyyy"
                                              ) ===
                                              moment.utc(e?.EndDate).format(
                                                "DD MMM,yyyy"
                                              ) ? (
                                                <>
                                                  {" "}
                                                  {moment.utc(e?.StartDate).format(
                                                    "DD MMM"
                                                  )}
                                                  &nbsp;
                                                </>
                                              ) : (
                                                <>
                                                  {" "}
                                                  {moment.utc(e?.StartDate).format(
                                                    "DD MMM"
                                                  )}{" "}
                                                  -{" "}
                                                  {moment.utc(e?.EndDate).format(
                                                    "DD MMM"
                                                  )}{" "}
                                                  &nbsp;
                                                </>
                                              )}{" "}
                                            </span>{" "}
                                            <span className="dot">
                                              <GoDotFill />
                                            </span>{" "}
                                            <span>
                                              {" "}
                                              {moment.utc(e.StartDate).format(
                                                "ddd"
                                              )}
                                              ,{" "}
                                              {moment
                                                .utc(e?.StartTime)
                                                .format("hh:mm a")}{" "}
                                            </span>
                                          </div>
                                          <span className="publish-time">
                                            <IoTime />{" "}
                                            {new Date(
                                              moment.utc(v.EndTime)
                                            ).getHours() -
                                              new Date(
                                                moment.utc(v.StartTime)
                                              ).getHours()}{" "}
                                            {text_h}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
        <br />
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={openloginInfo}
        onClose={handleCloseloginInfo}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <BiLogIn className="Email-icon hometextnight" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3 className="text-center hometextnight">
              {text_info_please_login}
            </h3>
            {/* <h5 className="text-center">
              We have sent you a link on your registered email or phone no. to
              verify your Account.
            </h5>
            <h5 className="text-center">
              Please check your Spam or Junk folder.
            </h5> */}
            <br />
            {/* <center>
              <Link onClick={() => []} style={{ cursor: "pointer" }}>
                <u>Resend verification link</u>
              </Link>
            </center> */}
          </DialogContentText>
          <Divider />
        </DialogContent>
        <>
          <div className="d-flex justify-content-center mb-3">
            <button
              className="main-btn btn-hover "
              onClick={handleCloseloginInfo}
            >
              {text_okay}
            </button>
          </div>
        </>
      </Dialog>
      <Footer />
    </>
  );
}

export default EventDetails;
