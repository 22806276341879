import React, { useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaCut } from "react-icons/fa";
import mainlogo from "../../assets/img/tiq8-Logo-white.png";
import QRCode from "react-qr-code";
import moment from "moment";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import { FcHome } from "react-icons/fc";
import { useReactToPrint } from "react-to-print";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { toast } from "react-toastify";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function InvoiceProfile() {
  const [text_seatname, setText_seatname] = useState("Seat Name");
  const [text_invoice, setText_invoice] = useState("Invoice");
  const [text_invoiceto, setText_invoiceto] = useState("Invoice to");
  const [text_download, setText_download] = useState("Download");
  const [text_backtohome, setText_backtohome] = useState("Back to home");
  const [text_powerBy, setText_powerBy] = useState("Powered by TiQ8");
  const [text_eveDetails, setText_eveDetails] = useState("Event Details");
  const [text_type, setText_type] = useState("Type");

  const [text_qty, setText_qty] = useState("Qty");

  const componentRef = useRef();
  const resellEveDetail = JSON.parse(sessionStorage.getItem("resellEveDetail"));
  const ownerDetails = JSON.parse(sessionStorage.getItem("ownerDetails"));
  console.log(ownerDetails);
  const resellTicketDetail = JSON.parse(
    sessionStorage.getItem("resellTicketDetail")
  );
  const navigate = useNavigate();
  const data = useLocation();
  const TicketData = data.state !== null ? data.state.profile_ticket_data : 0;
  console.log(TicketData);
  const [AttenendingEventData, setAttendingEventData] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [back, setBack] = useState("#FFFFFF");
  const [fore, setFore] = useState("#000000");
  const [size, setSize] = useState(128);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getPurchaseCard = () => {
    var API_URL = API_DOMAIN + "ticket/mybought";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var data = response?.data?.data;
        var res = data?.tickets;
        console.log(res);

        const filteredData = res.filter(
          (item) => item.event_unique_id === TicketData
        );
        console.log(filteredData[0]?.eventsdetails);

        setAttendingEventData(filteredData);
        setEventDetails(filteredData[0]?.eventsdetails);
      })
      .catch(function (error) {
        console.log(error);
        // errorToast(err);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          });
        } else {
          //   if (totalAttendingTicket !== "") {
          toast.error(error.response.data.message);
          //   }
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getPurchaseCard();
  }, []);
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_seatname(
        xpath.select1("/resources/string[@name='text_seatname']", doc)
          .firstChild.data
      );
      setText_invoice(
        xpath.select1("/resources/string[@name='text_invoice']", doc).firstChild
          .data
      );
      setText_invoiceto(
        xpath.select1("/resources/string[@name='text_invoiceto']", doc)
          .firstChild.data
      );
      setText_download(
        xpath.select1("/resources/string[@name='text_download']", doc)
          .firstChild.data
      );
      setText_backtohome(
        xpath.select1("/resources/string[@name='text_backtohome']", doc)
          .firstChild.data
      );
      setText_powerBy(
        xpath.select1("/resources/string[@name='text_powerBy']", doc).firstChild
          .data
      );
      setText_eveDetails(
        xpath.select1("/resources/string[@name='text_eveDetails']", doc)
          .firstChild.data
      );
      setText_type(
        xpath.select1("/resources/string[@name='text_type']", doc).firstChild
          .data
      );
      setText_qty(
        xpath.select1("/resources/string[@name='text_qty']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="invoice clearfix p-80 mt-5">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="d-flex justify-content-center gap-2">
              <center>
                <Link to="/" className="main-btn btn-hover h_40 mb-3 ">
                  <FcHome className="homeicon" /> {text_backtohome}
                </Link>
              </center>
              <center>
                <Link
                  className="main-btn btn-hover h_40 mb-3"
                  onClick={handlePrint}
                >
                  {text_download}
                </Link>
              </center>
            </div>

            <div className="col-lg-8 col-md-10" ref={componentRef}>
              <div className="invoice-header justify-content-between">
                <div className="invoice-header-logo">
                  <img src={mainlogo} alt="invoice-logo" />
                </div>
              </div>
              <div className="invoice-body">
                <div className="invoice_dts">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="invoice_title">{text_invoice}</h2>
                    </div>
                    <div className="col-md-6">
                      <div className="vhls140">
                        <ul>
                          <li>
                            <div className="vdt-list">
                              {text_invoiceto}{" "}
                              {sessionStorage.getItem("userfirstname")}{" "}
                              {sessionStorage.getItem("userlastname")}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="vhls140">
                        <ul>
                          <li>
                            {/* <div className="vdt-list">Invoice ID :</div> */}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-table bt_40">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">{text_eveDetails}</th>
                          <th scope="col">{text_type}</th>
                          <th scope="col">{text_qty}</th>
                          {/* <th scope="col">Unit Price</th>
                          <th scope="col">Total</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {eventDetails.map((v) => {
                          return (
                            <>
                              <tr>
                                <td>1</td>
                                <td>
                                  <Link
                                    to="#"
                                    target=""
                                    className="invoice-link-sty showpassnight"
                                  >
                                    {v?.EventTitle}
                                  </Link>
                                </td>
                                <td>{v?.EventType}</td>
                                <td>{AttenendingEventData.length}</td>
                                {/* <td>
                                  {" "}
                                  {v?.sell_currency}{" "}
                                  {Number(10)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </td>
                                <td>
                                  {" "}
                                  {v?.eventsdetails[0]?.sell_currency}{" "}
                                  {Number(v.seat_price)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </td> */}
                              </tr>

                              <tr>
                                <td colspan="1"></td>
                                <td colspan="5">
                                  <div className="user_dt_trans text-end pe-xl-4">
                                    <div className="totalinv2">
                                      {/* Invoice Total : USD */}
                                      {/* {v?.eventsdetails[0]?.sell_currency}{" "}
                                      {Number(v.seat_price)
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")} */}
                                    </div>
                                    {/* <p>Paid via Paypal</p> */}
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                {AttenendingEventData.map((v) => {
                  return (
                    <>
                      <div className="invoice_footer">
                        <div className="cut-line">
                          <i className="fa-solid fa-scissors">
                            <FaCut />
                          </i>
                        </div>

                        <div className="main-card">
                          <div className="row g-0">
                            <div className="col-lg-7">
                              <div className="event-order-dt p-4">
                                <div className="event-thumbnail-img">
                                  <img
                                    src={
                                      eventDetails[0].EventImagePath === "path"
                                        ? img1
                                        : eventDetails[0].EventImagePath
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="event-order-dt-content">
                                  <h5>{eventDetails[0].EventTitle}</h5>
                                  {/* <span>Wed, Jun 01, 2022 5:30 AM. Duration 1h</span> */}
                                  <span>
                                    {" "}
                                    {moment.utc(eventDetails[0].StartDate).format(
                                      "DD MMM,yyyy"
                                    ) ===
                                    moment.utc(eventDetails[0].EndDate).format(
                                      "DD MMM,yyyy"
                                    ) ? (
                                      <>
                                        {" "}
                                        {moment.utc(
                                          eventDetails[0].StartDate
                                        ).format("ddd, DD MMM, yyyy")}{" "}
                                        {moment
                                          .utc(eventDetails[0].StartTime)
                                          .format("hh:mm a")}
                                        &nbsp;.
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        {moment.utc(
                                          eventDetails[0].StartDate
                                        ).format("ddd, DD MMM, yyyy")}
                                        ,{" "}
                                        {moment
                                          .utc(eventDetails[0].StartTime)
                                          .format("hh:mm a")}{" "}
                                        -{" "}
                                        {moment.utc(eventDetails[0].EndDate).format(
                                          "ddd, DD MMM, yyyy"
                                        )}
                                        ,{" "}
                                        {moment
                                          .utc(eventDetails[0].EndTime)
                                          .format("hh:mm a")}{" "}
                                        &nbsp;. Duration{" "}
                                        {new Date(
                                          moment.utc(eventDetails[0].EndTime)
                                        ).getHours() -
                                          new Date(
                                            moment.utc(
                                              eventDetails[0].StartTime
                                            )
                                          ).getHours()}{" "}
                                        h
                                      </>
                                    )}
                                  </span>
                                  <div className="buyer-name">
                                    {" "}
                                    {v?.ownername}
                                  </div>
                                  {/* <div className="booking-total-tickets">
                              <i className="fa-solid fa-ticket rotate-icon"></i>
                              <span className="booking-count-tickets mx-2">
                                1
                              </span>
                              x Ticket
                            </div> */}
                                  <div className="booking-total-grand">
                                    Total :{" "}
                                    <span>
                                      {eventDetails[0].sell_currency}{" "}
                                      {Number(v?.seat_price)
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="QR-dt p-4">
                                <ul className="QR-counter-type">
                                  <li></li>
                                  <li>{text_seatname}</li>
                                  <li>{v?.seat_name}</li>
                                </ul>
                                <div className="QR-scanner">
                                  <QRCode
                                    value={
                                      "Event Name:--" +
                                      eventDetails[0].EventTitle +
                                      "Seat:--" +
                                      v?.seat_name +
                                      "Start Date:--" +
                                      moment.utc(eventDetails[0].StartDate).format(
                                        "ddd, DD MMM, yyyy"
                                      ) +
                                      "Start Time:--" +
                                      moment
                                        .utc(eventDetails[0].StartTime)
                                        .format("hh:mm a")
                                    }
                                    bgColor={back}
                                    fgColor={fore}
                                    size={size === "" ? 0 : size}
                                  />
                                  {/* <img src="images/qr.png" alt="QR-Ticket-Scanner"/> */}
                                </div>
                                <p>{text_powerBy}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="cut-line">
                          <i className="fa-solid fa-scissors">
                            <FaCut />
                          </i>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default InvoiceProfile;
