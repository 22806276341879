import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import OrgDashboardSidebar from "./OrgDashboardSidebar";
import { FaEye, FaLink } from "react-icons/fa";
import { MdFileCopy } from "react-icons/md";
import { IoIosCloseCircleOutline, IoIosSettings } from "react-icons/io";
import Loader from "../../utils/Loader";
import { Box, Divider, Modal, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { toast } from "react-toastify";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function OrgLandingPageInfo() {
  const [showEditForm, setShowEditForm] = useState(false);
  const userid = sessionStorage.getItem("userid");

  const navigate = useNavigate();
  const [openImageUploadPopup, setOpenImageUploadPopup] = useState(false);
  const handleOpenImagePopup = () => setOpenImageUploadPopup(true);
  const handleCloseImagePopup = () => setOpenImageUploadPopup(false);
  const [Loading, setLoading] = useState(false);
  const [opentooltip, setOpenTooltip] = useState(false);
  const [landingImage, setLandingImage] = useState("");

  const styleTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_save, setText_save] = useState("Save");
  const [text_upload, setText_upload] = useState("Upload");
  const [text_upload_image, setText_upload_image] = useState("Upload Image");
  const [text_update, setText_update] = useState("Update");
  const [text_description, setText_description] = useState("Description");
  const [text_enterdesc, setText_enterdesc] = useState("Enter Description");
  const [text_entertitle, setText_entertitle] = useState("Enter Title");
  const [text_title, setText_title] = useState("Title");
  const [text_logo, setText_logo] = useState("Logo");
  const [text_customize, setText_customize] = useState("Customize");
  const [text_landingpage, setText_landingpage] = useState("Landing Page");
  const [text_view, setText_view] = useState("View");
  const [text_copyurl1, setText_copyurl1] = useState("Copy URL");
  const [text_info, setText_info] = useState("Info");

  const imguplpoadValidation = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setLandingImage(file);
        // You can perform further actions with the selected file here
      } else {
        setLandingImage(null);
        alert("Please upload only JPEG or PNG files.");
      }
    }
  };
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [hasLandingInfo, setHasLandingInfo] = useState(false);
  const handleEveImgUpload = () => {
    setLoading(true);
    var API_URL = API_DOMAIN + "file/upload";
    var imgdata = new FormData();
    imgdata.append("att_file", landingImage);
    let headerConfig = {
      headers: {
        "Content-Type": "form-data",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .post(API_URL, imgdata, headerConfig)
      .then(function (response) {
        console.log("Img Upload", response);
        if (response.status === 200) {
          setLoading(false);
          setLogoUrl(response.data.data.Location);
          handleCloseImagePopup();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const landingInfoHandle = () => {
    var API_URL = API_DOMAIN + `landingpage/create`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let data = {
      title: title,
      description: description,
      logourl: logoUrl,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Create Landing Info", response);
        GetlandingInfo();
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const GetlandingInfo = () => {
    var API_URL =
      API_DOMAIN + `landingpage/select?uid=${sessionStorage.getItem("userid")}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response.data.data;
        console.log("Landing Info ", res, res.length);
        if (res.length > 0) {
          setHasLandingInfo(true);
          setTitle(res[0].title);
          setDescription(res[0].description);
          setLogoUrl(res[0].logourl);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    GetlandingInfo();
  }, []);
  const updateLandingInfoHandle = () => {
    var API_URL = API_DOMAIN + `landingpage/update`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let data = {
      title: title,
      description: description,
      logourl: logoUrl,
    };
    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Update Landing Info", response);
        GetlandingInfo();
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_upload(
        xpath.select1("/resources/string[@name='text_upload']", doc).firstChild
          .data
      );
      setText_upload_image(
        xpath.select1("/resources/string[@name='text_upload_image']", doc)
          .firstChild.data
      );
      setText_update(
        xpath.select1("/resources/string[@name='text_update']", doc).firstChild
          .data
      );
      setText_description(
        xpath.select1("/resources/string[@name='text_description']", doc)
          .firstChild.data
      );
      setText_enterdesc(
        xpath.select1("/resources/string[@name='text_description']", doc)
          .firstChild.data
      );
      setText_entertitle(
        xpath.select1("/resources/string[@name='text_entertitle']", doc)
          .firstChild.data
      );
      setText_title(
        xpath.select1("/resources/string[@name='text_title']", doc).firstChild
          .data
      );
      setText_logo(
        xpath.select1("/resources/string[@name='text_logo']", doc).firstChild
          .data
      );
      setText_customize(
        xpath.select1("/resources/string[@name='text_customize']", doc)
          .firstChild.data
      );
      setText_landingpage(
        xpath.select1("/resources/string[@name='text_landingpage']", doc)
          .firstChild.data
      );
      setText_view(
        xpath.select1("/resources/string[@name='text_view']", doc).firstChild
          .data
      );
      setText_copyurl1(
        xpath.select1("/resources/string[@name='text_copyurl1']", doc).firstChild
          .data
      );
      setText_info(
        xpath.select1("/resources/string[@name='text_info']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <OrgDashboardSidebar />
      <div id="otherPages">
        <div className="wrapper wrapper-body" id="wrapper-body">
          <div className="dashboard-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-main-title">
                    <h3>
                      <FaLink className="me-3" />
                      {text_landingpage} {text_info}
                    </h3>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="main-card mt-5 p-4">
                    <div className="conversion-setup">
                      <div className="main-card">
                        <div className="contact-list">
                          <div className="top d-flex flex-wrap justify-content-between align-items-center p-4 border_bottom">
                            <div className="icon-box">
                              <span className="icon-big icon">
                                <i className="fa-solid fa-chart-column"></i>
                              </span>
                              <h5 className="font-18 mb-1 mt-1 f-weight-medium">
                                {sessionStorage.getItem("userfirstname")} {""}
                                {sessionStorage.getItem("userlastname")}
                              </h5>
                              <p className="text-gray-50 m-0">
                                <span className="visitor-date-time">
                                  https://www.tick8.online/landingpage?cid=
                                  {userid}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="bottom d-flex flex-wrap justify-content-between align-items-center gap-3 p-4">
                            <Tooltip
                              arrow
                              open={opentooltip}
                              title="Copied!"
                              onClose={() => setOpenTooltip(false)}
                            >
                              {" "}
                              <button
                                className="sv-btn "
                                onClick={() => [
                                  navigator.clipboard.writeText(
                                    `https://www.tick8.online/landingpage?cid=${userid}`
                                  ),
                                  setOpenTooltip(true),
                                ]}
                              >
                                {" "}
                                {/* // https://www.tiq8.com/landingpage?cid=6543348a5a5f6ddb0f89b9ef */}
                                <MdFileCopy className="icon me-2" />
                                {text_copyurl1}
                              </button>{" "}
                            </Tooltip>
                            <button
                              className="sv-btn p-2 "
                              style={{ width: "max-content" }}
                              onClick={() =>
                                navigate(`/landingpage?cid=${userid}`)
                              }
                            >
                              <FaEye className="icon me-2" />
                              {text_view} {text_landingpage}
                            </button>
                            <button
                              className="sv-btn "
                              onClick={() => setShowEditForm(true)}
                            >
                              <IoIosSettings className="icon me-2" />
                              {text_customize}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {showEditForm === true ? (
                <>
                  <div className="main-card mt-2">
                    <div className="p-4 bp-form main-form">
                      <div className="row p-4">
                        <div className="col-lg-4 col-md-12">
                          <div className="form-group border_bottom-create profile-night-form pb_30">
                            <label className="form-label fs-16">
                              {text_logo}
                            </label>
                            <button
                              className="btn btn-default btn-hover steps_btn mt-2"
                              onClick={() => handleOpenImagePopup()}
                            >
                              {hasLandingInfo === true ? (
                                <>{text_update}</>
                              ) : (
                                <>{text_upload}</>
                              )}
                            </button>
                            {logoUrl !== "" ? (
                              <>
                                <img
                                  src={logoUrl}
                                  alt=""
                                  className="eventImg"
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="form-group border_bottom-create profile-night-form pb_30">
                            <label className="form-label fs-16">
                              {text_title}
                              {/* <span className="starsty">*</span> */}
                            </label>
                            <input
                              className="form-control h_50 inputstylenightmode"
                              type="text"
                              placeholder={text_entertitle}
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="form-group border_bottom-create profile-night-form pb_30">
                            <label className="form-label fs-16">
                              {text_description}
                              {/* <span className="starsty">*</span> */}
                            </label>
                            <input
                              className="form-control h_50 inputstylenightmode"
                              type="text"
                              placeholder={text_enterdesc}
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center gap-4 mt-5">
                        <button
                          className="cancelBtn"
                          onClick={() => setShowEditForm(false)}
                        >
                          {text_cancel}
                        </button>
                        {hasLandingInfo === true ? (
                          <>
                            <button
                              className="saveBtn"
                              onClick={updateLandingInfoHandle}
                            >
                              {text_update}
                            </button>
                          </>
                        ) : (
                          <>
                            {" "}
                            <button
                              className="saveBtn"
                              onClick={landingInfoHandle}
                            >
                              {text_save}
                            </button>
                          </>
                        )}
                      </div>
                      <br />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openImageUploadPopup}
        onClose={handleCloseImagePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue"
                onClick={() => [handleCloseImagePopup()]}
              />
            </div>
            <h3 className="popup-main-title">{text_upload_image}</h3>
            <Divider />
            {Loading === true ? (
              <>
                <center className=" mt-3 mb-3">
                  <Loader />
                </center>
              </>
            ) : (
              <>
                <div className="container">
                  <div className="text-center form-group mt-3">
                    <input
                      type="file"
                      className="form-control h_50 uploadinput landinginputnight"
                      onChange={imguplpoadValidation}
                      //   onChange={(e) => [
                      //     setEventImage(e.target.files),
                      //     console.log(e.target.files),
                      //   ]}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <button
                    onClick={() => handleCloseImagePopup()}
                    className="cancelBtn"
                  >
                    {" "}
                    {text_cancel}
                  </button>

                  <button className="resell-Btn" onClick={handleEveImgUpload}>
                    {" "}
                    {text_save}
                  </button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default OrgLandingPageInfo;
