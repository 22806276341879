import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
function AuthGard(props) {
  const { Component } = props;
  const navigate = useNavigate();
  useEffect(() => {
    let token = sessionStorage.getItem("token");
    if (token === null || token === "undefined" || token === "") {
      navigate("/");
      sessionStorage.clear();
      localStorage.clear();
    }
  });
  return <Component />;
}

export default AuthGard;
