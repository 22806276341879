import React from "react";
import "../FAQ/FAQ.css";

function FAQ() {
  return (
    <>
    <div id="otherPages">
      <h1>FAQ</h1>
      </div>
    </>
  );
}

export default FAQ;
