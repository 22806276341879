import React, { useState, useEffect } from "react";
import "../ContactUs/ContactUs.css";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { MdEmail } from "react-icons/md";
import { IoIosHelpBuoy } from "react-icons/io";
import { FaSquarePhone } from "react-icons/fa6";
import PhoneInput from "react-phone-input-2";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { EMAIL_REGEX } from "../../utils/Validation";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function ContactUs() {
  const navigate = useNavigate();
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [Message, setMessage] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [messageErr, setMessageErr] = useState("");
  const [text_helpcenter, setText_helpcenter] = useState("Help Center");
  const [text_contactinfo, setText_contactinfo] = useState(
    "Contact information"
  );
  const [text_submit, setText_submit] = useState("Submit");
  const [text_message, setText_message] = useState("Message");
  const [text_phone, setText_phone] = useState("Phone");
  const [text_ph_email, setText_ph_email] = useState("Enter Email");
  const [text_email, setText_email] = useState("Email");
  const [text_ph_lastName, setText_ph_lastName] = useState("Enter Last Name");
  const [text_ph_firstName, setText_ph_firstName] =
    useState("Enter First Name");
  const [text_lastname, setText_lastname] = useState("Last Name");
  const [text_firstname, setText_firstname] = useState("First Name");
  const [text_noticecontactus, setText_noticecontactus] = useState(
    "Have any questions? We'd love to hear from you."
  );
  const [text_contactus, setText_contactus] = useState("Contact Us");
  const [text_home, setText_home] = useState("Home");
  const [text_err_email, setText_err_email] = useState("Please Enter Email");
  const [text_err_valid_email, setText_err_valid_email] = useState(
    "Please Enter Valid Email"
  );
  const [text_entermessage, setText_entermessage] = useState(
    "Please Enter Message"
  );

  const ContactValidationHandler = () => {
    var isValidcontact = true;

    if (!Email) {
      isValidcontact = false;
      setEmailErr(<>*{text_err_email}!</>);
    }
    if (Email !== "" && EMAIL_REGEX.test(Email) === false) {
      isValidcontact = false;
      setEmailErr(<>*{text_err_valid_email}!</>);
    }
    if (!Message) {
      isValidcontact = false;
      setMessageErr(<>*{text_entermessage}!</>);
    }
    // setLoginBtn(false);
    return isValidcontact;
  };

  const ContactHandle = () => {
    if (ContactValidationHandler()) {
      var API_URL = API_DOMAIN + "contact/contactus";
      let headerConfig = {
        headers: {
          authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };
      var data = {
        firstname: FirstName,
        lastname: LastName,
        email: Email,
        phone: PhoneNo,
        msg: Message,
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("response***", response);
          toast.success(response?.data?.message, {
            autoClose: 2000,
            theme: "light",
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        })
        .catch(function (error) {
          console.log("catch block err***", error.response.data);
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            // setForgoterr(error.response.data.message);
          }
        })
        .then(function () {});
    }
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_helpcenter(
        xpath.select1("/resources/string[@name='text_helpcenter']", doc)
          .firstChild.data
      );
      setText_contactinfo(
        xpath.select1("/resources/string[@name='text_contactinfo']", doc)
          .firstChild.data
      );
      setText_submit(
        xpath.select1("/resources/string[@name='text_submit']", doc).firstChild
          .data
      );
      setText_message(
        xpath.select1("/resources/string[@name='text_message']", doc).firstChild
          .data
      );
      setText_phone(
        xpath.select1("/resources/string[@name='text_phone']", doc).firstChild
          .data
      );
      setText_ph_email(
        xpath.select1("/resources/string[@name='text_ph_email']", doc)
          .firstChild.data
      );
      setText_email(
        xpath.select1("/resources/string[@name='text_email']", doc).firstChild
          .data
      );
      setText_ph_lastName(
        xpath.select1("/resources/string[@name='text_ph_lastName']", doc)
          .firstChild.data
      );
      setText_ph_firstName(
        xpath.select1("/resources/string[@name='text_ph_firstName']", doc)
          .firstChild.data
      );
      setText_lastname(
        xpath.select1("/resources/string[@name='text_lastname']", doc)
          .firstChild.data
      );
      setText_firstname(
        xpath.select1("/resources/string[@name='text_firstname']", doc)
          .firstChild.data
      );
      setText_noticecontactus(
        xpath.select1("/resources/string[@name='text_noticecontactus']", doc)
          .firstChild.data
      );
      setText_contactus(
        xpath.select1("/resources/string[@name='text_contactus']", doc)
          .firstChild.data
      );
      setText_err_email(
        xpath.select1("/resources/string[@name='text_err_email']", doc)
          .firstChild.data
      );
      setText_err_valid_email(
        xpath.select1("/resources/string[@name='text_err_valid_email']", doc)
          .firstChild.data
      );
      setText_entermessage(
        xpath.select1("/resources/string[@name='text_entermessage']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="wrapper  eventdetail-nightmod" id="otherPages">
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_contactus}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title checkout-title text-center">
                  <h3>{text_contactus}</h3>
                  <p className="mb-0">{text_noticecontactus}</p>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="main-card mt-5">
                  <div className="row">
                    <div className="col-xl-7 col-lg-12 col-md-12 order-lg-2">
                      <div className="contact-form bp-form p-lg-5 ps-lg-4 pt-lg-4 pb-5 p-4">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group profile-night-form mt-4">
                              <label className="form-label">
                                {text_firstname}
                                {/* <span className="starsty">*</span> */}
                              </label>
                              <input
                                className="form-control h_50"
                                type="text"
                                placeholder={text_ph_firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group profile-night-form mt-4">
                              <label className="form-label">
                                {text_lastname}
                                {/* <span className="starsty">*</span> */}
                              </label>
                              <input
                                className="form-control h_50"
                                type="text"
                                placeholder={text_ph_lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group profile-night-form mt-4">
                              <label className="form-label">
                                {text_email}
                                <span className="starsty">*</span>
                              </label>
                              <input
                                className="form-control h_50"
                                type="Email"
                                placeholder={text_ph_email}
                                onChange={(e) => [
                                  setEmail(e.target.value),
                                  setEmailErr(""),
                                ]}
                              />
                            </div>
                            <span className="starsty">{emailErr}</span>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group profile-night-form mt-4">
                              <label className="form-label">
                                {text_phone}
                                {/* <span className="starsty">*</span> */}
                              </label>
                              <PhoneInput
                                country={"us"}
                                inputClass="Register-input"
                                onChange={(e) => setPhoneNo(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group profile-night-form mt-4">
                              <label className="form-label">
                                {text_message}
                                <span className="starsty">*</span>
                              </label>
                              <textarea
                                className="form-textarea"
                                placeholder="About"
                                onChange={(e) => [
                                  setMessage(e.target.value),
                                  setMessageErr(""),
                                ]}
                              ></textarea>
                            </div>
                            <span className="starsty">{messageErr}</span>
                          </div>
                          <div className="col-md-12">
                            <div className="text-center mt-4">
                              <button
                                className="main-btn btn-hover h_50 w-100"
                                type="submit"
                                onClick={ContactHandle}
                              >
                                {text_submit}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-12 col-md-12 order-lg-1 d-none d-xl-block">
                      <div className="contact-banner-block">
                        <div className="contact-hero-banner">
                          <div className="contact-hero-banner-info">
                            <h3>{text_contactinfo}</h3>
                            <p>
                              Fill out the form and our team will get back to
                              you soon.
                            </p>
                            <ul>
                              {/* <li>
                                <div className="contact-info d-flex align-items-center">
                                  <div className="contact-icon">
                                    <FaSquarePhone />
                                  </div>
                                  <Link to="#" className="contact-link">
                                    +1(000)00-00000
                                  </Link>
                                </div>
                              </li> */}
                              <li>
                                <div className="contact-info d-flex align-items-center">
                                  <div className="contact-icon">
                                    <MdEmail />
                                  </div>

                                  <Link
                                    to="mailto:support@tiq8.com"
                                    className="contact-link"
                                  >
                                    support@tiq8.com
                                  </Link>
                                </div>
                              </li>
                              <li>
                                <div className="contact-info d-flex align-items-center">
                                  <div className="contact-icon">
                                    <IoIosHelpBuoy />
                                  </div>
                                  <Link to="#" className="contact-link">
                                    {text_helpcenter}
                                  </Link>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 col-md-12 d-block d-xl-none">
                <div className="main-card mt-4">
                  <div className="contact-banner-block">
                    <div className="contact-hero-banner p_30 min-h-auto">
                      <div className="contact-hero-banner-info">
                        <h3>{text_contactinfo}</h3>
                        <p>
                          Fill out the form and our team will get back to you
                          soon.
                        </p>
                        <ul>
                          {/* <li>
                            <div className="contact-info d-flex align-items-center">
                              <div className="contact-icon">
                                <i
                                  className="fa-solid fa-square-phone fa-beat-fade"
                                  // style="--fa-beat-fade-opacity: 0.67; --fa-beat-fade-scale: 1.075;"
                                ></i>
                              </div>
                              <Link to="#">+1(000)00-00000</Link>
                            </div>
                          </li> */}
                          <li>
                            <div className="contact-info d-flex align-items-center">
                              <div className="contact-icon">
                                <i
                                  className="fa-solid fa-envelope fa-beat-fade"
                                  // style="--fa-beat-fade-opacity: 0.67; --fa-beat-fade-scale: 1.075;"
                                ></i>
                              </div>
                              <Link to="mailto:support@tiq8.com">
                                support@tiq8.com
                              </Link>
                            </div>
                          </li>
                          <li>
                            <div className="contact-info d-flex align-items-center">
                              <div className="contact-icon">
                                <i
                                  className="fa-solid fa-life-ring fa-beat-fade"
                                  // style="--fa-beat-fade-opacity: 0.67; --fa-beat-fade-scale: 1.075;"
                                ></i>
                              </div>
                              <Link to="#">{text_helpcenter}</Link>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default ContactUs;
