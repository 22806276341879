import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import OrgDashboardSidebar from "./OrgDashboardSidebar";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { FaCreditCard, FaTrashAlt } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import { GlobalConstants } from "../../utils/GlobalConst";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function OrgPayout() {
  //variable
  const [searchValue, setSearchValue] = useState("");
  const [accountname, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankname] = useState("");
  const [BSBcode, setBSBcode] = useState("");
  const [SwiftCode, setSwiftCode] = useState("");
  const [ABARouting, setABARouting] = useState("");

  const [internationalACNumb, setInternationalACNum] = useState("");

  // const start //
  const [text_addbank, setText_addbank] = useState("Add Bank Account");
  const [text_searchcoupon, setText_searchcoupon] = useState(
    "Search by coupon name"
  );
  const [text_addedbank, setText_addedbank] = useState("Added Bank Account");
  const [text_payouts, setText_payouts] = useState("Payouts");
  const [text_intbankaccount, setText_intbankaccount] = useState(
    "International Bank Account Number"
  );
  const [text_abarouting, setText_abarouting] = useState("ABA Routing");
  const [text_swiftbic, setText_swiftbic] = useState("SWIFT/BIC code");
  const [text_bsbcode, setText_bsbcode] = useState("BSB code");
  const [text_bankname, setText_bankname] = useState("Bank Name");
  const [text_accountnumber, setText_accountnumber] =
    useState("Account Number");
  const [text_accountname, setText_accountname] = useState("Account Name");
  const [text_save, setText_save] = useState("Save");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const BankData = [
    {
      bankName: "Bank Name",
      OwnerName: "John Doe",
      ACnumber: "****1234",
    },
  ];
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_addbank(
        xpath.select1("/resources/string[@name='text_addbank']", doc).firstChild
          .data
      );
      setText_searchcoupon(
        xpath.select1("/resources/string[@name='text_searchcoupon']", doc)
          .firstChild.data
      );
      setText_addedbank(
        xpath.select1("/resources/string[@name='text_addedbank']", doc)
          .firstChild.data
      );
      setText_payouts(
        xpath.select1("/resources/string[@name='text_payouts']", doc).firstChild
          .data
      );
      setText_intbankaccount(
        xpath.select1("/resources/string[@name='text_intbankaccount']", doc)
          .firstChild.data
      );
      setText_abarouting(
        xpath.select1("/resources/string[@name='text_abarouting']", doc)
          .firstChild.data
      );
      setText_swiftbic(
        xpath.select1("/resources/string[@name='text_swiftbic']", doc)
          .firstChild.data
      );
      setText_bsbcode(
        xpath.select1("/resources/string[@name='text_bsbcode']", doc).firstChild
          .data
      );
      setText_bankname(
        xpath.select1("/resources/string[@name='text_bankname']", doc)
          .firstChild.data
      );
      setText_accountnumber(
        xpath.select1("/resources/string[@name='text_accountnumber']", doc)
          .firstChild.data
      );
      setText_accountname(
        xpath.select1("/resources/string[@name='text_accountname']", doc)
          .firstChild.data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <OrgDashboardSidebar />
      <div
        className="modal-org fade"
        id="bankModal"
        tabindex="-1"
        aria-labelledby="bankModalLabel"
        aria-hidden="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold" id="bankModalLabel">
                {text_addbank}
              </h5>
              <button
                type="button"
                className="close-model-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <RxCross2 className="uil-multiply" />
              </button>
            </div>
            <div className="modal-body">
              <div className="model-content main-form">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">
                        {text_accountname}*
                      </label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={accountname}
                        onChange={(e) => setAccountName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">
                        {text_accountnumber}*
                      </label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">
                        {text_bankname}*
                      </label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={bankName}
                        onChange={(e) => setBankname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">
                        {text_bsbcode}*
                      </label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder="XXY-ZZZ"
                        value={BSBcode}
                        onChange={(e) => setBSBcode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">
                        {text_swiftbic}*
                      </label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={SwiftCode}
                        onChange={(e) => setSwiftCode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">
                        {text_abarouting}*
                      </label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={ABARouting}
                        onChange={(e) => setABARouting(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label fw-bold">
                        {text_intbankaccount}*
                      </label>
                      <input
                        className="form-control h_40"
                        type="text"
                        placeholder=""
                        value={internationalACNumb}
                        onChange={(e) => setInternationalACNum(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="co-main-btn min-width"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => console.log("close model")}
              >
                {text_cancel}
              </button>
              <button
                type="button"
                className="main-btn-org-popup min-width btn-hover"
              >
                {text_save}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="otherPages">
        <div className="wrapper wrapper-body" id="wrapper-body">
          <div className="dashboard-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-main-title">
                    <h3>
                      <FaCreditCard className="me-3" />
                      {text_payouts}
                    </h3>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="main-card mt-5">
                    <div className="dashboard-wrap-content p-4">
                      <h5 className="mb-4 fw-bold">{text_addedbank} (1)</h5>
                      <div className="d-md-flex flex-wrap align-items-center">
                        <div className="dashboard-date-wrap">
                          <div className="form-group">
                            <div className="relative-input position-relative">
                              <input
                                className="form-control h_40"
                                type="text"
                                placeholder={text_searchcoupon}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                              />
                              <i className="uil uil-search">
                                <CiSearch className="search--icon" />
                              </i>
                            </div>
                          </div>
                        </div>
                        <div className="rs ms-auto mt_r4">
                          <button
                            className="main-btn btn-hover h_40 w-100"
                            data-bs-toggle="modal"
                            data-bs-target="#bankModal"
                          >
                            {text_addbank}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="all-promotion-list">
                    <div className="main-card mt-4 p-4 pt-0">
                      <div className="row">
                        {BankData.length > 0 &&
                          BankData.map((e) => {
                            return (
                              <>
                                <div className="col-lg-4 col-md-6">
                                  <div className="bank-card p-4 mt-4">
                                    <h5>{e.bankName}</h5>
                                    <h6>{e.OwnerName}</h6>
                                    <span>{e.ACnumber}</span>
                                    <div className="card-actions">
                                      <Link className="action-link">
                                        <MdEdit
                                          className="edit-icon-payout"
                                          data-bs-toggle="modal"
                                          data-bs-target="#bankModal"
                                        />
                                      </Link>
                                      <Link className="action-link">
                                        <FaTrashAlt className="delet-icon-payout mb-1" />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrgPayout;
